import type { ReactElement } from 'react';
import { Text, Button, Icon, Modal } from '@authenticins/react-ui';

interface MoreInformationNeededModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MoreInformationNeededModal({ isOpen, onClose }: MoreInformationNeededModalProps): ReactElement {
  return (
    <Modal
      centered
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Button variant='primary' onClick={onClose}>
          Let's go
        </Button>
      }
    >
      <Icon name='RiSurveyLine' size={4} c='subtle' />
      <Text variant='heading' mt={2} textAlign='center'>
        We need a little more info
      </Text>
      <Text mt={1.5} textAlign='center'>
        Looks like we need to ask you a few more questions to get you the best coverage.
      </Text>
    </Modal>
  );
}
