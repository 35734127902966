import { type ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { type Analytics as AuthenticAnalytics, createAnalytics } from '@authenticins/analytics';

type Analytics = AuthenticAnalytics & { isInit: boolean };

const AnalyticsContext = createContext<Analytics>({} as unknown as Analytics);

/**
 * Provides context for {@link Analytics | analytics} to its children.
 */
export function AnalyticsProvider({
  tenantName,
  segmentApiKey,
  statsigApiKey,
  children
}: {
  tenantName: string;
  segmentApiKey: string;
  statsigApiKey: string;
  children: ReactNode;
}): ReactNode {
  const [analytics, setAnalytics] = useState<AuthenticAnalytics>({} as unknown as AuthenticAnalytics);

  // Create and initialize on mount.
  useEffect(() => {
    if (typeof tenantName === 'undefined' ||
      typeof segmentApiKey === 'undefined' ||
      typeof statsigApiKey === 'undefined') return;
    async function init(): Promise<void> {
      const analytics = await createAnalytics(tenantName, segmentApiKey, statsigApiKey);
      setAnalytics(analytics);
    }
    void init();
  }, [segmentApiKey, statsigApiKey, tenantName]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

/**
 * Returns access to the {@link AnalyticsContext | Analytics context}.
 */
export function useAnalytics(): Analytics {
  const analytics = useContext(AnalyticsContext);
  if (typeof analytics === 'undefined') {
    throw new Error('Error: Called "useAnalytics()" outside an AnalyticsProvider.');
  }
  return analytics;
}
