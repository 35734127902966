import type { ReactElement } from 'react';
import { Text, Button, Icon, Modal } from '@authenticins/react-ui';

interface NotAllowedModalProps {
  isOpen: boolean;
  onClose?: (() => void) | undefined;
}

export function NotAllowedModal({ isOpen, onClose }: NotAllowedModalProps): ReactElement {
  return (
    <Modal
      centered
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Button variant='primary' onClick={onClose ?? (() => window.location.reload())}>
          Start over
        </Button>
      }
    >
      <Icon name='RiAlertLine' size={4} c='subtle' />
      <Text variant='heading' mt={2} textAlign='center'>
        You're not allowed to view this
      </Text>
      <Text mt={1.5} textAlign='center'>
        Looks like this belongs to another user, so you can't view it.
      </Text>
    </Modal>
  );
}
