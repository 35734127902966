import { type ReactNode, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import {
  type FunnelFlow,
  type ThemeOverrides,
  type ThemeStyleProps,
  MS_PER_SECOND,
  AUTHENTIC_SUPPORT_PHONE_NUMBER,
  MOBILE_BREAKPOINT_PX,
  getUrlParam,
  ThemeProvider,
  GoogleMapsProvider,
  NotFoundView,
  Box,
  Text,
  ErrorModal,
  LoadingBar
} from '@authenticins/react-ui';
import '@authenticins/react-ui/dist/style.css';

import {
  AnalyticsProvider,
  useAnalytics,
  AuthenticClientProvider,
  useAuthentic,
  FunctionalFinanceProvider
} from './providers';
import { WelcomeView, ApplicationView, PolicyView } from './views';
import { Navbar, Footer, LoginModal, NotAllowedModal, MoreInformationNeededModal } from './components';

const {
  VITE_AUTHENTIC_API_URL,
  VITE_TENANT_NAME,
  VITE_AWS_REGION,
  VITE_AWS_IDENTITY_POOL_ID,
  VITE_AWS_USER_POOL_ID,
  VITE_AWS_USER_POOL_WEB_CLIENT_ID,
  VITE_SENTRY_DSN,
  VITE_SENTRY_ENVIRONMENT,
  VITE_SENTRY_RELEASE,
  VITE_SEGMENT_API_KEY,
  VITE_STATSIG_API_KEY,
  VITE_GOOGLE_API_KEY,
  VITE_GOOGLE_MAP_ID
} = import.meta.env;

export enum ROUTE {
  WELCOME = '/welcome',
  APPLY = '/apply',
  POLICY = '/policy',
}
export enum PAGE {
  APPLICATION_META_FORM = 'Application Lead Form',
  APPLICATION_META_REJECTED = 'Application Lead Rejected',
  APPLICATION_QUESTIONS = 'Application Questions',
  APPLICATION_DECLINED = 'Application Declined',
  APPLICATION_QUOTE = 'Application Quote',
  POLICY_DASHBOARD = 'Policy Dashboard',
}

function App(): ReactNode {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const [supportPhoneNumber, setSupportPhoneNumber] = useState<number>(AUTHENTIC_SUPPORT_PHONE_NUMBER);
  const [funnelFlow, setFunnelFlow] = useState<FunnelFlow | null>(null);
  const [themeOverrides, setThemeOverrides] = useState<ThemeOverrides>({});
  const viewThemeStyleProps: ThemeStyleProps = {
    pt: themeOverrides.layout?.hideNavbar !== true ? 10 : undefined,
    pb: themeOverrides.layout?.hideFooter !== true ? 7 : undefined
  };
  const [isThemeInit, setIsThemeInit] = useState<boolean>(false);

  // Initialize Sentry then Check URL params for funnel flow, theme overrides, and layout overrides on mount.
  useEffect(() => {
    if (typeof VITE_SENTRY_DSN !== 'undefined') {
      Sentry.init({
        dsn: VITE_SENTRY_DSN,
        environment: VITE_SENTRY_ENVIRONMENT,
        release: VITE_SENTRY_RELEASE,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/api(\.[a-zA-Z0-9-]+)*\.authenticinsurance\.com/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      });
    }
    const urlParamsFunnelFlow = getUrlParam(window.location.href, 'funnel-flow') as FunnelFlow | null;
    if (urlParamsFunnelFlow !== null) setFunnelFlow(urlParamsFunnelFlow);
    const urlParamsThemeOverrides = getUrlParam(window.location.href, 'theme-overrides') as ThemeOverrides | null;
    if (urlParamsThemeOverrides !== null) setThemeOverrides(urlParamsThemeOverrides);
    // If we don't expect an analytics theme overrides config, initialize the theme now.
    if (typeof VITE_TENANT_NAME === 'undefined' ||
      typeof VITE_SEGMENT_API_KEY === 'undefined' ||
      typeof VITE_STATSIG_API_KEY === 'undefined') setIsThemeInit(true);
  }, []);

  // Once analytics is initialized, check for support phone number,
  // funnel flow, theme overrides, and layout overrides configs.
  useEffect(() => {
    if (!analytics.isInit) return;
    const supportPhoneNumber = analytics.getConfig('tenant_support_phone_number')?.['supportPhoneNumber'] as number | undefined;
    if (typeof supportPhoneNumber !== 'undefined') setSupportPhoneNumber(supportPhoneNumber);
    const funnelFlow = analytics.getConfig('tenant_funnel_flow')?.['funnelFlow'] as FunnelFlow | undefined;
    // If the funnel flow was set by URL params, don't override it.
    if (typeof funnelFlow !== 'undefined') setFunnelFlow((prev) => prev ?? funnelFlow);
    const themeOverridesConfig = analytics.getConfig('tenant_theme_overrides');
    const themeOverrides: ThemeOverrides = {};
    if (typeof themeOverridesConfig?.['darkMode'] !== 'undefined') Object.assign(themeOverrides, { useDarkMode: themeOverridesConfig['darkMode'] });
    if (typeof themeOverridesConfig?.['primaryColor'] !== 'undefined') Object.assign(themeOverrides, { primaryColor: themeOverridesConfig['primaryColor'] });
    if (typeof themeOverridesConfig?.['secondaryColor'] !== 'undefined') Object.assign(themeOverrides, { secondaryColor: themeOverridesConfig['secondaryColor'] });
    if (typeof themeOverridesConfig?.['borderRadius'] !== 'undefined') Object.assign(themeOverrides, { borderRadius: themeOverridesConfig['borderRadius'] });
    if (typeof themeOverridesConfig?.['brandName'] !== 'undefined') Object.assign(themeOverrides, { brand: { name: themeOverridesConfig['brandName'] } });
    if (typeof themeOverridesConfig?.['lightLogo'] !== 'undefined') {
      Object.assign(themeOverrides, {
        brand: {
          ...themeOverrides.brand,
          logoUrls: { light: themeOverridesConfig['lightLogo'] }
        }
      });
    }
    if (typeof themeOverridesConfig?.['darkLogo'] !== 'undefined') {
      Object.assign(themeOverrides, {
        brand: {
          ...themeOverrides.brand,
          logoUrls: {
            ...themeOverrides.brand?.logoUrls,
            dark: themeOverridesConfig['darkLogo']
          }
        }
      });
    }
    if (typeof themeOverridesConfig?.['favicon'] !== 'undefined') {
      Object.assign(themeOverrides, {
        brand: {
          ...themeOverrides.brand,
          faviconUrl: themeOverridesConfig['favicon']
        }
      });
    }
    if (typeof themeOverridesConfig?.['hideNavbar'] !== 'undefined') {
      Object.assign(themeOverrides, {
        layout: { hideNavbar: themeOverridesConfig['hideNavbar'] }
      });
    }
    if (typeof themeOverridesConfig?.['hideFooter'] !== 'undefined') {
      Object.assign(themeOverrides, {
        layout: {
          ...themeOverrides.layout,
          hideFooter: themeOverridesConfig['hideFooter']
        }
      });
    }
    // If theme overrides were set by URL params, don't override them.
    setThemeOverrides((prev) => ({
      ...themeOverrides,
      ...prev,
      brand: { ...themeOverrides.brand, ...prev.brand }
    }));
    // If the theme hasn't been initialized yet, initialize it.
    if (!isThemeInit) setIsThemeInit(true);
  }, [analytics.isInit]);

  // Once both analytics and the client are initialized, track Insurance Portal visit.
  useEffect(() => {
    if (!analytics.isInit || !authentic.isInit) return;
    analytics.trackEvent(ANALYTICS_EVENT.INSURANCE_PORTAL_VISITED);
  }, [analytics.isInit, authentic.isInit]);

  // Once analytics and the theme are initialized, check if the user is on mobile and hide the Intercom widget if so.
  useEffect(() => {
    if (!analytics.isInit || !isThemeInit || window.innerWidth > MOBILE_BREAKPOINT_PX) return;
    const intercomWidgetRemovalInternal = setInterval(() => {
      if ('Intercom' in window) {
        const intercomIframe = document.getElementById('intercom-frame');
        const intercomLauncher = document.querySelector('.intercom-lightweight-app');
        if (intercomIframe !== null) intercomIframe.remove();
        if (intercomLauncher !== null) intercomLauncher.remove();
        clearInterval(intercomWidgetRemovalInternal);
      }
    }, MS_PER_SECOND / 10);
  }, [analytics.isInit, isThemeInit]);

  return (
    <BrowserRouter>
      {isThemeInit && (
        <ThemeProvider overrides={themeOverrides}>
          <GoogleMapsProvider apiKey={VITE_GOOGLE_API_KEY} mapId={VITE_GOOGLE_MAP_ID}>
            {themeOverrides.layout?.hideNavbar !== true && <Navbar mb={-10} supportPhoneNumber={supportPhoneNumber} funnelFlow={funnelFlow ?? 'lead'} />}
            <Routes>
              {/* Root page is determined by which "funnel flow" is set. */}
              <Route
                path='/'
                element={funnelFlow === 'welcome'
                  ? <WelcomeView {...viewThemeStyleProps} />
                  : <ApplicationView supportPhoneNumber={supportPhoneNumber} funnelFlow={funnelFlow ?? 'lead'} {...viewThemeStyleProps} />}
              />
              <Route path={ROUTE.WELCOME} element={<WelcomeView {...viewThemeStyleProps} />} />
              <Route path={ROUTE.APPLY} element={<ApplicationView supportPhoneNumber={supportPhoneNumber} funnelFlow={funnelFlow ?? 'lead'} {...viewThemeStyleProps} />} />
              <Route path={ROUTE.POLICY} element={<PolicyView {...viewThemeStyleProps} />} />
              <Route path='*' element={<NotFoundView {...viewThemeStyleProps} />} />
            </Routes>
            <LoginModal isOpen={authentic.showLoginModal} onClose={() => window.location.reload()} />
            <NotAllowedModal isOpen={authentic.showNotAllowedModal} />
            <MoreInformationNeededModal isOpen={authentic.showMoreInformationNeededModal} onClose={authentic.dismissMoreInformationNeededModal} />
            <ErrorModal supportPhoneNumber={supportPhoneNumber} isOpen={authentic.showErrorModal} />
            <LoadingBar isOpen={authentic.isSubmittingApplication || authentic.isGeneratingApplicationQuotesPaymentData}>
              <Box column>
                <Text variant='label' mt={0.25} mb={0.5} pr={2}>Please wait, this may take a few moments...</Text>
                <Text fontSize='sm' c='muted'>KEEP THIS TAB OPEN</Text>
              </Box>
            </LoadingBar>
            {themeOverrides.layout?.hideFooter !== true && <Footer mt={-7} />}
          </GoogleMapsProvider>
        </ThemeProvider>
      )}
    </BrowserRouter>
  );
}

const root = document.getElementById('root');
if (root === null) throw new Error('Error: No element of ID "root" found.');
ReactDOM.createRoot(root).render(
  <AnalyticsProvider
    tenantName={VITE_TENANT_NAME}
    segmentApiKey={VITE_SEGMENT_API_KEY}
    statsigApiKey={VITE_STATSIG_API_KEY}
  >
    <AuthenticClientProvider
      apiUrl={VITE_AUTHENTIC_API_URL}
      authConfig={{
        awsCognitoRegion: VITE_AWS_REGION,
        awsCognitoIdentityPoolId: VITE_AWS_IDENTITY_POOL_ID,
        awsCognitoUserPoolId: VITE_AWS_USER_POOL_ID,
        awsCognitoUserPoolWebClientId: VITE_AWS_USER_POOL_WEB_CLIENT_ID
      }}
      tenantName={VITE_TENANT_NAME}
    >
      <FunctionalFinanceProvider environment={(VITE_AUTHENTIC_API_URL as string).includes('prod') ? 'prod' : 'sandbox'}>
        <App />
      </FunctionalFinanceProvider>
    </AuthenticClientProvider>
  </AnalyticsProvider>
);
