import { type ReactElement, useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import * as Authentic from '@authenticins/ts-client';
import {
  getBrandNameWithoutInsurance,
  useTheme,
  Box,
  Checkbox,
  Markdown
} from '@authenticins/react-ui';

import { useAnalytics, useAuthentic } from '../../providers';
import {
  type ApplicationPageProps,
  ApplicationPage,
  ApplicationFieldInput,
  injectCustomerIndustry
} from '.';

type QuestionSectionPagesProps = Pick<ApplicationPageProps, 'isActive' | 'isCompleted' | 'customerIndustry'> & {
  numberOfExposurePages: number;
  setNumberOfQuestionSectionPages: (numberOfQuestionSectionPages: number) => void;
  totalApplicationPages: number;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

export function QuestionSectionPages({
  isActive = false,
  isCompleted = false,
  customerIndustry,
  numberOfExposurePages,
  setNumberOfQuestionSectionPages,
  totalApplicationPages,
  isSubmitting,
  setIsSubmitting
}: QuestionSectionPagesProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const [questionSections, setQuestionSections] = useState<Authentic.ApplicationSection[]>([]);
  const currentQuestionSection = authentic.application !== null
    ? questionSections[authentic.currentApplicationPageIndex - numberOfExposurePages]
    : null;
  const currentQuestionSectionFields = currentQuestionSection?.fields ?? [];
  const [questionSectionsFieldValues, setQuestionSectionsFieldValues] = useState<Record<string, Authentic.ApplicationFieldValue>>({});
  const areCurrentQuestionSectionFieldsFilled = currentQuestionSectionFields
    .filter((field) => !field.isOptional)
    .every((mandatoryField) => typeof questionSectionsFieldValues[mandatoryField.name] === 'string');
  const [isOptedInToSmsUpdates, setIsOptedInToSmsUpdates] = useState<boolean>(false);
  const smsUpdatesOptInCheckboxLabel = `I consent to SMS updates from ${getBrandNameWithoutInsurance(theme)} and their partners about my insurance applications, products, and promotions. Message and data rates may apply.`;
  const [tenantFunnelDisclaimer, setTenantFunnelDisclaimer] = useState<string | null>(null);
  const isSubmittingDisabled = !areCurrentQuestionSectionFieldsFilled;
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const isActiveInternal = isInit && isActive;

  // Check analytics for funnel disclaimer config.
  useEffect(() => {
    if (!analytics.isInit) return;
    const tenantFunnelDisclaimer = analytics.getConfig('tenant_funnel_disclaimer')?.['funnelDisclaimer'] as string | undefined;
    if (typeof tenantFunnelDisclaimer !== 'undefined') setTenantFunnelDisclaimer(tenantFunnelDisclaimer);
  }, [analytics.isInit]);

  // Allow time for question section pages to render before they become active.
  useEffect(() => {
    if (authentic.application === null) return;
    setTimeout(() => { setIsInit(true); }, theme.transition.baseDurationMs);
  }, [authentic.application]);

  // After submitting field responses (when a question section page changes) populate all question section field values.
  useEffect(() => {
    if (authentic.application === null) return;
    let questionSectionsFieldValues = {};
    for (const questionSection of authentic.application.questions) {
      questionSectionsFieldValues = {
        ...questionSectionsFieldValues,
        ...questionSection.fields.reduce((acc, field) => ({
          ...acc,
          [field.name]: authentic.application?.answers.questions[field.name] ?? field.defaultValue ?? null
        }), {})
      };
    }
    setQuestionSectionsFieldValues(questionSectionsFieldValues);
  }, [authentic.application, isActiveInternal]);

  // When any question section field value changes, filter all question sections by their fields' conditionals.
  useEffect(() => {
    if (authentic.application === null) return;
    const filteredQuestionSections = Authentic.filterApplicationSectionsByConditionals(authentic.application.questions, {
      ...authentic.application.answers.questions,
      ...questionSectionsFieldValues
    }, true);
    setNumberOfQuestionSectionPages(filteredQuestionSections.length);
    setQuestionSections(filteredQuestionSections);
  }, [authentic.application, questionSectionsFieldValues, isActiveInternal]);

  async function handleSubmitQuestionSection(): Promise<void> {
    const areCurrentQuestionSectionFieldValuesNew = currentQuestionSectionFields
      .filter((field) => !field.isOptional)
      .some((mandatoryField) => {
        const fieldValue = questionSectionsFieldValues[mandatoryField.name];
        return fieldValue !== authentic.application?.answers.questions[mandatoryField.name];
      });
    setIsSubmitting(true);
    let wasErrorUpdatingFieldResponses = false;
    if (areCurrentQuestionSectionFieldValuesNew) {
      const fieldResponses: Authentic.ApplicationFieldResponse[] = [];
      for (const field of currentQuestionSectionFields) {
        const fieldValue = questionSectionsFieldValues[field.name];
        if (typeof fieldValue === 'string') {
          fieldResponses.push({
            fieldName: field.name,
            fieldValue
          });
        }
      }
      wasErrorUpdatingFieldResponses = await authentic.updateApplicationFieldResponses(fieldResponses);
    }
    if (!wasErrorUpdatingFieldResponses) {
      const isFinalApplicationPage = authentic.currentApplicationPageIndex === totalApplicationPages - 1;
      if (isFinalApplicationPage) {
        await authentic.submitApplication();
        if (analytics.isInit && isOptedInToSmsUpdates) {
          analytics.trackEvent(ANALYTICS_EVENT.OPTED_IN_TO_SMS_UPDATES, {
            timestamp: Date.now(),
            methodOfSmsOptInConsent: 'Digital form (checkbox)',
            checkboxLabel: smsUpdatesOptInCheckboxLabel,
            isOptedInToSmsUpdates,
            phoneNumber: authentic.application?.answers.questions['PHONE']
          });
        }
      }
      else authentic.setCurrentApplicationPageIndex(authentic.currentApplicationPageIndex + 1);
    }
    setHasAttemptedSubmit(false);
    setIsSubmitting(false);
  }

  return (
    <>
      {questionSections.map((section, i) => {
        const isFinalQuestionSectionPage = i === questionSections.length - 1;

        return (
          <ApplicationPage
            key={i}
            isActive={
              isActiveInternal &&
              authentic.application !== null &&
              i + numberOfExposurePages === authentic.currentApplicationPageIndex
            }
            isCompleted={
              isCompleted ||
              (authentic.application !== null &&
              i + numberOfExposurePages < authentic.currentApplicationPageIndex)
            }
            customerIndustry={customerIndustry}
            title={section.title}
            footer={isFinalQuestionSectionPage && tenantFunnelDisclaimer !== null && (
              <Box c='muted' fontSize='sm' textAlign='center'>
                <Markdown>{tenantFunnelDisclaimer}</Markdown>
              </Box>
            )}
            continueButton={{
              label: isFinalQuestionSectionPage ? 'Get quote' : undefined,
              onClick: () => { void handleSubmitQuestionSection(); },
              isDisabled: isSubmittingDisabled || isSubmitting,
              onDisabledClick: () => { setHasAttemptedSubmit(true); },
              isLoading: isSubmitting
            }}
          >
            {section.fields.map((field, j) => (
              <Box key={j} column w='100%' mt={j > 0 ? 3 : undefined}>
                <ApplicationFieldInput
                  field={{
                    ...field,
                    title: injectCustomerIndustry(field.title, customerIndustry),
                    label: typeof field.label !== 'undefined' ? injectCustomerIndustry(field.label, customerIndustry) : undefined
                  }}
                  // @ts-expect-error - For address inputs, allow the user to select from the business locations they've previously submitted.
                  options={
                    field.type === 'address'
                      ? authentic.application?.answers.exposures.map((exposureResponse) => exposureResponse.fieldValues)
                        .filter((fieldValues) => 'ADDRESS' in fieldValues)
                        .map((fieldValuesWithAddress) => fieldValuesWithAddress['ADDRESS'])
                      : undefined
                  }
                  value={questionSectionsFieldValues[field.name] ?? null}
                  onChange={(v) => {
                    setQuestionSectionsFieldValues({
                      ...questionSectionsFieldValues,
                      [field.name]: v
                    });
                  }}
                  error={!field.isOptional && hasAttemptedSubmit && typeof questionSectionsFieldValues[field.name] !== 'string'}
                  isDisabled={isSubmitting}
                />
                {analytics.isInit && field.name === 'PHONE' && (
                  <Checkbox
                    mt={1.5}
                    fontSize='sm'
                    label={smsUpdatesOptInCheckboxLabel}
                    isActive={isOptedInToSmsUpdates}
                    onChange={setIsOptedInToSmsUpdates}
                    isDisabled={isSubmitting}
                  />
                )}
              </Box>
            ))}
          </ApplicationPage>
        );
      })}
    </>
  );
}
