import type { ReactElement } from 'react';
import { type ThemeStyleProps, type IconProps, useTheme } from '@authenticins/react-ui';

import { GeneralLiabilityIcon } from './GeneralLiability';
import { PropertyIcon } from './Property';
import { ProfessionalLiabilityIcon } from './ProfessionalLiability';
import { GymLiabilityIcon } from './GymLiability';

export type ProductIconProps = ThemeStyleProps & Pick<IconProps, 'size' | 'onMobile'> & {
  isActive?: boolean | undefined;
  productName: string;
};

export function ProductIcon({ isActive = true, size, productName, ...themeStyleProps }: ProductIconProps): ReactElement {
  const theme = useTheme();
  if (typeof themeStyleProps.onMobile?.size !== 'undefined' && theme.isOnMobile) size = themeStyleProps.onMobile.size;

  if (productName === 'Property') return <PropertyIcon isActive={isActive} size={size} {...themeStyleProps} />;
  if (productName === 'Professional Liability') return <ProfessionalLiabilityIcon isActive={isActive} size={size} {...themeStyleProps} />;
  if (productName === 'Gym Liability') return <GymLiabilityIcon isActive={isActive} size={size} {...themeStyleProps} />;
  return <GeneralLiabilityIcon isActive={isActive} size={size} {...themeStyleProps} />;
}
