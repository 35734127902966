import { type ReactElement, Fragment, useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import * as Authentic from '@authenticins/ts-client';
import {
  type StateCode,
  type ThemeStyleProps,
  MS_PER_SECOND,
  STATES,
  AUTHENTIC_BRAND_NAME,
  AUTHENTIC_WEBSITE_URL,
  INPUT_SEARCH_DEBOUNCE_DURATION_MS,
  getRoundedNumber,
  formatPhoneNumber,
  objectContainsSomeValue,
  getBrandNameWithoutInsurance,
  useTheme,
  Box,
  Text,
  Button,
  Link,
  Input,
  NameInput,
  EmailInput,
  Select,
  Divider,
  Tooltip,
  Icon,
  Spinner
} from '@authenticins/react-ui';

import { useAnalytics, useAuthentic } from '../../providers';
import { type ApplicationPageProps, ApplicationPage } from '.';
import { ProductCard } from '../../components';

type LeadFormPagesProps = Pick<ApplicationPageProps, 'isActive' | 'isCompleted' | 'customerIndustry'> & {
  supportPhoneNumber: number;
  initialPage: LEAD_FORM_PAGE;
  showWelcomePage: boolean;
  showClassCodeSearchPage: boolean;
  currentPage: LEAD_FORM_PAGE;
  setCurrentPage: (currentPage: LEAD_FORM_PAGE) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
};
interface BusinessInfoInputsProps extends ThemeStyleProps {
  businessName: string | null;
  setBusinessName: (businessName: string | null) => void;
  showClassCodeSearchPage: boolean;
  availableBusinessClasses: Authentic.ApplicationBusinessClass[];
  hasAttemptedSubmit: boolean;
}
interface BusinessClassSearchProps extends ThemeStyleProps {
  onContinue: () => void;
}
interface ApplicantInfoInputsProps extends ThemeStyleProps {
  applicantName: string | null;
  setApplicantName: (applicantName: string | null) => void;
  hasAttemptedSubmit: boolean;
  isSubmitting: boolean;
}

export enum LEAD_FORM_PAGE {
  PREFILL,
  BUSINESS_INFO,
  CLASS_CODE_SEARCH,
  PRODUCT_SELECT,
  APPLICANT_INFO
}

export function LeadFormPages({
  supportPhoneNumber,
  initialPage,
  isActive = false,
  isCompleted = false,
  customerIndustry,
  showWelcomePage,
  showClassCodeSearchPage,
  currentPage,
  setCurrentPage,
  isSubmitting,
  setIsSubmitting
}: LeadFormPagesProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const [businessName, setBusinessName] = useState<string | null>(null);
  const [applicantName, setApplicantName] = useState<string | null>(null);
  const [offeredProducts, setOfferedProducts] = useState<Authentic.Product[]>([]);
  const [offeredAndAddOnProducts, setOfferedAndAddOnProducts] = useState<Authentic.Product[]>([]);
  const isBusinessInfoComplete = businessName !== null && authentic.applicationMeta.businessClassCode.length > 0 && authentic.applicationMeta.stateCodes.length > 0;
  const isProductSelectionComplete = authentic.applicationMeta.productIds.length > 0;
  const isApplicantInfoComplete = applicantName !== null && authentic.applicationMeta.email.length > 0;
  const isSubmittingDisabled = !isBusinessInfoComplete || !isProductSelectionComplete || !isApplicantInfoComplete;
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  // When the current page changes, reset any attempt to submit.
  useEffect(() => { setHasAttemptedSubmit(false); }, [currentPage]);

  // If an application loads or pre-fill data is set before initialization, set relevant state.
  useEffect(() => {
    if (isInit) return;
    if (authentic.application !== null) {
      setBusinessName(authentic.application.answers.questions['BUSINESS_LEGAL_NAME'] ?? null);
      setApplicantName(authentic.application.answers.questions['NAME'] ?? null);
      setCurrentPage(LEAD_FORM_PAGE.APPLICANT_INFO);
    } else if (authentic.prefillData !== null) {
      setBusinessName(authentic.prefillData.answers?.questions?.['BUSINESS_LEGAL_NAME'] ?? null);
      setApplicantName(authentic.prefillData.answers?.questions?.['NAME'] ?? null);
    }
    setIsInit(true);
  }, [isInit, authentic.prefillData, authentic.application]);

  // If the user is logged in, pre-fill their email.
  useEffect(() => {
    if (isInit || authentic.auth.email === null) return;
    authentic.setApplicationMeta({
      ...authentic.applicationMeta,
      email: authentic.auth.email
    });
  }, [isInit, authentic.auth.isAuthenticated]);

  // If there's only one available business class, auto-select it.
  useEffect(() => {
    if (authentic.availableBusinessClasses.length === 1) {
      const onlyBusinessClass = authentic.availableBusinessClasses[0];
      if (typeof onlyBusinessClass === 'undefined') return;
      authentic.setApplicationMeta({
        ...authentic.applicationMeta,
        businessClassCode: onlyBusinessClass.code
      });
    }
  }, [authentic.availableBusinessClasses]);

  // Parse available products into "offered" and add-on products.
  // If no products were loaded from an application or pre-filled and there's only one "offered" product, auto-select it.
  useEffect(() => {
    if (authentic.availableProducts.length === 0) return;
    const offeredProducts = authentic.availableProducts.filter((product) => product.isOffered);
    const addOnProducts = authentic.availableProducts.filter((product) => !product.isOffered);
    setOfferedProducts(offeredProducts);
    const offeredAndAddOnProducts = [...offeredProducts, ...addOnProducts];
    setOfferedAndAddOnProducts(offeredAndAddOnProducts);
    if (authentic.application !== null || typeof authentic.prefillData?.meta?.productIds !== 'undefined') {
      authentic.setApplicationMeta({
        ...authentic.applicationMeta,
        productIds: (authentic.application?.meta.productIds ?? authentic.prefillData?.meta?.productIds ?? [])
          .filter(productId => offeredAndAddOnProducts
            .some(product => product.id === productId))
      });
    } else if (offeredProducts.length === 1) {
      authentic.setApplicationMeta({
        ...authentic.applicationMeta,
        // @ts-expect-error - We know the product at index 0 exists.
        productIds: [offeredProducts[0].id]
      });
    }
  }, [authentic.availableProducts]);

  async function handleSubmitApplicationMeta(): Promise<boolean> {
    const isApplicationMetaNew = authentic.application === null ||
      JSON.stringify(authentic.application.meta) !== JSON.stringify(authentic.applicationMeta) ||
      businessName !== authentic.application.answers.questions['BUSINESS_LEGAL_NAME'] ||
      applicantName !== authentic.application.answers.questions['NAME'];
    if (!isApplicationMetaNew) {
      authentic.setCurrentApplicationStep(Authentic.APPLICATION_STEP.QUESTIONS);
      return false;
    }
    if (isSubmittingDisabled) return true;
    setIsSubmitting(true);
    // Identify the user in analytics.
    if (analytics.isInit && authentic.auth.awsCognitoIdentityId !== null) {
      await analytics.identifyUser(authentic.auth.awsCognitoIdentityId, authentic.applicationMeta.email, {
        tenantCustomerId: authentic.tenantCustomerId ?? undefined,
        applicantName
      });
    }
    // If the user is authenticated with a different email, log them out.
    if (authentic.auth.isAuthenticated && authentic.auth.email !== authentic.applicationMeta.email) await authentic.auth.logOut();
    const prefillData: Authentic.PrefillData = {};
    // const prefillData: Authentic.PrefillData = {
    //   answers: {
    //     // TODO: Default these on API, not here.
    //     questions: {
    //       GL_LIMIT_QP: '$1,000,000 / $2,000,000',
    //       ATTACH_AL3020_DECGL1: 'No',
    //       EPLI_ELECT: 'No',
    //       ATTACH_AL2000_DECGL1: 'No',
    //       PL_END_ELECT: 'No'
    //     }
    //   }
    // };
    // Include any existing prefill-data.
    if (authentic.prefillData !== null) {
      Object.assign(prefillData, {
        answers: {
          questions: {
            ...prefillData.answers?.questions,
            ...authentic.prefillData.answers?.questions
          },
          exposures: authentic.prefillData.answers?.exposures ?? []
        }
      });
    }
    // Include any current application answers.
    if (authentic.application !== null) {
      Object.assign(prefillData, {
        answers: {
          questions: {
            ...prefillData.answers?.questions,
            ...authentic.application?.answers.questions
          },
          exposures: [
            ...(prefillData.answers?.exposures ?? []),
            ...(authentic.application?.answers.exposures ?? [])
          ]
        }
      });
    }
    // Include pre-responded fields.
    if (businessName !== null) {
      Object.assign(prefillData, {
        answers: {
          ...prefillData.answers,
          questions: {
            ...prefillData.answers?.questions,
            BUSINESS_LEGAL_NAME: businessName
          }
        }
      });
    }
    if (applicantName !== null) {
      Object.assign(prefillData, {
        answers: {
          ...prefillData.answers,
          questions: {
            ...prefillData.answers?.questions,
            NAME: applicantName
          }
        }
      });
    }
    await authentic.submitApplicationMeta(authentic.applicationMeta, objectContainsSomeValue(prefillData) ? prefillData : undefined);
    setIsSubmitting(false);
    return false;
  }

  return (
    <>
      <ApplicationPage
        isActive={isInit && isActive && currentPage === LEAD_FORM_PAGE.PREFILL}
        isCompleted={isCompleted || currentPage > LEAD_FORM_PAGE.PREFILL}
        customerIndustry={customerIndustry}
        continueButton={{
          label: 'Start quote',
          onClick: () => {
            if (showClassCodeSearchPage) setCurrentPage(LEAD_FORM_PAGE.CLASS_CODE_SEARCH);
            else {
              if (offeredProducts.length > 0) setCurrentPage(LEAD_FORM_PAGE.PRODUCT_SELECT);
              else authentic.setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_REJECTED);
            }
            if (analytics.isInit) {
              analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_PREFILL_PAGE, {
                prefillData: authentic.prefillData
              });
            }
          },
          isDisabled: authentic.isLoadingAvailableProducts ||
            (showClassCodeSearchPage
              ? (businessName === null || authentic.applicationMeta.stateCodes.length === 0)
              : !isBusinessInfoComplete) ||
            !isApplicantInfoComplete,
          onDisabledClick: () => { setHasAttemptedSubmit(true); },
          isLoading: authentic.isLoadingAvailableProducts
        }}
        footer={<TermsAndPrivacyFooter />}
      >
        <Text variant='heading' mt={2} textAlign='center'>
          Success! Your account has been connected to this application.
        </Text>
        <ApplicantInfoInputs
          mt={3}
          applicantName={applicantName}
          setApplicantName={setApplicantName}
          hasAttemptedSubmit={hasAttemptedSubmit}
          isSubmitting={isSubmitting}
        />
        <BusinessInfoInputs
          mt={3}
          businessName={businessName}
          setBusinessName={setBusinessName}
          showClassCodeSearchPage={showClassCodeSearchPage}
          availableBusinessClasses={authentic.availableBusinessClasses}
          hasAttemptedSubmit={hasAttemptedSubmit}
        />
        {authentic.prefillData !== null && (
          <Box column w='100%' mt={3}>
            {(authentic.prefillData.answers?.exposures ?? []).map((exposureResponse, i) => {
              const exposure = authentic.prefilledApplicationSections?.exposures.find((exposure) => exposure.name === exposureResponse.exposureName);
              if (typeof exposure === 'undefined') return <Fragment key={i}/>;
              const exposureResponseDisplayName = Authentic.getApplicationExposureResponseDisplayName(exposureResponse, exposure);
              const exposureResponseIconName = exposure.sections[0]?.fields[0]?.type === 'address' ? 'RiMapPinLine' : 'RiCheckboxLine';

              return (
                <Box
                  key={i}
                  position='relative'
                  column
                  w='100%'
                  mt={2}
                  p={2}
                  border='subtle'
                  radius='base'
                >
                  <Box position='absolute' top={-1} left={1} px={1} bg='background'>
                    <Icon name={exposureResponseIconName} size='sm' mr={1} />
                    <Text fontSize='sm' bold>{exposureResponseDisplayName}</Text>
                  </Box>
                  {Object.entries(exposureResponse.fieldValues)
                    .filter(([_, fieldValue]) => fieldValue !== exposureResponseDisplayName)
                    .map(([fieldName, fieldValue], j) => {
                      const field = exposure.sections.flatMap((section) => section.fields).find((field) => field.name === fieldName);

                      if (typeof field === 'undefined' ||
                        // TODO: Temporary fix until the API no longer returns this field in the pre-fill response.
                        field.name === 'STATE' ||
                        // Don't show boolean fields that have a value of "No".
                        fieldValue === 'No') return <Fragment key={j}/>;
                      return (
                        <Box key={j} justify='space-between' w='100%' mt={1}>
                          <Text>{field.title}</Text>
                          {fieldValue === 'Yes' ? <Icon name='RiCheckboxLine' /> : <Text>{fieldValue}</Text>}
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
          </Box>
        )}
      </ApplicationPage>
      <ApplicationPage
        isActive={isInit && isActive && currentPage === LEAD_FORM_PAGE.BUSINESS_INFO}
        isCompleted={isCompleted || currentPage > LEAD_FORM_PAGE.BUSINESS_INFO}
        customerIndustry={customerIndustry}
        title={showWelcomePage
          ? (!theme.isOnMobile ? "Let's get started." : '')
          : "Let's start with some info about your business."}
        continueButton={{
          onClick: () => {
            if (showClassCodeSearchPage) setCurrentPage(LEAD_FORM_PAGE.CLASS_CODE_SEARCH);
            else {
              if (offeredProducts.length > 0) setCurrentPage(LEAD_FORM_PAGE.PRODUCT_SELECT);
              else authentic.setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_REJECTED);
            }
            if (analytics.isInit) {
              analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_BUSINESS_INFO_PAGE, {
                wasShownLeadFormWelcomePage: showWelcomePage,
                businessName,
                businessClassCode: authentic.applicationMeta.businessClassCode.length > 0
                  ? authentic.applicationMeta.businessClassCode
                  : undefined,
                stateCodes: authentic.applicationMeta.stateCodes
              });
            }
          },
          isDisabled: authentic.isLoadingAvailableProducts ||
            businessName === null ||
            authentic.applicationMeta.stateCodes.length === 0 ||
            (!showClassCodeSearchPage && authentic.applicationMeta.businessClassCode.length === 0),
          onDisabledClick: () => { setHasAttemptedSubmit(true); },
          isLoading: authentic.isLoadingAvailableProducts
        }}
        footer={initialPage !== LEAD_FORM_PAGE.PREFILL && <TermsAndPrivacyFooter />}
      >
        <BusinessInfoInputs
          businessName={businessName}
          setBusinessName={setBusinessName}
          showClassCodeSearchPage={showClassCodeSearchPage}
          availableBusinessClasses={authentic.availableBusinessClasses}
          hasAttemptedSubmit={hasAttemptedSubmit}
        />
      </ApplicationPage>
      <ApplicationPage
        isActive={isInit && isActive && currentPage === LEAD_FORM_PAGE.CLASS_CODE_SEARCH}
        isCompleted={isCompleted || currentPage > LEAD_FORM_PAGE.CLASS_CODE_SEARCH}
        customerIndustry={customerIndustry}
        title={`What type of business ${businessName !== null ? `is ${businessName}` : 'do you have'}?`}
      >
        <BusinessClassSearch
          onContinue={() => {
            setCurrentPage(LEAD_FORM_PAGE.PRODUCT_SELECT);
            if (analytics.isInit) {
              analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_BUSINESS_CLASS_CODE_SEARCH_PAGE, {
                businessClassSearchQuery: authentic.businessClassSearchQuery,
                businessClasses: authentic.availableBusinessClasses,
                businessClassCode: authentic.applicationMeta.businessClassCode
              });
            }
          }
        }
        />
      </ApplicationPage>
      <ApplicationPage
        isActive={isInit && isActive && currentPage === LEAD_FORM_PAGE.PRODUCT_SELECT}
        isCompleted={isCompleted || currentPage > LEAD_FORM_PAGE.PRODUCT_SELECT}
        customerIndustry={customerIndustry}
        title={`Here's what coverage${offeredAndAddOnProducts.length > 1 ? 's' : ''} most ${getBrandNameWithoutInsurance(theme)} {{INDUSTRY_PLURAL}} need.`}
        continueButton={{
          onClick: () => {
            if (initialPage === LEAD_FORM_PAGE.PREFILL && isApplicantInfoComplete) void handleSubmitApplicationMeta();
            else setCurrentPage(LEAD_FORM_PAGE.APPLICANT_INFO);
            if (analytics.isInit) {
              analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_PRODUCT_SELECT_PAGE, {
                productIds: authentic.applicationMeta.productIds
              });
            }
          },
          isDisabled: isSubmitting || authentic.applicationMeta.productIds.length === 0,
          isLoading: isSubmitting
        }}
        footer={
          <Text maxW='325px' c='muted' fontSize='sm' textAlign='center'>
            Don't see your coverage? Our agents can help tailor a policy for your business.&nbsp;
            <Link inline href={`tel:${supportPhoneNumber}`}>
              {formatPhoneNumber(supportPhoneNumber)}
            </Link>
          </Text>
        }
      >
        {offeredAndAddOnProducts.map((product, i) => {
          const isSelected = authentic.applicationMeta.productIds.includes(product.id);
          // If this is the only "offered" product, it's mandatory.
          const isMandatory = product.isOffered && offeredProducts.length === 1;
          // If product is not "offered", it's optional but dependent on an "offered" product.
          const dependentProductIds = offeredProducts.filter((p) => p.addOnProductIds.includes(product.id)).map((p) => p.id);
          const isDisabled = !product.isOffered && !dependentProductIds.some((id) => authentic.applicationMeta.productIds.includes(id));

          return (
            <ProductCard
              key={i}
              mt={i > 0 ? 2 : undefined}
              product={product}
              isMandatory={isMandatory}
              isActive={isSelected}
              onChange={(isActive) => {
                authentic.setApplicationMeta({
                  ...authentic.applicationMeta,
                  productIds: isActive
                    ? [...authentic.applicationMeta.productIds, product.id]
                    : authentic.applicationMeta.productIds.filter((id) => id !== product.id)
                });
              }}
              isDisabled={isDisabled || isSubmitting}
            />
          );
        })}
      </ApplicationPage>
      <ApplicationPage
        isActive={isInit && isActive && currentPage === LEAD_FORM_PAGE.APPLICANT_INFO}
        isCompleted={isCompleted}
        customerIndustry={customerIndustry}
        title={`Great! Let's get ${businessName ?? 'you'} covered.`}
        continueButton={{
          onClick: () => {
            void handleSubmitApplicationMeta();
            if (analytics.isInit) {
              analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_APPLICANT_INFO_PAGE, {
                applicantName,
                email: authentic.applicationMeta.email
              });
            }
          },
          isDisabled: isSubmittingDisabled || isSubmitting,
          onDisabledClick: () => { setHasAttemptedSubmit(true); },
          isLoading: isSubmitting
        }}
      >
        <ApplicantInfoInputs
          applicantName={applicantName}
          setApplicantName={setApplicantName}
          hasAttemptedSubmit={hasAttemptedSubmit}
          isSubmitting={isSubmitting}
        />
      </ApplicationPage>
    </>
  );
}

function BusinessInfoInputs({
  businessName,
  setBusinessName,
  showClassCodeSearchPage,
  availableBusinessClasses,
  hasAttemptedSubmit,
  ...themeStyleProps
}: BusinessInfoInputsProps): ReactElement {
  const authentic = useAuthentic();

  return (
    <Box column w='100%' {...themeStyleProps}>
      <Input
          mb={3}
          label='Business name'
          iconName='RiAtLine'
          rightElement={<Tooltip label='Please enter the legal name of your business. If applicable, include the "Doing Business As" (DBA) name.' />}
          value={businessName ?? undefined}
          onChange={setBusinessName}
          error={hasAttemptedSubmit && businessName === null}
        />
        {!showClassCodeSearchPage && (
          <Select
            mb={3}
            isNullable={availableBusinessClasses.length > 1}
            label='Business type'
            iconName='RiBriefcaseLine'
            options={availableBusinessClasses.map((businessClass) => ({
              value: businessClass.code,
              label: businessClass.description
            }))}
            value={authentic.applicationMeta.businessClassCode}
            onChange={(v: string | null) => {
              authentic.setApplicationMeta({ ...authentic.applicationMeta, businessClassCode: v ?? '' });
            }}
            error={hasAttemptedSubmit && authentic.applicationMeta.businessClassCode.length === 0}
            isLoading={availableBusinessClasses.length === 0}
          />
        )}
        <Select
          isMultiSelect
          placeholder='Add states of operation...'
          iconName='RiMap2Line'
          options={STATES.map((state) => ({
            value: state.code,
            label: state.name
          }))}
          value={authentic.applicationMeta.stateCodes.sort()}
          onChange={(v: string[]) => {
            authentic.setApplicationMeta({ ...authentic.applicationMeta, stateCodes: v as StateCode[] });
          }}
          error={hasAttemptedSubmit && authentic.applicationMeta.stateCodes.length === 0}
        />
    </Box>
  );
}

function BusinessClassSearch({
  onContinue,
  ...themeStyleProps
}: BusinessClassSearchProps): ReactElement {
  const authentic = useAuthentic();
  const analytics = useAnalytics();
  const [searchQueryInternal, setSearchQueryInternal] = useState<string | null>(null);
  const [isDebounced, setIsDebounced] = useState<boolean>(false);
  const [showAllBusinessClasses, setShowAllBusinessClasses] = useState<boolean>(false);
  const showSearchResults = (authentic.businessClassSearchQuery !== null || showAllBusinessClasses) && !authentic.isLoadingAvailableBusinessClasses;
  const [selectedBusinessClassCode, setSelectedBusinessClassCode] = useState<string | null>(null);
  const [isSelectionDebounced, setIsSelectionDebounced] = useState<boolean>(false);
  const [hoveredBusinessClassOptionIndex, setHoveredBusinessClassOptionIndex] = useState<number>(-1);

  // When the search query changes, initiate a debounce period.
  useEffect(() => {
    setIsDebounced(false);
    if (searchQueryInternal === null) {
      authentic.setBusinessClassSearchQuery(null);
      return;
    }
    setShowAllBusinessClasses(false);
    const debounceTimeout = setTimeout(() => { setIsDebounced(true); }, INPUT_SEARCH_DEBOUNCE_DURATION_MS);
    return () => { clearTimeout(debounceTimeout); };
  }, [searchQueryInternal]);

  // Once debounced, set the business class search query to initiate a search.
  useEffect(() => {
    if (!isDebounced) return;
    authentic.setBusinessClassSearchQuery(searchQueryInternal);
  }, [isDebounced]);

  // Once a business class code is selected, update application meta.
  useEffect(() => {
    setIsSelectionDebounced(false);
    if (selectedBusinessClassCode === null) return;
    authentic.setApplicationMeta({ ...authentic.applicationMeta, businessClassCode: selectedBusinessClassCode });
    // Allow time for `authentic.isLoadingAvailableProducts` to be set.
    setTimeout(() => setIsSelectionDebounced(true), MS_PER_SECOND);
  }, [selectedBusinessClassCode]);

  // Once the selection has been debounced and available products have been loaded, allow time for "offered" products to be parsed.
  useEffect(() => {
    if (authentic.isLoadingAvailableProducts || selectedBusinessClassCode === null || !isSelectionDebounced) return;
    const isLeadRejected = !authentic.availableProducts.some((product) => product.isOffered);
    if (isLeadRejected) authentic.setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_REJECTED);
    else onContinue();
    setSelectedBusinessClassCode(null);
  }, [authentic.isLoadingAvailableProducts, selectedBusinessClassCode, isSelectionDebounced]);

  return (
    <Box column w='100%'>
      <Input
        placeholder='Describe your business...'
        iconName='RiBardLine'
        value={searchQueryInternal ?? undefined}
        onChange={setSearchQueryInternal}
        isDisabled={authentic.isLoadingAvailableProducts}
        isLoading={authentic.isLoadingAvailableBusinessClasses}
        {...themeStyleProps}
      />
      <Box
        centered
        column
        w='100%'
        maxH={showSearchResults ? ((authentic.availableBusinessClasses.length + 1) * 150) + 'px' : '0px'}
        p={0.5}
        opacity={showSearchResults ? 1 : 0}
        transition
        transitionDuration={Math.max(Math.min(getRoundedNumber(authentic.availableBusinessClasses.length / 4), 8), 2)}
        overflow='hidden'
        pointerEvents={showSearchResults ? 'auto' : 'none'}
      >
        {authentic.availableBusinessClasses
          .sort((a, b) => authentic.businessClassSearchQuery !== null
            ? b.searchQuerySimilarityScore - a.searchQuerySimilarityScore
            : a.description.localeCompare(b.description))
          .map((businessClass, i) => {
            const hasBeenSelected = selectedBusinessClassCode === businessClass.code;
            const useHoverStyles = authentic.isLoadingAvailableProducts ? hasBeenSelected : (i === hoveredBusinessClassOptionIndex);

            return (
              <Fragment key={i}>
                <Button
                  key={i}
                  justify='space-between'
                  w='100%'
                  mt={i === 0 ? 1 : undefined}
                  px={2}
                  py={1.5}
                  bg={useHoverStyles ? 'surface' : undefined}
                  radius='base'
                  onHover={(isHovered) => setHoveredBusinessClassOptionIndex(isHovered ? i : -1)}
                  onFocus={(isFocused) => setHoveredBusinessClassOptionIndex(isFocused ? i : -1)}
                  onClick={() => {
                    setSelectedBusinessClassCode(businessClass.code);
                    if (analytics.isInit) {
                      analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_LEAD_FORM_BUSINESS_CLASS_CODE_SEARCH_PAGE, {
                        businessClassCode: businessClass.code
                      });
                    }
                  }}
                  isDisabled={authentic.isLoadingAvailableProducts}
                >
                  <Text pr={4} bold>{businessClass.description}</Text>
                  {hasBeenSelected
                    ? (<Spinner position='absolute' top='50%' right={2} />)
                    : (<Icon
                        name='RiArrowRightLine'
                        position='absolute'
                        top='50%'
                        right={useHoverStyles ? 2 : 3}
                        opacity={useHoverStyles ? 1 : 0}
                        transition
                      />)}
                </Button>
                {i < authentic.availableBusinessClasses.length - 1 && <Divider my={1} />}
              </Fragment>
            );
          })}
        {authentic.businessClassSearchQuery !== null && (
          <Text mt={3}>
            {authentic.availableBusinessClasses.length > 0
              ? "Don't see your business type?"
              : 'No results, please try another search.'}&nbsp;
            <Button
              variant='link'
              inline
              onClick={() => {
                setSearchQueryInternal(null);
                authentic.setBusinessClassSearchQuery(null);
                setShowAllBusinessClasses(true);
                if (analytics.isInit) {
                  analytics.trackEvent(ANALYTICS_EVENT.CLICKED_LEAD_FORM_BUSINESS_CLASS_CODE_SEARCH_VIEW_ALL_BUTTON, {
                    searchQuery: authentic.businessClassSearchQuery
                  });
                }
              }}
              isDisabled={authentic.isLoadingAvailableProducts}
            >
              View all
            </Button>
          </Text>
        )}
      </Box>
    </Box>
  );
}

function ApplicantInfoInputs({
  applicantName,
  setApplicantName,
  hasAttemptedSubmit,
  isSubmitting,
  ...themeStyleProps
}: ApplicantInfoInputsProps): ReactElement {
  const authentic = useAuthentic();

  return (
    <Box column w='100%' {...themeStyleProps}>
      <NameInput
        mb={3}
        label='Full name'
        value={applicantName ?? undefined}
        onChange={setApplicantName}
        error={hasAttemptedSubmit && applicantName === null}
        isDisabled={isSubmitting}
      />
      <EmailInput
        rightElement={<Tooltip label="We'll use this email to send you your policy documents once you purchase." />}
        value={authentic.applicationMeta.email.length > 0 ? authentic.applicationMeta.email : undefined}
        onChange={(v) => {
          authentic.setApplicationMeta({ ...authentic.applicationMeta, email: v ?? '' });
        }}
        error={hasAttemptedSubmit && authentic.applicationMeta.email.length === 0}
        isDisabled={isSubmitting}
      />
    </Box>
  );
}

function TermsAndPrivacyFooter(themeStyleProps: ThemeStyleProps): ReactElement {
  return (
    <Text maxW='325px' c='muted' fontSize='sm' textAlign='center' {...themeStyleProps}>
      By continuing you agree to {AUTHENTIC_BRAND_NAME}'s&nbsp;
      <Link
        inline
        c='muted'
        href={`${AUTHENTIC_WEBSITE_URL}/docs/authentic-terms-of-service.pdf`}
        openInNewTab
      >
        Terms of Service
      </Link>
      &nbsp;and&nbsp;
      <Link
        inline
        c='muted'
        href={`${AUTHENTIC_WEBSITE_URL}/docs/authentic-privacy-policy.pdf`}
        openInNewTab
      >
        Privacy Policy
      </Link>.
    </Text>
  );
}
