import { type ReactElement, useState, useEffect } from 'react';
import * as Authentic from '@authenticins/ts-client';
import {
  useTheme,
  Box,
  Text,
  Button,
  Icon
} from '@authenticins/react-ui';

import { useAuthentic } from '../../providers';
import {
  type ApplicationPageProps,
  ApplicationPage,
  ApplicationFieldInput,
  injectCustomerIndustry
} from '.';

type ExposurePagesProps = Pick<ApplicationPageProps, 'isActive' | 'isCompleted' | 'customerIndustry'> & {
  setNumberOfExposurePages: (numberOfExposurePages: number) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

export function ExposurePages({
  isActive = false,
  isCompleted = false,
  customerIndustry,
  setNumberOfExposurePages,
  isSubmitting,
  setIsSubmitting
}: ExposurePagesProps): ReactElement {
  const authentic = useAuthentic();
  const theme = useTheme();
  const [exposures, setExposures] = useState<Authentic.ApplicationExposure[]>([]);
  const currentExposure = exposures[authentic.currentApplicationPageIndex] ?? null;
  const currentExposureSections = currentExposure?.sections ?? [];
  const currentExposureFields = currentExposureSections.flatMap((section) => section.fields);
  const [currentExposureFieldValues, setCurrentExposureFieldValues] = useState<Record<string, Authentic.ApplicationFieldValue>>({});
  const areCurrentExposureFieldsFilled = currentExposureFields
    .filter((field) => !field.isOptional)
    .every((mandatoryField) => typeof currentExposureFieldValues[mandatoryField.name] === 'string');
  const [isAddingExposure, setIsAddingExposure] = useState<boolean>(false);
  const [exposureIdToEdit, setExposureIdToEdit] = useState<string | null>(null);
  const showExposureFields = isAddingExposure || exposureIdToEdit !== null;
  const areExposureToEditFieldsNew = !Object.entries(currentExposureFieldValues)
    .every(([fieldName, fieldValue]) => authentic.application?.answers.exposures
      .find((exposureResponse) => exposureResponse.id === exposureIdToEdit)?.fieldValues[fieldName] === fieldValue);
  const isSubmittingDisabled = exposureIdToEdit !== null ? !areExposureToEditFieldsNew : !areCurrentExposureFieldsFilled;
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const isContinuingDisabled = authentic.application !== null
    ? (authentic.application.answers.exposures.length === 0 ||
      !authentic.application.answers.exposures
        .every((exposureResponse) => currentExposureFields
          .every((field) => field.isOptional || typeof exposureResponse.fieldValues[field.name] === 'string')))
    : true;
  const [isInit, setIsInit] = useState<boolean>(false);
  const isActiveInternal = isInit && isActive;

  // Allow time for exposure pages to render before they become active.
  useEffect(() => {
    if (authentic.application === null) return;
    setTimeout(() => { setIsInit(true); }, theme.transition.baseDurationMs);
  }, [authentic.application]);

  // Determine whether to prompt adding or finishing an exposure response on initialization.
  useEffect(() => {
    if (authentic.application === null || currentExposure === null) return;
    const currentExposureResponses = authentic.application.answers.exposures
      .filter((exposureResponse) => exposureResponse.exposureName === currentExposure.name);
    if (currentExposureResponses.length > 0) {
      const firstIncompleteCurrentExposureResponse = currentExposureResponses
        .find((exposureResponse) => currentExposureFields
          .filter((field) => !field.isOptional)
          .some((mandatoryField) => typeof exposureResponse.fieldValues[mandatoryField.name] !== 'string'));
      if (typeof firstIncompleteCurrentExposureResponse !== 'undefined') {
        setCurrentExposureFieldValues(firstIncompleteCurrentExposureResponse.fieldValues);
        setExposureIdToEdit(firstIncompleteCurrentExposureResponse.id);
      }
    } else setIsAddingExposure(true);
  }, [authentic.application, isActiveInternal]);

  // When any exposure's field value changes, filter all exposures by their sections' fields' conditionals.
  useEffect(() => {
    if (authentic.application === null) return;
    const filteredExposures: Authentic.ApplicationExposure[] = [];
    // We need the application's "CLASS_DESCRIPTION" in order to filter exposure fields by "EXPOSURE_CLASS_DESCRIPTION".
    const applicationClassDescription = authentic.application.answers.questions['CLASS_DESCRIPTION'];
    if (typeof applicationClassDescription === 'undefined') return;
    for (const exposure of authentic.application.exposures) {
      const filteredExposureSections = Authentic.filterApplicationSectionsByConditionals(exposure.sections, {
        ...authentic.application?.answers.questions,
        ...currentExposureFieldValues,
        EXPOSURE_CLASS_DESCRIPTION: applicationClassDescription
      }, true);
      if (filteredExposureSections.length > 0) {
        filteredExposures.push({
          ...exposure,
          sections: filteredExposureSections
        });
      }
    }
    setNumberOfExposurePages(filteredExposures.length);
    setExposures(filteredExposures);
  }, [authentic.application, currentExposureFieldValues, isActiveInternal]);

  async function handleSubmitExposureResponse(exposureResponse: Authentic.ApplicationExposureResponse): Promise<void> {
    setIsSubmitting(true);
    const wasError = await authentic.updateApplicationExposureResponse(exposureResponse);
    if (!wasError) {
      setIsAddingExposure(false);
      setExposureIdToEdit(null);
      setCurrentExposureFieldValues({});
    }
    setHasAttemptedSubmit(false);
    setIsSubmitting(false);
  }

  return (
    <>
      {exposures.map((exposure, i) => {
        const exposureResponses = authentic.application?.answers.exposures
          .filter((exposureResponse) => exposureResponse.exposureName === exposure.name) ?? [];
        const exposureDisplayName = Authentic.getApplicationExposureDisplayName(exposure.name);
        const exposureDisplayNameShorthand = exposureDisplayName.split(' ')[exposureDisplayName.split(' ').length - 1] ?? '';
        const isActiveExposure = isActiveInternal && i === authentic.currentApplicationPageIndex;

        return (
          <ApplicationPage
            key={i}
            isActive={isActiveExposure}
            isCompleted={isCompleted || i < authentic.currentApplicationPageIndex}
            customerIndustry={customerIndustry}
            title={exposure.title}
            footer={(
              <Box centered mt={2.5}>
                <Box
                  centered
                  column
                  w='100%'
                  opacity={showExposureFields ? 1 : 0}
                  transition
                  transitionDelay={showExposureFields ? 1 : undefined}
                  pointerEvents={!showExposureFields ? 'none' : undefined}
                >
                  <Button
                    variant='primary'
                    onClick={() => {
                      if (authentic.application === null) return;
                      const exposureResponse = {
                        id: exposureIdToEdit ?? (authentic.application.answers.exposures.length + 1).toString(),
                        exposureName: exposure.name,
                        fieldValues: currentExposureFieldValues
                      };
                      void handleSubmitExposureResponse(exposureResponse);
                    }}
                    isDisabled={isSubmittingDisabled || isSubmitting}
                    onDisabledClick={() => { setHasAttemptedSubmit(true); }}
                    isLoading={isSubmitting}
                  >
                    {exposureIdToEdit !== null ? 'Update' : 'Add'}&nbsp;
                    {exposureDisplayNameShorthand}
                  </Button>
                  {exposureResponses.length > 0 && (
                    <Button
                      variant='link'
                      mt={1.5}
                      c='error'
                      onClick={() => {
                        setIsAddingExposure(false);
                        setExposureIdToEdit(null);
                        setCurrentExposureFieldValues({});
                        setHasAttemptedSubmit(false);
                      }}
                      isDisabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
                <Box
                  centered
                  position='absolute'
                  w='100%'
                  opacity={!showExposureFields ? 1 : 0}
                  transition
                  transitionDelay={!showExposureFields ? 1 : undefined}
                  pointerEvents={showExposureFields ? 'none' : undefined}
                >
                  <Button variant='secondary' mr={2} onClick={() => { setIsAddingExposure(true); }}>
                    <Icon name='RiAddLargeLine' mr={1} />
                    Add another {exposureDisplayNameShorthand}
                  </Button>
                  <Button
                    variant='primary'
                    onClick={() => { authentic.setCurrentApplicationPageIndex(authentic.currentApplicationPageIndex + 1); }}
                    isDisabled={!isActiveExposure || isContinuingDisabled}
                    onDisabledClick={() => { setHasAttemptedSubmit(true); }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            )}
          >
            <Box
              column
              w={`calc(100% + ${theme.baseSpacingPx}px)`}
              maxH={showExposureFields ? '1000px' : '0px'}
              p={0.5}
              opacity={showExposureFields ? 1 : 0}
              transition
              transitionDuration={2}
              transitionDelay={showExposureFields ? 1 : undefined}
              overflow='hidden'
              pointerEvents={!showExposureFields ? 'none' : undefined}
            >
              {exposure.sections.map((section, j) => {
                const showSectionTitle = exposure.sections.some((section) => section.fields.length > 1);

                return (
                  <Box key={j} column w='100%' mt={j > 0 ? 3 : undefined}>
                    {showSectionTitle && (
                      <Text variant='label' mb={1}>
                        {injectCustomerIndustry(section.title, customerIndustry)}
                      </Text>
                    )}
                    {section.fields.map((field, k) => (
                      <Box key={k} w='100%' mt={k > 0 ? 2 : undefined}>
                        <ApplicationFieldInput
                          field={{
                            ...field,
                            title: injectCustomerIndustry(field.title, customerIndustry),
                            label: typeof field.label !== 'undefined' ? injectCustomerIndustry(field.label, customerIndustry) : undefined
                          }}
                          validValues={authentic.application !== null && field.type === 'address' ? authentic.application.meta.stateCodes : undefined}
                          value={currentExposureFieldValues[field.name] ?? null}
                          onChange={(v) => {
                            setCurrentExposureFieldValues({
                              ...currentExposureFieldValues,
                              [field.name]: v
                            });
                          }}
                          error={!field.isOptional && hasAttemptedSubmit && typeof currentExposureFieldValues[field.name] !== 'string'}
                          isDisabled={isSubmitting}
                        />
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box>
            <Box
              column
              w={`calc(100% + ${theme.baseSpacingPx}px)`}
              maxH={!showExposureFields ? '1000px' : '0px'}
              opacity={!showExposureFields ? 1 : 0}
              transition
              transitionDuration={2}
              transitionDelay={!showExposureFields ? 1 : undefined}
              overflow='hidden'
              pointerEvents={showExposureFields ? 'none' : undefined}
            >
              {exposureResponses.map((exposureResponse, j) => {
                const isExposureResponseComplete = exposure.sections
                  .flatMap((section) => section.fields)
                  .filter((field) => !field.isOptional)
                  .every((mandatoryField) => typeof exposureResponse.fieldValues[mandatoryField.name] === 'string');
                const exposureResponseIconName = exposure.sections[0]?.fields[0]?.type === 'address' ? 'RiMapPinLine' : 'RiCheckboxLine';

                return (
                  <Box key={j} column w='100%' mt={j > 0 ? 2 : undefined}>
                    <Box
                      align='center'
                      justify='space-between'
                      w='100%'
                      p={2}
                      bg='surface'
                      border={!isExposureResponseComplete ? 'error' : undefined}
                      radius='base'
                    >
                      <Box centered>
                        <Icon name={exposureResponseIconName} mr={1} c='muted' />
                        <Text pr={1}>{Authentic.getApplicationExposureResponseDisplayName(exposureResponse, exposure)}</Text>
                      </Box>
                      <Box w='55px' justify='flex-end'>
                        <Button
                          variant='link'
                          onClick={() => {
                            setCurrentExposureFieldValues(exposureResponse.fieldValues);
                            setExposureIdToEdit(exposureResponse.id);
                          }}
                          isDisabled={isSubmitting}
                        >
                          Edit
                          <Icon name='RiEdit2Line' ml={1} />
                        </Button>
                      </Box>
                    </Box>
                    {!isExposureResponseComplete && (
                      <Text mt={0.5} c='error' fontSize='sm'>
                        Missing information, please&nbsp;
                        <Button
                          variant='link'
                          inline
                          onClick={() => {
                            setCurrentExposureFieldValues(exposureResponse.fieldValues);
                            setExposureIdToEdit(exposureResponse.id);
                          }}
                        >
                          finish {exposureDisplayNameShorthand}
                        </Button>
                      </Text>
                    )}
                  </Box>
                );
              })}
            </Box>
          </ApplicationPage>
        );
      })}
    </>
  );
}
