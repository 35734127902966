import { type ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';

import { useAnalytics, useAuthentic } from '.';

interface FunctionalFinance {
  isInit: boolean;
  errorMessage: string | null;
  isSubmittingPaymentDisabled: boolean;
  isSubmittingPayment: boolean;
  wasPaymentSuccessful: boolean;
  configureCardPaymentForm: (cardPaymentFormConfig: CardPaymentFormConfig) => void;
  configurePayment: (ffPaymentConfig: Record<string, any>) => void;
  submitPayment: (isPayingInFull: boolean, paymentData: PaymentData) => Promise<void>;
};
type CardPaymentFormElement = 'cardNumberWrapper' | 'expirationDateWrapper' | 'cvcWrapper';
// Where each value is the HTML ID of the wrapper element.
type CardPaymentFormConfig = Record<CardPaymentFormElement, string>;
interface PaymentData {
  payerName: string;
  billingZipCode: string;
  bankAccount?: {
    isSavingsAccount: boolean;
    routingNumber: string;
    accountNumber: string;
  };
};
type FunctionalFinanceErrorCode =
  | 'address'
  | 'cvv'
  | 'data_messaging'
  | 'decline'
  | 'duplicate_transaction'
  | 'expired'
  | 'fraud_compliance'
  | 'insufficient_funds'
  | 'limit_violation'
  | 'not_supported_restricted';

const FUNCTIONAL_FINANCE_ERRORS: Record<FunctionalFinanceErrorCode, string> = {
  data_messaging: 'Invalid card details',
  address: 'Invalid billing address',
  cvv: 'Invalid CVV',
  expired: 'Card expired',
  decline: 'Card declined',
  insufficient_funds: 'Insufficient funds',
  limit_violation: 'Card limit exceeded',
  duplicate_transaction: 'Duplicate transaction',
  not_supported_restricted: 'Card restricted or unsupported',
  fraud_compliance: 'Please use another payment method'
};
const FunctionalFinanceContext = createContext<FunctionalFinance>({} as unknown as FunctionalFinance);

/**
 * Provides context for {@link FunctionalFinance | Functional Finance payments} to its children.
 */
export function FunctionalFinanceProvider({
  environment = 'prod',
  children
}: {
  environment?: 'sandbox' | 'prod';
  children: ReactNode;
}): ReactNode {
  const analytics = useAnalytics();
  const authentic = useAuthentic();

  const [functionalFinance, setFunctionalFinance] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmittingPaymentDisabled, setIsSubmittingPaymentDisabled] = useState<boolean>(true);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState<boolean>(false);
  const [wasPaymentSuccessful, setWasPaymentSuccessful] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  // Initialize Functional Finance's "Payment Interface" script to the DOM on mount.
  useEffect(() => {
    if (isInit) return;
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://${environment}.payments-library.functionalfi.com/ffinterface/v2.0/${
      // Functional Finance uses "production" here, but keeps "prod" in the subdomain.
      environment === 'prod'
        ? 'production'
        : environment
    }/ffPaymentInterface.js`;
    script.addEventListener('load', () => {
      // @ts-expect-error - We know the interface is exposed to the browser window.
      setFunctionalFinance(window.ffPaymentInterface);
      setIsInit(true);
    });
    document.head.appendChild(script);
  }, []);

  /**
   * Configures Functional Finance's injected card payment form with the given {@link Authentic.CardPaymentFormConfig | payment form config}.
   */
  function configureCardPaymentForm(cardPaymentFormConfig: CardPaymentFormConfig): void {
    if (!isInit) return;
    functionalFinance.configurePaymentInterface(
      Object.entries(cardPaymentFormConfig).reduce((acc, [formElement, wrapperElementId]) => ({
        ...acc,
        [formElement]: {
          wrapper: wrapperElementId,
          placeholder: formElement === 'cardNumberWrapper'
            ? 'Enter card number'
            : formElement === 'expirationDateWrapper'
              ? 'MM/YY'
              : 'CVC'
        }
      }), {}),
      (formStatus: { submitEnabled: boolean; formSubmitting: boolean }) => {
        setIsSubmittingPaymentDisabled(!formStatus.submitEnabled);
        setIsSubmittingPayment(formStatus.formSubmitting);
      }
    );
  }

  /**
   * Configures a Functional Finance payment with the given payment config.
   */
  function configurePayment(ffPaymentConfig: Record<string, any>): void {
    if (!isInit) return;
    functionalFinance.configure(ffPaymentConfig);
  }

  /**
   * Submits the previously configured Functional Finance payment using the given {@link PaymentData | payment data}.
   */
  async function submitPayment(isPayingInFull: boolean, paymentData: PaymentData): Promise<void> {
    if (!isInit) return;
    try {
      setErrorMessage(null);
      const isPayingWithACH = typeof paymentData.bankAccount !== 'undefined';
      await functionalFinance[isPayingInFull ? 'submitPayment' : 'bindPFAAndSubmitDownpayment'](
        isPayingWithACH ? 'ach' : 'card',
        {
          name: paymentData.payerName,
          address: { postal_code: paymentData.billingZipCode },
          ...(isPayingWithACH
            ? {
                // @ts-expect-error - We know the bank account details are defined.
                bankAccountType: paymentData.bankAccount.isSavingsAccount ? 'SAVINGS' : 'CHECKING',
                // @ts-expect-error - ^
                bankRoutingNumber: paymentData.bankAccount.routingNumber,
                // @ts-expect-error - ^
                bankAccountNumber: paymentData.bankAccount.accountNumber
              }
            : {}),
          // Automatically store payment details.
          user_consents_to_stored_payment_instrument: true
        },
        () => {
          setWasPaymentSuccessful(true);
          if (analytics.isInit) {
            analytics.trackEvent(ANALYTICS_EVENT.PURCHASED_APPLICATION_QUOTES, {
              applicationId: authentic.application?.id,
              quoteIds: authentic.application?.quotes.map((quote) => quote.id)
            });
          }
        },
        ({ status, error }: { status: string; error?: { errors?: Array<{ errorCode: FunctionalFinanceErrorCode }> } }) => {
          if (isSubmittingPayment || status !== 'failure') return;
          const ffErrorCode = error?.errors?.[0]?.errorCode;
          const errorMessage = (typeof ffErrorCode !== 'undefined'
            ? FUNCTIONAL_FINANCE_ERRORS[ffErrorCode]
            : undefined) ?? 'Unable to process payment';
          setErrorMessage(errorMessage);
          console.error('Error: Unable to process payment with Functional Finance.', ffErrorCode);
        });
    } catch (err) {
      console.error('Error: Unable to submit payment through Functional Finance.', err);
    }
  }

  return (
    <FunctionalFinanceContext.Provider
      value={{
        isInit,
        errorMessage,
        isSubmittingPaymentDisabled,
        isSubmittingPayment,
        wasPaymentSuccessful,
        configureCardPaymentForm,
        configurePayment,
        submitPayment
      }}
    >
      {children}
    </FunctionalFinanceContext.Provider>
  );
}

/**
 * Returns access to the {@link FunctionalFinanceContext | Functional Finance payments context}.
 */
export function useFunctionalFinance(): FunctionalFinance {
  const functionalFinance = useContext(FunctionalFinanceContext);
  if (typeof functionalFinance === 'undefined') {
    throw new Error('Error: Called "useFunctionalFinance()" outside a FunctionalFinanceProvider.');
  }
  return functionalFinance;
}
