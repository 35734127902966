import { type ReactElement, type ReactNode, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import {
  type ThemeStyleProps,
  type IconName,
  MAX_VIEW_CONTENT_WIDTH_PX,
  getBrandNameWithInsurance,
  getBrandNameWithoutInsurance,
  useTheme,
  View,
  Box,
  Text,
  Button,
  Image,
  Accordion,
  Icon
} from '@authenticins/react-ui';

import { ROUTE } from '../main';
import { useAnalytics } from '../providers';
import { ProductCard } from '../components';

interface HeroCopy {
  imageUrl: string;
  title: string;
  subtitle: string;
  ctaLabel: string;
  footer?: { iconName: IconName; text: string };
  exampleProductNames?: string[];
}
interface SectionContent {
  title?: ReactNode;
  subtitle?: ReactNode;
  testimonials?: Testimonial[];
  valueProps?: ValueProp[];
  faqs?: Faq[];
  ctaLabel?: string;
}
interface Testimonial {
  quote: string;
  name: string;
  description: string;
}
interface ValueProp {
  iconName: IconName;
  title: string;
  description: string;
}
interface Faq {
  question: string;
  answer: string;
}
type SectionProps = ThemeStyleProps & SectionContent & {
  footer?: ReactNode;
};
interface CtaButtonProps extends ThemeStyleProps {
  label: string;
}

const DEFAULT_HERO_COPY: HeroCopy = {
  imageUrl: 'https://images.unsplash.com/photo-1594402919317-9e67dca0a305?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  title: 'Insurance built for your business',
  subtitle: 'Guard against financial losses and empower your business to succeed.',
  ctaLabel: 'Get a quote in less than 5 minutes',
  footer: {
    iconName: 'RiSparkling2Line',
    text: 'Discounts available!'
  },
  exampleProductNames: ['General Liability', 'Property', 'Professional Liability']
};

export function WelcomeView(themeStyleProps: ThemeStyleProps): ReactElement {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const theme = useTheme();
  const heroContainerRef = useRef<HTMLDivElement>(null);
  const [heroCopy, setHeroCopy] = useState<HeroCopy>(DEFAULT_HERO_COPY);
  const showProductExamples = typeof heroCopy.exampleProductNames !== 'undefined' && window.innerWidth >= MAX_VIEW_CONTENT_WIDTH_PX;
  const [showPersistentCta, setShowPersistentCta] = useState<boolean>(false);
  const [sections, setSections] = useState<SectionContent[]>([
    {
      title: `Why businesses choose ${getBrandNameWithInsurance(theme)}`,
      subtitle: 'We understand your risks, so we built an insurance program just for you.',
      valueProps: [
        {
          iconName: 'RiComputerLine',
          title: 'Get covered in minutes',
          description: 'Just a few questions to get an instant quote. Activate your coverage online!'
        },
        {
          iconName: 'RiCoinsLine',
          title: 'Save on insurance',
          description: '5% exclusive discount applied to your policy. Cancel anytime with no fees.'
        },
        {
          iconName: 'RiStackLine',
          title: 'Custom coverage',
          description: 'We designed coverage specifically for your business. Simple to manage online.'
        },
        {
          iconName: 'RiCustomerService2Line',
          title: 'Expert guidance',
          description: 'Call or email our licensed agents to answer questions or customize a policy.'
        },
        {
          iconName: 'RiFlashlightLine',
          title: 'Fast and easy claims',
          description: "We'll strive to resolve claims in 48 hours or less, you focus on running your business."
        },
        {
          iconName: 'RiAwardLine',
          title: 'Backed by trusted insurers',
          description: 'Admitted "A- Excellent" AM Best rated and backed by Everspan Insurance Company.'
        }
      ]
    },
    {
      title: 'Making the switch is easy',
      subtitle: "Already covered? We'll help you switch to simpler coverage that saves you money."
    },
    {
      title: 'Ready to get started?',
      subtitle: 'Get a no-pressure quote in less than 5 minutes.',
      faqs: [
        {
          question: `Why is ${getBrandNameWithoutInsurance(theme)} selling insurance?`,
          answer: `Running your business is simpler when you can manage everything in the same place. With ${getBrandNameWithInsurance(theme)} we hope to arm you with the protection you need to help your business thrive.`
        },
        {
          question: `Who is eligible for ${getBrandNameWithInsurance(theme)}?`,
          answer: 'Eligibility is determined by the state where you operate your business. We are selling in all U.S. states except NY, WA, MN HI, AK.'
        },
        {
          question: 'What payment options are available?',
          answer: 'Multiple payment options are available. We offer flexibility with premium payments spread over the year, eliminating large upfront costs.'
        }
      ]
    }
  ]);

  // Update the document title on mount.
  useEffect(() => { document.title = `Welcome | ${getBrandNameWithInsurance(theme)}`; }, []);

  // Check analytics for welcome page overrides config.
  useEffect(() => {
    if (!analytics.isInit) return;
    const shouldRedirectToLeadFormWelcomePage = analytics.featureIsEnabled('lead_form_welcome_page');
    if (shouldRedirectToLeadFormWelcomePage) navigate(ROUTE.APPLY);
    else {
      const welcomePageOverridesConfig = analytics.getConfig('tenant_welcome_page_copy');
      if (typeof welcomePageOverridesConfig?.['hero'] !== 'undefined') setHeroCopy(welcomePageOverridesConfig['hero'] as HeroCopy);
      if (typeof welcomePageOverridesConfig?.['sections'] !== 'undefined') setSections(welcomePageOverridesConfig['sections'] as SectionContent[]);
    }
  }, [analytics.isInit]);

  // If scroll is past the hero container, show the persistent CTA button.
  useEffect(() => {
    function handleScroll(): void {
      setShowPersistentCta(window.scrollY >= (heroContainerRef.current?.clientHeight ?? 0));
    }
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll); };
  }, [heroContainerRef]);

  return (
    <View subviewThemeStyleProps={{ pt: 0 }} {...themeStyleProps}>
      <Box reference={heroContainerRef} position='relative' w='100vw'>
        <Box position='absolute' w='100%' h='100%'>
          <Image
            fit='cover'
            src={heroCopy.imageUrl}
            alt='Depiction of a small business'
          />
        </Box>
        <Box
          centered
          minH='450px'
          w='100%'
          zIndex={1}
          styles={{ backgroundColor: 'rgba(0, 0, 0, 0.68)' }}
        >
          <Box
            centered
            wrap
            w='100%'
            maxW={MAX_VIEW_CONTENT_WIDTH_PX + 'px'}
            px={2}
            py={4}
            c={theme.isDarkMode ? 'text' : 'surface'}
          >
            <Box
              centered={!showProductExamples}
              column
              w='100%'
              maxW={heroCopy.title.split('').length > 40 ? '650px' : '800px'}
              pr={showProductExamples ? 6 : undefined}
            >
              <Text
                variant='heading'
                fontSize='xl'
                textAlign={!showProductExamples ? 'center' : undefined}
                onMobile={{ fontSize: 2.25 }}
              >
                {heroCopy.title}
              </Text>
              <Text
                maxW='85%'
                mt={1.5}
                fontSize={1.25}
                textAlign={!showProductExamples ? 'center' : undefined}
              >
                {heroCopy.subtitle}
              </Text>
              <ApplyCtaButton
                mt={4}
                bg='secondary'
                c='secondaryContrast'
                label={heroCopy.ctaLabel}
              />
              {typeof heroCopy.footer !== 'undefined' && (
                <Box centered mt={1.5}>
                  <Text bold textAlign='center'>
                    <Icon name={heroCopy.footer.iconName} mr={1} />
                    {heroCopy.footer.text}
                  </Text>
                </Box>
              )}
            </Box>
            {showProductExamples && (
              <Box
                column
                w='100%'
                maxW='365px'
                transform='scale(0.8)'
                transition
              >
                {(heroCopy.exampleProductNames ?? []).map((productName, i) => (
                  <ProductCard
                    key={i}
                    align='center'
                    mt={i > 0 ? 1 : undefined}
                    c='text'
                    // @ts-expect-error - No need to pass full product data.
                    product={{ name: productName, carrier: { name: '', description: '' } }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {sections.map((section, i) => (
        <Section
          key={i}
          mb={i === sections.length - 1 ? -6 : undefined}
          bg={i === 1
            ? 'secondary'
            : (i + 1) % 2 === 0
                ? 'surface'
                : undefined}
          c={i === 1 ? 'secondaryContrast' : undefined}
          footer={typeof section.ctaLabel !== 'undefined' ? <ApplyCtaButton label={section.ctaLabel} /> : undefined}
          {...section}
        />
      ))}
      <ApplyCtaButton
        position='fixed'
        bottom={showPersistentCta ? 10 : 6}
        border='background'
        shadow='drop'
        opacity={showPersistentCta ? 1 : 0}
        pointerEvents={!showPersistentCta ? 'none' : undefined}
        zIndex={1}
        label={heroCopy.ctaLabel}
      />
    </View>
  );
}

export function Section({
  title,
  subtitle,
  testimonials,
  valueProps,
  faqs,
  footer,
  ...themeStyleProps
}: SectionProps): ReactElement {
  const theme = useTheme();
  const doesSectionHaveBackground = typeof themeStyleProps.bg !== 'undefined';

  return (
    <Box
      centered
      column
      w={doesSectionHaveBackground ? '100vw' : undefined}
      px={2}
      py={12}
      onMobile={{ px: doesSectionHaveBackground ? 2 : undefined, py: 8 }}
      {...themeStyleProps}
    >
      {typeof title !== 'undefined' && (
        <Text
          variant='heading'
          fontSize='xl'
          textAlign='center'
          onMobile={{ fontSize: 2 }}
        >
          {title}
        </Text>
      )}
      {typeof subtitle !== 'undefined' && (
        <Text
          mt={2}
          fontSize={1.25}
          textWrap='balance'
          textAlign='center'
          onMobile={{ fontSize: 'base' }}
        >
          {subtitle}
        </Text>
      )}
      {typeof testimonials !== 'undefined' && (
        <Box
          centered
          column
          w='100%'
          mt={(typeof title !== 'undefined' || typeof subtitle !== 'undefined') ? 3 : undefined}
        >
          {testimonials.map((testimonial, i) => (
            <Box key={i} centered w='100%' maxW='875px' my={4} onMobile={{ column: true }}>
              <Text variant='display' mb={-4} c='subtle' fontSize={8}>“</Text>
              <Box column w='100%' px={4}>
                <Text variant='heading' fontSize={1.25} bold={false}>
                  {testimonial.quote}
                </Text>
                <Text variant='heading' mt={1.5}>
                  {testimonial.name}
                </Text>
                <Text mt={0.5} c='muted' fontSize='sm'>
                  {testimonial.description}
                </Text>
              </Box>
              <Text variant='display' mb={-4} c='subtle' fontSize={8} onMobile={{ display: 'none' }}>”</Text>
            </Box>
          ))}
        </Box>
      )}
      {typeof valueProps !== 'undefined' && (
        <Box
          justify='center'
          wrap
          w='100%'
          mt={(typeof title !== 'undefined' || typeof subtitle !== 'undefined' || typeof testimonials !== 'undefined') ? 3 : undefined}
        >
          {valueProps.map((valueProp, i) => (
            <Box key={i} centered column w='100%' maxW='300px' m={3} onMobile={{ mx: 0 }}>
              <Icon
                name={valueProp.iconName}
                size='xl'
                c={themeStyleProps.c ?? (theme.palette.doesPrimaryPassAccessibility ? 'primary' : 'text')}
              />
              <Text variant='heading' mt={2} textAlign='center'>
                {valueProp.title}
              </Text>
              <Text mt={1.5} textAlign='center'>
                {valueProp.description}
              </Text>
            </Box>
          ))}
        </Box>
      )}
      {typeof faqs !== 'undefined' && (
        <Accordion
          bg={themeStyleProps.bg === 'surface' ? 'background' : 'surface'}
          mt={(typeof title !== 'undefined' || typeof subtitle !== 'undefined' || typeof testimonials !== 'undefined' || typeof valueProps !== 'undefined')
            ? 3
            : undefined}
          items={faqs.map((faq) => ({ title: faq.question, content: faq.answer }))}
        />
      )}
      <Box centered column mt={typeof footer !== 'undefined' ? 4 : undefined}>
        {footer}
      </Box>
    </Box>
  );
}

function ApplyCtaButton({ label, ...themeStyleProps }: CtaButtonProps): ReactElement {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  return (
    <Button
      pl={4}
      pr={5}
      py={2}
      variant='primary'
      fontSize={1.25}
      onClick={() => {
        navigate(ROUTE.APPLY + window.location.search);
        if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.CLICKED_WELCOME_PAGE_CTA, { label });
      }}
      onMobile={{ pl: 3, pr: 4, fontSize: 'base' }}
      {...themeStyleProps}
    >
      {label}
    </Button>
  );
}
