import type { ReactElement } from 'react';
import { useTheme, useThemeStyles } from '@authenticins/react-ui';

import { type ProductIconProps } from '.';

export function GymLiabilityIcon({ isActive = true, size = 4, ...themeStyleProps }: Omit<ProductIconProps, 'productName'>): ReactElement {
  const theme = useTheme();
  const themeStyles = useThemeStyles(themeStyleProps);
  const sizePx = (typeof size === 'number' ? size * theme.font.size.base : theme.font.size[size]) + 'px';
  const accentFill = isActive ? theme.palette.primary : theme.palette.muted;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1191 1185'
      fill='none'
      style={{
        ...themeStyles,
        minWidth: sizePx,
        width: sizePx,
        minHeight: sizePx,
        height: sizePx
      }}
    >
      <path d='M637.302 640.707L564.056 640.89L264.825 365.444L351.35 156.029L847.343 154.902L941.553 363.864L637.302 640.707Z' fill={accentFill} />
      <path d='M642.639 654.501L558.678 654.64L248.474 369.071L342.122 142.316L856.221 141.108L958.198 367.362L642.639 654.501ZM569.365 627.099L631.975 626.968L925.033 360.393L838.632 168.657L360.67 169.664L281.228 361.803L569.365 627.099Z' fill='black' />
      <path d='M660.454 880.547L542.768 880.819L570.222 628.085L631.75 627.929L660.454 880.547Z' fill={accentFill} />
      <path d='M675.866 894.309L527.383 894.64L557.869 614.326L644.022 614.116L675.866 894.309ZM558.099 867.011L644.988 866.795L619.479 641.742L582.628 641.831L558.099 867.011Z' fill='black' />
      <path d='M756.247 549.088L748.939 522.564L996.526 454.452L1087.41 267.053L891.953 267.515L891.873 239.943L1131.43 239.414L1015.85 477.705L756.247 549.088Z' fill='black' />
      <path d='M450.685 549.821L190.792 479.578L74.0107 241.851L313.564 241.268L313.645 268.839L118.171 269.249L209.962 456.24L457.916 523.22L450.685 549.821Z' fill='black' />
      <path d='M724.868 931.993L483.56 935.658L484.008 880.935L723.855 880.394L724.868 931.993Z' fill='black' />
      <path d='M738.971 945.728L468.686 946.411L470.543 867.271L737.327 866.634L738.971 945.728ZM496.878 918.649L710.805 918.189L710.314 894.114L497.416 894.61L496.878 918.649Z' fill='black' />
      <path d='M885.78 945.499L322.973 946.748L323.285 1079.1L886.092 1077.85L885.78 945.499Z' fill={accentFill} />
      <path d='M886.16 1077.83L323.372 1079.12L323.028 944.366L885.837 943.859L886.16 1077.83Z' fill='black' />
      {isActive && <path d='M409.582 393.969L354.25 347.407L405.845 208.066L428.281 216.376L382.545 339.904L425 375.687L409.582 393.969Z' fill='white' />
      }
    </svg>
  );
}
