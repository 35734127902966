import { type ReactElement, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Authentic from '@authenticins/ts-client';
import {
  type ThemeStyleProps,
  MS_PER_SECOND,
  SECS_PER_MINUTE,
  MINS_PER_HOUR,
  HOURS_PER_DAY,
  AUTHENTIC_CLAIMS_PHONE_NUMBER,
  AUTHENTIC_CLAIMS_EMAIL,
  MAX_ELEMENT_WIDTH_PX,
  getFormattedUtcDate,
  formatPhoneNumber,
  formatCurrency,
  getBrandNameWithInsurance,
  useTheme,
  View,
  Box,
  Text,
  Button,
  Input,
  Link,
  Table,
  Tag,
  Accordion,
  Modal,
  Icon,
  Spinner,
  Markdown
} from '@authenticins/react-ui';

import { ROUTE } from '../main';
import { useAnalytics, useAuthentic } from '../providers';

interface PolicyStatusTagProps {
  isProcessing: boolean;
  status: Authentic.POLICY_STATUS;
}
interface ClaimsFaq {
  question: string;
  answer: string;
}

// TODO: Remove when ts-engine is live or the statsig caching system is.
const DEFAULT_CLAIMS_FAQS: ClaimsFaq[] = [
  {
    question: 'How do I file a claim?',
    answer: 'To file a claim, please contact our claims administrator Reserv at the phone number or email address below.'
  },
  {
    question: 'What are the hours of operation for claims?',
    answer: 'Claims are accepted Monday - Friday from 8 AM to 5 PM Eastern.'
  }
];

export function PolicyView(themeStyleProps: ThemeStyleProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const navigate = useNavigate();
  const [filteredPolicies, setFilteredPolicies] = useState<Authentic.Policy[]>([]);
  const [currentPolicy, setCurrentPolicy] = useState<Authentic.Policy | null>(null);
  const [isClaimsContactModalOpen, setIsClaimsContactModalOpen] = useState<boolean>(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [claimsFaqs, setClaimsFaqs] = useState<ClaimsFaq[]>(DEFAULT_CLAIMS_FAQS);
  const [claimsPhoneNumber, setClaimsPhoneNumber] = useState<number>(AUTHENTIC_CLAIMS_PHONE_NUMBER);
  // TODO: use getMinifiedTenantName function
  // TODO: create constants for template strings
  // TODO: we use {{TENANT_NAME}} in adjustments, so export a constant to use there and here.
  const tenantClaimsEmail = AUTHENTIC_CLAIMS_EMAIL.replace('claim+', '').replace('@', `+${theme.brand.name.toLowerCase().replaceAll(' ', '')}@`);

  // Update the document title on mount.
  useEffect(() => { document.title = `Policy | ${getBrandNameWithInsurance(theme)}`; }, []);

  // Initialize the view on client initialization.
  useEffect(() => {
    if (!authentic.isInit) return;
    setIsInit(true);
  }, [authentic.isInit]);

  // Once analytics is initialized, check for claims FAQs and phone number.
  useEffect(() => {
    if (!analytics.isInit) return;
    const claimsFaqs = analytics.getConfig('tenant_claims_faqs')?.['claimsFaqs'] as ClaimsFaq[] | undefined;
    // Once claims FAQs are loaded, replace template strings with tenant name.
    if (typeof claimsFaqs !== 'undefined') {
      setClaimsFaqs(claimsFaqs.map((faq) => ({
        question: faq.question.replaceAll('{{TENANT_NAME}}', theme.brand.name),
        answer: faq.answer.replaceAll('{{TENANT_NAME}}', theme.brand.name)
      })));
    }
    const claimsPhoneNumber = analytics.getConfig('tenant_claims_phone_number')?.['phoneNumber'] as number | null;
    if (claimsPhoneNumber !== null) setClaimsPhoneNumber(claimsPhoneNumber);
  }, [analytics.isInit]);

  // Once policies are loaded, filter based on their status.
  useEffect(() => {
    if (authentic.isLoadingPolicies || authentic.policies.length === 0) return;
    // If there are any active policies, don't show policies that are pending payment.
    // Otherwise only show the most recent policy that is pending payment.
    const filteredPolicies = authentic.policies.filter((policy) => policy.status === Authentic.POLICY_STATUS.ACTIVE).length > 0
      ? authentic.policies.filter((policy) => policy.status !== Authentic.POLICY_STATUS.PENDING_PAYMENT)
      : [
          ...authentic.policies.filter((policy) => policy.status !== Authentic.POLICY_STATUS.PENDING_PAYMENT),
          ...authentic.policies.filter((policy) => policy.status === Authentic.POLICY_STATUS.PENDING_PAYMENT).slice(0, 1)
        ];
    setFilteredPolicies(filteredPolicies);
    // Auto-select first policy.
    setCurrentPolicy(filteredPolicies[0] ?? null);
  }, [authentic.isLoadingPolicies, authentic.policies]);

  function policyIsProcessing(policy: Authentic.Policy): boolean {
    return policy.status === Authentic.POLICY_STATUS.PENDING_PAYMENT &&
      parseInt(policy.policyStartTimestamp) >= (Date.now() - (5 * MS_PER_SECOND * SECS_PER_MINUTE));
  }

  return (
    <View centered={!isInit || authentic.isLoadingPolicies} {...themeStyleProps}>
      {isInit && !authentic.isLoadingPolicies && !authentic.auth.isAuthenticated && (
        <Text mb={3}>
          <b>Not seeing all your coverage?</b>&nbsp;You may need to&nbsp;
          <Button variant='link' inline onClick={authentic.promptLogin}>
            Log in
          </Button>
        </Text>
      )}
      {!isInit || authentic.isLoadingPolicies
        ? (<Box centered column>
            <Spinner size={4} />
            <Text mt={1.5} c='muted' fontSize='sm'>
              {authentic.isLoadingPolicies ? 'Loading your policies...' : 'Loading...'}
            </Text>
          </Box>)
        : filteredPolicies.length === 0
          ? (<Box centered column maxW={MAX_ELEMENT_WIDTH_PX + 'px'}>
              <Icon name='RiShieldCheckLine' size={4} c='subtle' />
              <Text variant='heading' mt={2} textAlign='center'>
                No policies yet.
              </Text>
              <Text mt={1.5} textAlign='center'>
                Looks like you don't have any policies with {theme.brand.name} yet, would you like to apply for one?
              </Text>
              <Button
                variant='secondary'
                mt={4}
                onClick={() => { navigate(ROUTE.APPLY); }}
              >
                Start an application
                <Icon name='RiArrowRightLine' ml={1} />
              </Button>
            </Box>)
          : (<Box column w='100%'>
              <Table
                header={{
                  title: 'Your coverages',
                  rightElement: (
                    <Box>
                      <Button variant='function' mr={2} onClick={() => { setIsClaimsContactModalOpen(true); }}>
                        <Icon name='RiFileShield2Line' mr={1} />
                        File a claim
                      </Button>
                      <Button variant='function' onClick={() => { navigate(ROUTE.APPLY); }}>
                        <Icon name='RiAddLine' mr={0.5} />
                        Add policy
                      </Button>
                    </Box>
                  )
                }}
                columns={[
                  { title: 'Policy ID' },
                  { title: 'Status', align: 'center' },
                  { title: 'Effective Date', align: 'center' },
                  { title: 'Yearly Premium', align: 'right' },
                  { title: 'Auto-renew', align: 'center' },
                  { title: 'Actions', align: 'right' }
                ]}
                rows={filteredPolicies.map((policy) => ({
                  cells: [
                    `${policy.carrierCode}-${policy.id}-${policy.renewalNumber}`,
                    policy.status === Authentic.POLICY_STATUS.PENDING_PAYMENT &&
                    parseInt(policy.policyCreationTimestamp) < (Date.now() - (MS_PER_SECOND * SECS_PER_MINUTE * MINS_PER_HOUR * HOURS_PER_DAY))
                      ? <Button variant='link' onClick={() => { window.location.assign(`${window.location.origin}/apply?application-id=${policy.applicationId}`); }}>
                          Pay Now
                        </Button>
                      : <PolicyStatusTag status={policy.status} isProcessing={policyIsProcessing(policy)} />,
                    getFormattedUtcDate(new Date(parseInt(policy.policyStartTimestamp))),
                    formatCurrency(policy.yearlyPremium),
                    <Icon name={policy.isRenewalCancelled ? 'RiCloseLine' : 'RiCheckLine'} c={policy.isRenewalCancelled ? 'error' : 'success'} />,
                    policy.status !== Authentic.POLICY_STATUS.PENDING_CANCEL && policy.status !== Authentic.POLICY_STATUS.CANCELLED
                      ? (<Button variant='link' c='error' onClick={() => {
                          if (authentic.auth.isAuthenticated) setIsCancellationModalOpen(true);
                          else authentic.promptLogin();
                        }}>
                          Cancel policy
                        </Button>)
                      : <></>
                  ],
                  isActive: currentPolicy?.id === policy.id,
                  onClick: filteredPolicies.length > 1 ? () => setCurrentPolicy(policy) : undefined
                }))}
              />
              {currentPolicy !== null && (
                <>
                  <Table
                    mt={3}
                    bg={undefined}
                    header={{ title: 'Limits' }}
                    columns={[
                      ...(currentPolicy.limits[0]?.address !== null ? [{ title: 'Location' }] : []),
                      { title: 'Name' },
                      { title: 'Occurrence', align: 'right' },
                      { title: 'Aggregate', align: 'right' }
                    ]}
                    rows={currentPolicy.limits
                      .filter((limit) => limit.address === null)
                      .map((limit) => ({
                        cells: [
                          limit.title,
                          parseInt(limit.occurrence) > 0 ? formatCurrency(limit.occurrence, true) : 'N/A',
                          parseInt(limit.aggregate) > 0 ? formatCurrency(limit.aggregate, true) : 'N/A'
                        ]
                      }))}
                  />
                  {currentPolicy.limits.some((limit) => limit.address !== null) && (
                    <Table
                      mt={3}
                      bg={undefined}
                      header={{ title: 'Property Limits' }}
                      columns={[
                        { title: 'Location' },
                        { title: 'Name' },
                        { title: 'Amount', align: 'right' }
                      ]}
                      rows={currentPolicy.limits
                        .filter((limit) => limit.address !== null)
                        .map((limit) => ({
                          cells: [
                            limit.address,
                            limit.title,
                            parseInt(limit.occurrence) > 0 ? formatCurrency(limit.occurrence, true) : 'N/A'
                          ]
                        }))}
                    />
                  )}
                  <Table
                    bg={undefined}
                    header={{ title: 'Insureds' }}
                    columns={[
                      { title: 'Name' },
                      { title: 'Email' },
                      { title: 'Primary', align: 'center' }
                    ]}
                    rows={currentPolicy.insureds.map((insured) => ({
                      cells: [
                        insured.name,
                        insured.email,
                        insured.isPrimary ? <Icon name='RiCheckLine' c='success' /> : null
                      ]
                    }))}
                  />
                  <Table
                    bg={undefined}
                    header={{ title: 'Documents' }}
                    columns={[
                      { title: 'Document' },
                      { title: 'Issued On', align: 'center' },
                      { title: 'Effective Date', align: 'center' },
                      { title: 'Download', align: 'right' }
                    ]}
                    rows={policyIsProcessing(currentPolicy)
                      ? [{ cells: ["Your policy documents are still being generated. You'll receive an email with them once they're ready."] }]
                      : currentPolicy.documents.map((document) => ({
                        cells: [
                          document.displayName,
                          getFormattedUtcDate(new Date(parseInt(document.createdAtTimestamp))),
                          getFormattedUtcDate(new Date(parseInt(document.effectiveDateTimestamp))),
                          <Link href={document.url} openInNewTab>{document.fileName}</Link>
                        ]
                      }))}
                  />
                </>
              )}
            </Box>)}
      <Modal
        centered
        isOpen={isClaimsContactModalOpen}
        onClose={() => { setIsClaimsContactModalOpen(false); }}
        footer={
          <>
            <Link buttonVariant='primary' w='100%' href={`tel:${claimsPhoneNumber}`}>
              <Icon name='RiPhoneLine' mr={0.5} />
              {formatPhoneNumber(claimsPhoneNumber)}
            </Link>
            <Link mt={1.5} mb={2} href={`mailto:${tenantClaimsEmail}`}>
              <Icon name='RiMailLine' mr={1} />
              {tenantClaimsEmail}
            </Link>
          </>
        }
      >
        <Icon name='RiFileShield2Line' size={4} c='subtle' />
        <Text variant='heading' mt={2} textAlign='center'>
          File a claim
        </Text>
        <Text mt={1} c='muted' fontSize='sm'>
          Mon—Fri, 8—5 (EST)
        </Text>
        <Text mt={1.5} textAlign='center'>
          To manage your claims, please contact our claims administrator <b>Reserv</b> below.
        </Text>
        <Accordion
          mt={2}
          items={claimsFaqs.map((faq) => ({
            title: faq.question,
            content: <Markdown>{faq.answer}</Markdown>
          }))}
        />
      </Modal>
      <CancellationModal
        currentPolicy={currentPolicy}
        isOpen={isCancellationModalOpen}
        onClose={() => { setIsCancellationModalOpen(false); }}
      />
    </View>
  );
}

function PolicyStatusTag({ isProcessing, status }: PolicyStatusTagProps): ReactElement {
  const statusThemeColor = status === Authentic.POLICY_STATUS.ACCEPTED ||
  status === Authentic.POLICY_STATUS.ACTIVE ||
  status === Authentic.POLICY_STATUS.RENEWED
    ? 'success'
    : status === Authentic.POLICY_STATUS.PENDING_PAYMENT || status === Authentic.POLICY_STATUS.UPCOMING
      ? 'warning'
      : 'error';

  function getStatusDisplayLabel(status: Authentic.POLICY_STATUS): string {
    switch (status) {
      case Authentic.POLICY_STATUS.ACCEPTED:
        return 'Accepted';
      case Authentic.POLICY_STATUS.PENDING_PAYMENT:
        return 'Pending payment';
      case Authentic.POLICY_STATUS.UPCOMING:
        return 'Upcoming';
      case Authentic.POLICY_STATUS.ACTIVE:
        return 'Active';
      case Authentic.POLICY_STATUS.RENEWED:
        return 'Renewed';
      case Authentic.POLICY_STATUS.EXPIRED:
        return 'Expired';
      case Authentic.POLICY_STATUS.PENDING_CANCEL:
        return 'Pending cancellation';
      default:
        return 'Cancelled';
    }
  }

  return (
    <Tag minW='125px' c={isProcessing ? 'muted' : statusThemeColor}>
      {isProcessing
        ? (<>
            Processing
            <Spinner ml={1} c='inherit' />
          </>)
        : getStatusDisplayLabel(status)}
    </Tag>
  );
}

function CancellationModal({
  currentPolicy,
  isOpen,
  onClose
}: {
  currentPolicy: Authentic.Policy | null;
  isOpen: boolean;
  onClose: () => void;
}): ReactElement {
  const authentic = useAuthentic();
  const [enteredPolicyId, setEnteredPolicyId] = useState<string | null>(null);
  const [isCancellationDisabled, setIsCancellationDisabled] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);

  // Reset modal state when it's closed.
  useEffect(() => {
    if (isOpen) return;
    setEnteredPolicyId(null);
    setIsCancellationDisabled(true);
  }, [isOpen]);

  // Validate the entered policy ID.
  useEffect(() => {
    if (currentPolicy === null) return;
    // TODO: add a getPolicyID() util to ts-engine.
    setIsCancellationDisabled(enteredPolicyId !==
      `${currentPolicy.carrierCode}-${currentPolicy.id}-${currentPolicy.renewalNumber}`);
  }, [currentPolicy, enteredPolicyId]);

  async function _handleCancellation(): Promise<void> {
    if (!authentic.isInit || currentPolicy === null) return;
    setIsCancelling(true);
    const policies = await authentic.cancelPolicy(currentPolicy);
    if (!Authentic.valueIsApiError(policies)) window.location.reload();
    else if (policies.code === Authentic.API_ERROR_CODE.AUTHENTICATION_REQUIRED) authentic.promptLogin();
    else authentic.setShowErrorModal(true);
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <Input
            mb={2}
            type='text'
            placeholder='Enter your policy ID to continue'
            value={enteredPolicyId ?? undefined}
            onChange={(v) => {
              setEnteredPolicyId(v?.toUpperCase() ?? '');
            }}
          />
          <Button
            variant='primary'
            isLoading={isCancelling}
            isDisabled={isCancellationDisabled}
            onClick={() => { void _handleCancellation(); }}
          >
            Yes, cancel policy
          </Button>
        </>
      }
    >
      <Icon name='RiCalendarCloseLine' size={4} c='error'/>
      <Text variant='heading' mt={2} textAlign='center'>
        Cancel your policy
      </Text>
      <Text mt={1.5} textAlign='center'>
        Are you sure you want to cancel your policy? This action cannot be undone.
      </Text>
    </Modal>
  );
}
