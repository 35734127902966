import { type ReactNode, createContext, useContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import * as Authentic from '@authenticins/ts-client';
import {
  MS_PER_SECOND,
  getUrlParam,
  setUrlParam,
  removeUrlParam
} from '@authenticins/react-ui';

import { ROUTE } from '../main';
import { useAnalytics } from '.';

type AuthenticClient = Authentic.Client & {
  isInit: boolean;
  tenantName: string;
  availableBusinessClasses: Authentic.ApplicationBusinessClass[];
  businessClassSearchQuery: string | null;
  setBusinessClassSearchQuery: (searchQuery: string | null) => void;
  isLoadingAvailableBusinessClasses: boolean;
  availableProducts: Authentic.Product[];
  isLoadingAvailableProducts: boolean;
  tenantCustomerId: string | null;
  prefillData: Authentic.PrefillData | null;
  prefilledApplicationSections: Pick<Authentic.Application, 'questions' | 'exposures'> | null;
  applicationMeta: Authentic.ApplicationMeta;
  setApplicationMeta: (applicationMeta: Authentic.ApplicationMeta) => void;
  application: Authentic.Application | null;
  isLoadingApplication: boolean;
  isSubmittingApplicationMeta: boolean;
  isSubmittingApplication: boolean;
  isGeneratingApplicationQuotesPaymentData: boolean;
  currentApplicationStatus: Authentic.APPLICATION_STATUS | null;
  setCurrentApplicationStatus: (status: Authentic.APPLICATION_STATUS | null) => void;
  currentApplicationStep: Authentic.APPLICATION_STEP;
  setCurrentApplicationStep: (step: Authentic.APPLICATION_STEP) => void;
  currentApplicationPageIndex: number;
  setCurrentApplicationPageIndex: (index: number) => void;
  updateApplicationFieldResponses: (fieldResponses: Authentic.ApplicationFieldResponse[]) => Promise<boolean>;
  updateApplicationExposureResponse: (exposureResponse: Authentic.ApplicationExposureResponse) => Promise<boolean>;
  submitApplicationMeta: (applicationMeta: Authentic.ApplicationMeta, prefillData?: Authentic.PrefillData) => Promise<boolean>;
  submitApplication: () => Promise<boolean>;
  resubmitApplication: (changes: Partial<Authentic.ApplicationMeta> | Authentic.ApplicationFieldResponse[] | Authentic.ApplicationExposureResponse[]) => Promise<boolean>;
  getApplicationQuotesPaymentData: (isPayingInFull?: boolean) => Promise<Authentic.ApplicationQuotesPaymentData | null>;
  policies: Authentic.Policy[];
  setPolicies: (policies: Authentic.Policy[]) => void;
  isLoadingPolicies: boolean;
  showLoginModal: boolean;
  promptLogin: () => void;
  showNotAllowedModal: boolean;
  showMoreInformationNeededModal: boolean;
  dismissMoreInformationNeededModal: () => void;
  showErrorModal: boolean;
  setShowErrorModal: (showErrorModal: boolean) => void;
};

const CHECK_QUOTE_PACKET_GENERATION_INTERVAL_MS = MS_PER_SECOND * 3;

const AuthenticClientContext = createContext<AuthenticClient>({} as unknown as AuthenticClient);

/**
 * Provides context for the {@link AuthenticClient | Authentic client} to its children.
 */
export function AuthenticClientProvider({
  apiUrl,
  authConfig,
  tenantName,
  children
}: {
  apiUrl: string;
  authConfig: Authentic.AuthConfig;
  tenantName: string;
  children: ReactNode;
}): ReactNode {
  const analytics = useAnalytics();
  const [client, setClient] = useState<Authentic.Client | null>(null);
  const [auth, setAuth] = useState<Authentic.Auth>({ isAuthenticated: false } as unknown as Authentic.Auth);
  const [availableBusinessClasses, setAvailableBusinessClasses] = useState<AuthenticClient['availableBusinessClasses']>([]);
  const [businessClassSearchQuery, setBusinessClassSearchQuery] = useState<string | null>(null);
  const [isLoadingAvailableBusinessClasses, setIsLoadingAvailableBusinessClasses] = useState<boolean>(false);
  const [availableProducts, setAvailableProducts] = useState<AuthenticClient['availableProducts']>([]);
  const [isLoadingAvailableProducts, setIsLoadingAvailableProducts] = useState<boolean>(false);
  const [tenantCustomerId, setTenantCustomerId] = useState<string | null>(null);
  const [prefillData, setPrefillData] = useState<Authentic.PrefillData | null>(null);
  const [prefilledApplicationSections, setPrefilledApplicationSections] = useState<AuthenticClient['prefilledApplicationSections']>(null);
  const [applicationMeta, setApplicationMeta] = useState<Authentic.ApplicationMeta>({
    email: '',
    businessClassCode: '',
    stateCodes: [],
    productIds: []
  });
  const [application, setApplication] = useState<AuthenticClient['application']>(null);
  const [isLoadingApplication, setIsLoadingApplication] = useState<boolean>(false);
  const [isSubmittingApplicationMeta, setIsSubmittingApplicationMeta] = useState<boolean>(false);
  const [isSubmittingApplication, setIsSubmittingApplication] = useState<boolean>(false);
  const [isGeneratingApplicationQuotesPaymentData, setIsGeneratingApplicationQuotesPaymentData] = useState<boolean>(false);
  const [currentApplicationStatus, setCurrentApplicationStatus] = useState<AuthenticClient['currentApplicationStatus']>(null);
  const [currentApplicationStep, setCurrentApplicationStep] = useState<Authentic.APPLICATION_STEP>(Authentic.APPLICATION_STEP.META);
  const [currentApplicationPageIndex, setCurrentApplicationPageIndex] = useState<number>(0);
  const [policies, setPolicies] = useState<AuthenticClient['policies']>([]);
  const [isLoadingPolicies, setIsLoadingPolicies] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showNotAllowedModal, setShowNotAllowedModal] = useState<boolean>(false);
  const [showMoreInformationNeededModal, setShowMoreInformationNeededModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  // Create and initialize on mount.
  useEffect(() => {
    if (!analytics.isInit) return;
    async function init(): Promise<void> {
      if (typeof apiUrl === 'undefined' || typeof authConfig === 'undefined') return;
      const client = await Authentic.createClient(
        apiUrl,
        authConfig,
        setAuth
      );
      setClient(client);
      setAuth(client.auth);
      const urlParamsApplicationId = getUrlParam(window.location.href, 'application-id');
      const urlParamsTenantCustomerId = getUrlParam(window.location.href, 'customer-id') as string | null;
      if (urlParamsTenantCustomerId !== null) setTenantCustomerId(urlParamsTenantCustomerId);
      // Accomodate legacy pre-fill URL param name "data".
      const urlParamsPrefillData = getUrlParam(window.location.href, 'prefill-data') ?? getUrlParam(window.location.href, 'data');
      if (urlParamsApplicationId !== null) {
        setIsLoadingApplication(true);
        let applicationResp = await client.getApplication(urlParamsApplicationId as string);
        if (Authentic.valueIsApiError(applicationResp)) {
          console.error('Error: Unable to load application.', urlParamsApplicationId, applicationResp);
          _reactToApiError(applicationResp, client.auth);
        } else {
          // If the application has quotes that were purchased, redirect to the policy dashboard.
          if (applicationResp.status === Authentic.APPLICATION_STATUS.PURCHASED) {
            window.location.assign(ROUTE.POLICY);
            return;
          }
          setApplicationMeta(applicationResp.meta);
          // If a loaded application's start date is in the past, set it to tomorrow.
          const policyStartDateFieldResponse = applicationResp.answers.questions['POLICY_START_DATE'] ?? null;
          const policyStartDate = policyStartDateFieldResponse !== null ? new Date(policyStartDateFieldResponse) : null;
          const tomorrowDate = new Date();
          tomorrowDate.setDate(tomorrowDate.getDate() + 1);
          tomorrowDate.setHours(0, 0, 0, 0);
          if (policyStartDate === null ||
            tomorrowDate.getUTCFullYear() > policyStartDate.getUTCFullYear() ||
            tomorrowDate.getUTCMonth() > policyStartDate.getUTCMonth() ||
            tomorrowDate.getUTCDate() > policyStartDate.getUTCDate()) {
            const updatedApplicationResp = await client.updateApplicationFieldResponses(applicationResp, [{
              fieldName: 'POLICY_START_DATE',
              fieldValue: tomorrowDate.toISOString()
            }]);
            if (Authentic.valueIsApiError(updatedApplicationResp)) {
              console.error('Error: Unable to update outdated policy start date on loaded application.', updatedApplicationResp);
              _reactToApiError(updatedApplicationResp, client.auth);
            } else if (applicationPagesAreCompleted(updatedApplicationResp) && updatedApplicationResp.quotes.length > 0) {
              const quotedApplicationResp = await client.getApplicationQuotes(updatedApplicationResp, true);
              if (Authentic.valueIsApiError(quotedApplicationResp)) {
                console.error('Error: Unable to generate new quotes on loaded application.', quotedApplicationResp);
                _reactToApiError(quotedApplicationResp, client.auth);
              } else applicationResp = quotedApplicationResp;
            }
          }
          // TODO: remove this once the API always returns latest quotes.
          const wasApplicationDeclined = applicationResp.status === Authentic.APPLICATION_STATUS.APPROVED && applicationResp.quotes.length === 0;
          const applicationStatus = Authentic.APPLICATION_STATUS[wasApplicationDeclined
            ? 'DECLINED'
            : applicationResp.quotes.length > 0
              ? Authentic.applicationWasDeclined(applicationResp)
                ? 'DECLINED'
                : 'APPROVED'
              : 'META_ACCEPTED'];
          setCurrentApplicationStatus(applicationStatus);
          setCurrentApplicationStep(Authentic.APPLICATION_STEP[applicationStatus === Authentic.APPLICATION_STATUS.APPROVED ? 'QUOTE' : 'QUESTIONS']);
          setCurrentApplicationPageIndex(getCurrentApplicationPageIndex(applicationResp));
          setApplication(applicationResp);
        }
      } else if (urlParamsTenantCustomerId !== null || urlParamsPrefillData !== null) {
        let prefillData: Authentic.PrefillData = {};
        if (urlParamsTenantCustomerId !== null &&
          (getUrlParam(window.location.href, 'funnel_flow') === 'prefill' ||
          analytics.getConfig('tenant_funnel_flow')?.['funnelFlow'] === 'prefill')) {
          const prefillDataResp = await client.getPrefillData(urlParamsTenantCustomerId);
          if (Authentic.valueIsApiError(prefillDataResp)) {
            console.error('Error: Unable to load pre-fill data for tenant customer ID.', urlParamsTenantCustomerId, prefillDataResp);
            _reactToApiError(prefillDataResp, client.auth);
          } else {
            prefillData = prefillDataResp.prefillData;
            setPrefilledApplicationSections(prefillDataResp.applicationSections);
          }
        }
        if (urlParamsPrefillData !== null && typeof urlParamsPrefillData === 'object') {
          if ('meta' in urlParamsPrefillData) {
            prefillData.meta = {
              ...prefillData.meta,
              ...urlParamsPrefillData.meta
            };
          }
          if ('answers' in urlParamsPrefillData) {
            prefillData.answers = {
              ...prefillData.answers,
              ...(!('questions' in urlParamsPrefillData.answers)
                // Accomodate legacy pre-fill "questions" format.
                ? { questions: urlParamsPrefillData.answers }
                : urlParamsPrefillData.answers)
            };
          }
          // Accomodate legacy pre-fill "exposures" format.
          if ('exposures' in urlParamsPrefillData) {
            prefillData.answers = {
              ...prefillData.answers,
              exposures: urlParamsPrefillData.exposures.map((prefilledExposureResponse: any) => {
                if ('name' in prefilledExposureResponse) {
                  prefilledExposureResponse = {
                    ...prefilledExposureResponse,
                    exposureName: prefilledExposureResponse.name
                  };
                  delete prefilledExposureResponse.name;
                }
                return prefilledExposureResponse;
              })
            };
          }
          // Ensure pre-filled business location exposures are unique by address.
          if ('answers' in prefillData && 'exposures' in prefillData.answers) {
            const uniquePrefilledExposureResponses: Authentic.ApplicationExposureResponse[] = [];
            for (const prefilledExposureResponse of prefillData.answers.exposures) {
              if (prefilledExposureResponse.exposureName !== 'business_location' ||
                !uniquePrefilledExposureResponses.some((resp) => resp.fieldValues['ADDRESS'] === prefilledExposureResponse.fieldValues['ADDRESS'])) {
                for (const fieldName in prefilledExposureResponse.fieldValues) {
                  if (prefilledExposureResponse.fieldValues[fieldName] === null) delete prefilledExposureResponse.fieldValues[fieldName];
                }
                uniquePrefilledExposureResponses.push(prefilledExposureResponse);
              }
            }
            // @ts-expect-error - We remove any `null` field values above.
            prefillData.answers.exposures = uniquePrefilledExposureResponses;
          }
        }
        if (typeof prefillData.meta !== 'undefined') {
          setApplicationMeta({
            email: prefillData.meta.email ?? '',
            businessClassCode: prefillData.meta.businessClassCode ?? '',
            stateCodes: prefillData.meta.stateCodes ?? [],
            productIds: prefillData.meta.productIds ?? []
          });
        }
        setPrefillData(prefillData);
      }
      setTimeout(() => {
        setIsInit(true);
        setIsLoadingApplication(false);
        setIsSubmittingApplication(false);
      }, MS_PER_SECOND);
    }
    if (!isInit) void init();
  }, [apiUrl, authConfig, analytics.isInit]);

  // Once analytics and the client are initialized, identify the user in analytics.
  // If an application was loaded or prefill-data was set during initialization, also track those event(s).
  useEffect(() => {
    if (!isInit || !analytics.isInit || auth.awsCognitoIdentityId === null) return;
    void analytics.identifyUser(
      auth.awsCognitoIdentityId,
      application?.meta.email,
      {
        tenantCustomerId: tenantCustomerId ?? undefined,
        applicantName: application?.answers.questions['NAME']
      }
    ).then(() => {
      if (prefillData !== null) analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_PREFILLED, { prefillData });
      if (application !== null) analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_LOADED, { applicationId: application.id });
    }).catch((err) => { console.error('Error: Unable to identify user in analytics during client initialization.', err); });
  }, [isInit, analytics.isInit]);

  // Once we have a client and on business class search query change, load available business classes.
  useEffect(() => {
    async function getAvailableBusinessClasses(): Promise<void> {
      if (client === null) return;
      setIsLoadingAvailableBusinessClasses(true);
      const businessClassesResp = await client.getAvailableBusinessClasses(businessClassSearchQuery ?? undefined);
      if (Authentic.valueIsApiError(businessClassesResp)) {
        console.error('Error: Unable to load business classes.', businessClassesResp);
        _reactToApiError(businessClassesResp, auth);
      } else {
        setAvailableBusinessClasses(businessClassesResp);
        // If pre-filling with a business class code, ensure the dependent question and exposure fields are also pre-filled.
        if (typeof prefillData?.meta?.businessClassCode !== 'undefined') {
          const prefilledBusinessClassCode = prefillData.meta.businessClassCode;
          const businessClass = businessClassesResp.find((businessClass) => businessClass.code === prefilledBusinessClassCode);
          if (typeof businessClass !== 'undefined') {
            prefillData.answers = {
              ...prefillData.answers,
              questions: {
                ...prefillData.answers?.questions,
                CLASS_DESCRIPTION: businessClass.description,
                MARKET_GROUP_LONG: businessClass.marketGroupDescription
              }
            };
            if (typeof prefillData.answers.exposures !== 'undefined') {
              for (const prefilledExposureResponse of prefillData.answers.exposures) {
                prefilledExposureResponse.fieldValues['EXPOSURE_CLASS_DESCRIPTION'] = businessClass.description;
              }
            }
            setPrefillData(prefillData);
          }
        }
      }
      setIsLoadingAvailableBusinessClasses(false);
    }
    void getAvailableBusinessClasses();
  }, [client, businessClassSearchQuery]);

  // Once the we have a client, load user policies.
  useEffect(() => {
    if (client === null) return;
    async function getPolicies(): Promise<void> {
      if (client === null) return;
      setIsLoadingPolicies(true);
      const policiesResp = await client.getPolicies();
      if (Authentic.valueIsApiError(policiesResp)) {
        console.error('Error: Unable to load user policies.', policiesResp);
        _reactToApiError(policiesResp, auth);
      } else setPolicies(policiesResp);
      setIsLoadingPolicies(false);
    }
    void getPolicies();
  }, [client]);

  // Once the business class code and state codes are set in the application meta, load available products.
  useEffect(() => {
    if (applicationMeta.businessClassCode.length === 0 || applicationMeta.stateCodes.length === 0) return;
    async function getAvailableProducts(): Promise<void> {
      if (client === null) return;
      setIsLoadingAvailableProducts(true);
      const productsResp = await client.getAvailableProducts(undefined, applicationMeta.businessClassCode, applicationMeta.stateCodes);
      if (Authentic.valueIsApiError(productsResp)) {
        console.error('Error: Unable to load products.', productsResp);
        _reactToApiError(productsResp, auth);
      } else setAvailableProducts(productsResp);
      setIsLoadingAvailableProducts(false);
    }
    void getAvailableProducts();
  }, [applicationMeta.businessClassCode, applicationMeta.stateCodes]);

  // When the application ID changes, add it to URL params and remove any pre-fill data params.
  useEffect(() => {
    if (!isInit || typeof application?.id === 'undefined') return;
    let updatedUrl = removeUrlParam(window.location.href, 'prefill-data');
    // Accomodate legacy pre-fill URL param name "data".
    updatedUrl = removeUrlParam(updatedUrl, 'data');
    updatedUrl = setUrlParam(updatedUrl, 'application-id', application.id);
    window.history.replaceState({}, '', updatedUrl);
  }, [isInit, application?.id]);

  /**
   * Checks if the given {@link Authentic.Application | application's} pages are completed.
   */
  function applicationPagesAreCompleted(application: Authentic.Application): boolean {
    return application.firstIncompleteQuestionSectionIndex === -1 && application.firstIncompleteExposureIndex === -1;
  }

  /**
   * Returns the index of the current page from the given {@link Authentic.Application | application}.
   */
  function getCurrentApplicationPageIndex(application: Authentic.Application): number {
    if (application.firstIncompleteExposureIndex !== -1) return application.firstIncompleteExposureIndex;
    const numberOfQuestionSectionPages = Authentic.filterApplicationSectionsByConditionals(application.questions, application.answers.questions, true).length;
    return (application.firstIncompleteQuestionSectionIndex !== -1
      ? application.firstIncompleteQuestionSectionIndex
      : numberOfQuestionSectionPages - 1) + application.exposures.length;
  }

  /**
   * Submits the {@link Authentic.ApplicationMeta | application meta} for approval and to create an {@link Authentic.Application | application}.
   * @returns `true` if error occurred or application meta rejected
   */
  async function submitApplicationMeta(applicationMeta: Authentic.ApplicationMeta, prefillData?: Authentic.PrefillData): Promise<boolean> {
    if (!isInit || client === null) return true;
    setIsSubmittingApplicationMeta(true);
    const applicationResp = await client.createApplication(applicationMeta, tenantCustomerId ?? undefined, prefillData);
    const wasError = Authentic.valueIsApiError(applicationResp);
    const wasRejected = !wasError && applicationResp.status === Authentic.APPLICATION_STATUS.META_REJECTED;
    // Ensure all data from the lead form pages can be tracked in analytics.
    const lead = {
      ...applicationMeta,
      businessName: prefillData?.answers?.questions?.['BUSINESS_LEGAL_NAME'],
      businessClassDescription: prefillData?.answers?.questions?.['CLASS_DESCRIPTION'],
      applicantName: prefillData?.answers?.questions?.['NAME']
    };
    if (wasError) _reactToApiError(applicationResp, auth);
    else if (wasRejected) {
      setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_REJECTED);
      if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_META_REJECTED, { tenantCustomerId, lead });
    } else {
      setApplication(applicationResp);
      setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_ACCEPTED);
      setCurrentApplicationStep(Authentic.APPLICATION_STEP.QUESTIONS);
      if (analytics.isInit) {
        analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_META_ACCEPTED, {
          tenantCustomerId,
          lead,
          applicationId: applicationResp.id
        });
      }
    }
    setIsSubmittingApplicationMeta(false);
    return wasRejected;
  }

  /**
   * Updates the given {@link ApplicationFieldResponse | field responses} on the application.
   * @returns `true` if error occurred.
   */
  async function updateApplicationFieldResponses(fieldResponses: Authentic.ApplicationFieldResponse[]): Promise<boolean> {
    if (!isInit || client === null || application === null) return true;
    const updatedApplicationResp = await client.updateApplicationFieldResponses(application, fieldResponses);
    const wasError = Authentic.valueIsApiError(updatedApplicationResp);
    if (wasError) {
      console.error('Error: Unable to update application field responses.', updatedApplicationResp);
      _reactToApiError(updatedApplicationResp, auth);
    } else {
      setApplication(updatedApplicationResp);
      if (analytics.isInit) {
        analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_QUESTION_RESPONSES_SUBMITTED, {
          applicationId: application.id,
          questionResponses: fieldResponses.reduce((acc, fieldResponse) => ({
            ...acc,
            [fieldResponse.fieldName]: fieldResponse.fieldValue
          }), {})
        });
      }
    }
    return wasError;
  }

  /**
   * Updates the given {@link Authentic.ApplicationExposureResponse | exposure response} on the application.
   * @returns `true` if error occurred or duplicate location exposure.
   */
  async function updateApplicationExposureResponse(exposureResponse: Authentic.ApplicationExposureResponse): Promise<boolean> {
    if (!isInit || client === null || application === null) return true;
    // Prevent duplicate business location exposures from.
    const isNewExposureResponse = !application.answers.exposures.some((exposureResp) => exposureResp.id === exposureResponse.id);
    if (isNewExposureResponse &&
      exposureResponse.exposureName === 'business_location' &&
      application.answers.exposures.some((exposureResp) => exposureResp.fieldValues['ADDRESS'] === exposureResponse.fieldValues['ADDRESS'])) {
      alert(`You've already added your ${exposureResponse.fieldValues['ADDRESS']} location.`);
      return true;
    }
    // Ensure the dependent class description field is on every exposure.
    if (typeof exposureResponse.fieldValues['EXPOSURE_CLASS_DESCRIPTION'] === 'undefined') {
      const businessClassDescription = application.answers.questions['CLASS_DESCRIPTION'];
      if (typeof businessClassDescription === 'undefined') {
        console.error('Error: "CLASS_DESCRIPTION" field is missing on the application.', application);
        return true;
      }
      exposureResponse.fieldValues['EXPOSURE_CLASS_DESCRIPTION'] = businessClassDescription;
    }
    const updatedApplicationResp = await client.updateApplicationExposureResponse(application, exposureResponse);
    const wasError = Authentic.valueIsApiError(updatedApplicationResp);
    if (wasError) {
      console.error('Error: Unable to update application exposure response.', updatedApplicationResp);
      _reactToApiError(updatedApplicationResp, auth);
    } else {
      setApplication(updatedApplicationResp);
      if (analytics.isInit) {
        analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_EXPOSURE_RESPONSE_SUBMITTED, {
          applicationId: application.id,
          exposureResponse
        });
      }
    }
    return wasError;
  }

  /**
   * Submits the application for approval to generate {@link Authentic.ApplicationQuote | quotes}.
   * @returns `true` if error occurred or application declined.
   */
  async function submitApplication(): Promise<boolean> {
    if (!isInit || client === null || application === null) return true;
    setIsSubmittingApplication(true);
    let wasError = false;
    let wasDeclined = false;
    let quotedApplicationResp = await client.getApplicationQuotes(application, true);
    if (Authentic.valueIsApiError(quotedApplicationResp)) {
      wasError = true;
      console.error('Error: Unable to submit application and generate quotes.', quotedApplicationResp);
      _reactToApiError(quotedApplicationResp, auth);
    } else {
      wasDeclined = Authentic.applicationWasDeclined(quotedApplicationResp);
      setApplication(quotedApplicationResp);
      setCurrentApplicationStatus(Authentic.APPLICATION_STATUS[wasDeclined ? 'DECLINED' : 'APPROVED']);
      setCurrentApplicationStep(Authentic.APPLICATION_STEP[wasDeclined ? 'META' : 'QUOTE']);
      if (!wasDeclined) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        const checkQuotePacketUrlsInterval = setInterval(async () => {
          if (Authentic.valueIsApiError(quotedApplicationResp)) {
            console.error('Error: Invalid application to get application quotes (checking for quote packet generation).', quotedApplicationResp);
            clearInterval(checkQuotePacketUrlsInterval);
            return;
          }
          quotedApplicationResp = await client.getApplicationQuotes(quotedApplicationResp);
          if (Authentic.valueIsApiError(quotedApplicationResp)) {
            console.error('Error: Unable to get application quotes (checking for quote packet generation).', quotedApplicationResp);
            _reactToApiError(quotedApplicationResp, auth);
          } else if (quotedApplicationResp.quotes
            .filter((quote) => quote.status !== Authentic.APPLICATION_QUOTE_STATUS.DECLINED)
            .every((quote) => quote.packetFileUrl !== null)) {
            setApplication({ ...application, quotes: quotedApplicationResp.quotes });
            clearInterval(checkQuotePacketUrlsInterval);
          }
        }, CHECK_QUOTE_PACKET_GENERATION_INTERVAL_MS);
      }
      if (analytics.isInit) {
        analytics.trackEvent(ANALYTICS_EVENT[wasDeclined ? 'APPLICATION_DECLINED' : 'APPLICATION_APPROVED'], {
          applicationId: quotedApplicationResp.id,
          businessName: quotedApplicationResp.answers.questions['BUSINESS_LEGAL_NAME']
        });
        analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_QUOTES_GENERATED, {
          applicationId: quotedApplicationResp.id,
          businessName: quotedApplicationResp.answers.questions['BUSINESS_LEGAL_NAME'],
          quotes: quotedApplicationResp.quotes
        });
      }
    }
    setIsSubmittingApplication(false);
    return wasError || wasDeclined;
  }

  /**
   * Resubmits the application with the given changes for approval to generate {@link Authentic.ApplicationQuote | quotes}.
   * @returns `true` if error occurred or application declined.
   */
  async function resubmitApplication(changes: Partial<Authentic.ApplicationMeta> | Authentic.ApplicationFieldResponse[] | Authentic.ApplicationExposureResponse[]): Promise<boolean> {
    if (!isInit || client === null || application === null) return true;
    setIsSubmittingApplication(true);
    let wasError = false;
    let wasDeclined = false;
    let changedApplicationResp: Authentic.Application | Authentic.ApiError = { code: Authentic.API_ERROR_CODE.UNKNOWN, message: 'Invalid changes provided.' };
    if (Authentic.valueIsApplicationMeta(changes)) changedApplicationResp = await client.updateApplicationMeta(application, { ...application.meta, ...changes });
    else if (Array.isArray(changes)) {
      if (changes.every(Authentic.valueIsApplicationFieldResponse)) changedApplicationResp = await client.updateApplicationFieldResponses(application, changes);
      else {
        for (const exposureResponseChange of changes) {
          changedApplicationResp = await client.updateApplicationExposureResponse(application, exposureResponseChange);
        }
      }
    }
    if (Authentic.valueIsApiError(changedApplicationResp)) {
      wasError = true;
      console.error('Error: Unable to resubmit application.', changedApplicationResp);
      _reactToApiError(changedApplicationResp, auth);
    } else {
      const doesChangedApplicationRequireMoreInformation = !applicationPagesAreCompleted(changedApplicationResp);
      if (doesChangedApplicationRequireMoreInformation) {
        setShowMoreInformationNeededModal(true);
        setApplication(changedApplicationResp);
        setCurrentApplicationStep(Authentic.APPLICATION_STEP.QUESTIONS);
        setCurrentApplicationPageIndex(getCurrentApplicationPageIndex(changedApplicationResp));
      } else {
        const quotedApplicationResp = await client.getApplicationQuotes(changedApplicationResp, true);
        if (Authentic.valueIsApiError(quotedApplicationResp)) {
          console.error('Error: Unable to resubmit application and generate new quotes.', quotedApplicationResp);
          _reactToApiError(quotedApplicationResp, auth);
        } else {
          setApplication(quotedApplicationResp);
          wasDeclined = Authentic.applicationWasDeclined(quotedApplicationResp);
          if (wasDeclined) setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.DECLINED);
          if (analytics.isInit) {
            analytics.trackEvent(ANALYTICS_EVENT[wasDeclined ? 'APPLICATION_DECLINED' : 'APPLICATION_APPROVED'], {
              applicationId: quotedApplicationResp.id,
              businessName: quotedApplicationResp.answers.questions['BUSINESS_LEGAL_NAME']
            });
            analytics.trackEvent(ANALYTICS_EVENT.APPLICATION_QUOTES_GENERATED, {
              applicationId: quotedApplicationResp.id,
              businessName: quotedApplicationResp.answers.questions['BUSINESS_LEGAL_NAME'],
              quotes: quotedApplicationResp.quotes
            });
          }
        }
      }
    }
    setIsSubmittingApplication(false);
    return wasError || wasDeclined;
  }

  /**
   * Returns {@link Authentic.ApplicationQuotesPaymentData | payment data} used to purchase the {@link Application | application's} {@link ApplicationQuote | quotes}.
   * @returns `null` if error occurred.
   */
  async function getApplicationQuotesPaymentData(isPayingInFull: boolean = true): Promise<Authentic.ApplicationQuotesPaymentData | null> {
    if (!isInit || client === null || application === null) return null;
    setIsGeneratingApplicationQuotesPaymentData(true);
    const quotesPaymentDataResp = await client.getApplicationQuotesPaymentData(application, isPayingInFull);
    if (Authentic.valueIsApiError(quotesPaymentDataResp)) {
      console.error('Error: Unable to get application quotes payment data.', quotesPaymentDataResp);
      _reactToApiError(quotesPaymentDataResp, auth);
    }
    setIsGeneratingApplicationQuotesPaymentData(false);
    return !Authentic.valueIsApiError(quotesPaymentDataResp) ? quotesPaymentDataResp : null;
  }

  /**
   * Reacts to the given {@linkcode Authentic.ApiError | ApiError}.
   */
  function _reactToApiError(apiError: Authentic.ApiError, auth: Authentic.Auth): void {
    // Capture the error in Sentry.
    Sentry.captureException(`FE client API error: ${apiError.code}`, {
      data: {
        apiError,
        email: auth.email,
        application
      }
    });
    if (apiError.code === Authentic.API_ERROR_CODE.AUTHENTICATION_REQUIRED) setShowLoginModal(true);
    else if (apiError.code === Authentic.API_ERROR_CODE.AUTHORIZATION_REQUIRED) {
      if (Authentic.openIdTokenIsExpired(auth.openIdToken ?? '')) window.location.reload();
      else setShowNotAllowedModal(true);
      // If there is a precondition required, the user will be redirected to satisfy it.
    } else if (apiError.code !== Authentic.API_ERROR_CODE.PRECONDITION_REQUIRED) setShowErrorModal(true);
  }

  return (
    <AuthenticClientContext.Provider
      value={{
        isInit,
        ...client,
        auth,
        tenantName,
        availableBusinessClasses,
        businessClassSearchQuery,
        setBusinessClassSearchQuery,
        isLoadingAvailableBusinessClasses,
        availableProducts,
        isLoadingAvailableProducts,
        prefillData,
        prefilledApplicationSections,
        applicationMeta,
        setApplicationMeta,
        application,
        isSubmittingApplicationMeta,
        isLoadingApplication,
        isSubmittingApplication,
        isGeneratingApplicationQuotesPaymentData,
        currentApplicationStatus,
        setCurrentApplicationStatus,
        currentApplicationStep,
        setCurrentApplicationStep,
        currentApplicationPageIndex,
        setCurrentApplicationPageIndex,
        // @ts-expect-error - This must be an ESLint bug.
        updateApplicationFieldResponses,
        // @ts-expect-error - ^
        updateApplicationExposureResponse,
        submitApplicationMeta,
        submitApplication,
        resubmitApplication,
        // @ts-expect-error - ^
        getApplicationQuotesPaymentData,
        policies,
        setPolicies,
        isLoadingPolicies,
        showLoginModal,
        promptLogin: () => { setShowLoginModal(true); },
        showNotAllowedModal,
        showMoreInformationNeededModal,
        dismissMoreInformationNeededModal: () => { setShowMoreInformationNeededModal(false); },
        showErrorModal,
        setShowErrorModal
      }}
    >
      {children}
    </AuthenticClientContext.Provider>
  );
}

/**
 * Returns access to the {@link AuthenticClientContext | Authentic client context}.
 */
export function useAuthentic(): AuthenticClient {
  const authentic = useContext(AuthenticClientContext);
  if (typeof authentic === 'undefined') {
    throw new Error('Error: Called "useAuthentic()" outside an AuthenticClientProvider.');
  }
  return authentic;
}
