import { type ReactElement, type ReactNode, useState, useEffect } from 'react';
import * as Authentic from '@authenticins/ts-client';
import {
  type StateCode,
  type FunnelFlow,
  type ThemeStyleProps,
  type IconName,
  MS_PER_SECOND,
  MAX_VIEW_CONTENT_WIDTH_PX,
  MAX_ELEMENT_WIDTH_PX,
  getRoundedNumber,
  getOffsetUtcDateFromTimestamp,
  getBrandNameWithInsurance,
  useTheme,
  View,
  Box,
  Text,
  Button,
  Input,
  EmailInput,
  PhoneInput,
  UrlInput,
  PercentInput,
  DateInput,
  AddressInput,
  RadioGroup,
  Select,
  Image,
  Tooltip,
  Icon,
  Spinner,
  Markdown
} from '@authenticins/react-ui';

import { PAGE } from '../../main';
import { useAnalytics, useAuthentic } from '../../providers';
import { LEAD_FORM_PAGE, LeadFormPages } from './LeadFormPages';
import { ExposurePages } from './ExposurePages';
import { QuestionSectionPages } from './QuestionSectionPages';
import { QuotesPage } from './QuotesPage';
import { DeclinePage } from './DeclinePage';

interface ApplicationViewProps extends ThemeStyleProps {
  supportPhoneNumber: number;
  funnelFlow: FunnelFlow;
}
export interface ApplicationPageProps extends ThemeStyleProps {
  isActive?: boolean;
  isCompleted?: boolean;
  customerIndustry: string | null;
  title?: string;
  continueButton?: {
    label?: ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
    onDisabledClick?: () => void;
    isLoading?: boolean;
  };
  footer?: ReactNode;
  children: ReactNode;
}
interface ApplicationFieldInputProps extends ThemeStyleProps {
  field: Authentic.ApplicationField;
  showFieldTitle?: boolean;
  options?: Authentic.ApplicationFieldValue[] | undefined;
  validValues?: Authentic.ApplicationFieldValue[] | undefined;
  value: Authentic.ApplicationFieldValue;
  onChange: (value: Authentic.ApplicationFieldValue) => void;
  error?: string | boolean;
  isDisabled?: boolean;
  inputElementThemeStyleProps?: ThemeStyleProps;
}
type BooleanFieldButtonsProps = ThemeStyleProps & Pick<ApplicationFieldInputProps, 'value' | 'onChange' | 'error' | 'isDisabled'>;

export function ApplicationView({
  supportPhoneNumber,
  funnelFlow,
  ...themeStyleProps
}: ApplicationViewProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const [tenantIndustries, setTenantIndustries] = useState<Record<string, string[]>>({});
  const [customerIndustry, setCustomerIndustry] = useState<string | null>(null);
  const [initialLeadFormPage, setInitialLeadFormPage] = useState<LEAD_FORM_PAGE>(LEAD_FORM_PAGE.BUSINESS_INFO);
  const [showLeadFormWelcomePage, setShowLeadFormWelcomePage] = useState<boolean>(false);
  const [showLeadFormClassCodeSearchPage, setShowLeadFormClassCodeSearchPage] = useState<boolean>(false);
  const [currentLeadFormPage, setCurrentLeadFormPage] = useState<LEAD_FORM_PAGE>(initialLeadFormPage);
  const [currentPage, setCurrentPage] = useState<PAGE | null>(null);
  const showBackButton = currentPage !== PAGE.APPLICATION_META_FORM || currentLeadFormPage > initialLeadFormPage;
  const [numberOfExposurePages, setNumberOfExposurePages] = useState<number>(0);
  const [numberOfQuestionSectionPages, setNumberOfQuestionSectionPages] = useState<number>(0);
  const totalApplicationPages = numberOfExposurePages + numberOfQuestionSectionPages;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const useLeadFormWelcomePageStyles = isInit && showLeadFormWelcomePage && currentPage === PAGE.APPLICATION_META_FORM && currentLeadFormPage === LEAD_FORM_PAGE.BUSINESS_INFO;
  const [leadFormWelcomePageImageUrl, setLeadFormWelcomePageHeroImageUrl] = useState<string>('https://images.unsplash.com/photo-1594402919317-9e67dca0a305?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  const [leadFormWelcomePageTitle, setLeadFormWelcomePageTitle] = useState<string>('Made to <<mark>>save you money.<<mark>> Insurance built just for you.');
  const [leadFormWelcomePageSubtitle, setLeadFormWelcomePageSubtitle] = useState<string>('We understand your risks, so we built a better insurance program just for your business. Easy. Fast. Get covered in minutes.');

  // Update the document title on mount.
  useEffect(() => { document.title = `Apply | ${getBrandNameWithInsurance(theme)}`; }, []);

  // Update the current page and on client initialization/application status or step change.
  useEffect(() => {
    if (!authentic.isInit) return;
    if (authentic.currentApplicationStatus === Authentic.APPLICATION_STATUS.DECLINED) setCurrentPage(PAGE.APPLICATION_DECLINED);
    else if (authentic.currentApplicationStatus === Authentic.APPLICATION_STATUS.META_REJECTED) setCurrentPage(PAGE.APPLICATION_META_REJECTED);
    else if (authentic.currentApplicationStep === Authentic.APPLICATION_STEP.META) setCurrentPage(PAGE.APPLICATION_META_FORM);
    else if (authentic.currentApplicationStep === Authentic.APPLICATION_STEP.QUESTIONS) setCurrentPage(PAGE.APPLICATION_QUESTIONS);
    else if (authentic.currentApplicationStep === Authentic.APPLICATION_STEP.QUOTE) setCurrentPage(PAGE.APPLICATION_QUOTE);
    // Allow analytics time to initialize without requiring it to in order for the view to initialize.
    setTimeout(() => { setIsInit(true); }, MS_PER_SECOND * 2);
  }, [authentic.isInit, authentic.currentApplicationStatus, authentic.currentApplicationStep]);

  // Once analytics is initialized, check for tenant industries config.
  useEffect(() => {
    if (!analytics.isInit) return;
    const tenantIndustries = analytics.getConfig('tenant_industries') as Record<string, string[]> | null;
    if (tenantIndustries !== null) setTenantIndustries(tenantIndustries);
    const isLeadFormWelcomePageEnabled = analytics.featureIsEnabled('lead_form_welcome_page');
    setShowLeadFormWelcomePage(isLeadFormWelcomePageEnabled);
    if (isLeadFormWelcomePageEnabled) {
      const welcomePageHeroCopy = analytics.getConfig('tenant_welcome_page_copy')?.['hero'] as Record<string, string> | undefined;
      if (typeof welcomePageHeroCopy !== 'undefined') {
        if (typeof welcomePageHeroCopy['imageUrl'] !== 'undefined') setLeadFormWelcomePageHeroImageUrl(welcomePageHeroCopy['imageUrl']);
        if (typeof welcomePageHeroCopy['title'] !== 'undefined') setLeadFormWelcomePageTitle(welcomePageHeroCopy['title']);
        if (typeof welcomePageHeroCopy['subtitle'] !== 'undefined') setLeadFormWelcomePageSubtitle(welcomePageHeroCopy['subtitle']);
      }
    }
    const isHorizontalClassCodeSearchEnabled = analytics.featureIsEnabled('horizontal_class_code_search');
    setShowLeadFormClassCodeSearchPage(isHorizontalClassCodeSearchEnabled);
  }, [analytics.isInit]);

  // Once we have tenant industries and a business class code, set the customer industry.
  useEffect(() => {
    if (Object.keys(tenantIndustries).length === 0 || authentic.applicationMeta.businessClassCode.length === 0) return;
    let customerIndustry: string | null = null;
    for (const [industry, businessClassCodes] of Object.entries(tenantIndustries)) {
      if (businessClassCodes.includes(authentic.applicationMeta.businessClassCode)) {
        customerIndustry = industry;
        break;
      }
    }
    setCustomerIndustry(customerIndustry);
  }, [tenantIndustries, authentic.applicationMeta.businessClassCode]);

  // Set the initial lead form page based on the funnel flow and whether there is pre-fill data available.
  useEffect(() => {
    if (isInit) return;
    const initialLeadFormPage = LEAD_FORM_PAGE[funnelFlow === 'prefill' && authentic.prefillData !== null ? 'PREFILL' : 'BUSINESS_INFO'];
    setInitialLeadFormPage(initialLeadFormPage);
    setCurrentLeadFormPage(initialLeadFormPage);
  }, [funnelFlow, authentic.prefillData]);

  function handleBack(): void {
    if (authentic.currentApplicationStatus === Authentic.APPLICATION_STATUS.DECLINED ||
        authentic.currentApplicationStep === Authentic.APPLICATION_STEP.QUOTE) {
      authentic.setCurrentApplicationStatus(Authentic.APPLICATION_STATUS.META_ACCEPTED);
      authentic.setCurrentApplicationStep(Authentic.APPLICATION_STEP.QUESTIONS);
      authentic.setCurrentApplicationPageIndex(totalApplicationPages - 1);
    } else if (authentic.currentApplicationStep === Authentic.APPLICATION_STEP.QUESTIONS) {
      if (authentic.currentApplicationPageIndex > 0) authentic.setCurrentApplicationPageIndex(authentic.currentApplicationPageIndex - 1);
      else authentic.setCurrentApplicationStep(Authentic.APPLICATION_STEP.META);
    } else if (authentic.currentApplicationStatus === Authentic.APPLICATION_STATUS.META_REJECTED) {
      authentic.setCurrentApplicationStatus(null);
      authentic.setCurrentApplicationStep(Authentic.APPLICATION_STEP.META);
    } else {
      if (currentLeadFormPage > LEAD_FORM_PAGE.PRODUCT_SELECT) setCurrentLeadFormPage(LEAD_FORM_PAGE.PRODUCT_SELECT);
      else if (showLeadFormClassCodeSearchPage && currentLeadFormPage > LEAD_FORM_PAGE.CLASS_CODE_SEARCH) setCurrentLeadFormPage(LEAD_FORM_PAGE.CLASS_CODE_SEARCH);
      else if (initialLeadFormPage !== LEAD_FORM_PAGE.PREFILL && currentLeadFormPage > LEAD_FORM_PAGE.BUSINESS_INFO) setCurrentLeadFormPage(LEAD_FORM_PAGE.BUSINESS_INFO);
      else setCurrentLeadFormPage(LEAD_FORM_PAGE.PREFILL);
    }
  }

  function getInjectedMarkedText(text: string): ReactNode {
    const markDelimiter = '<<mark>>';
    if (!text.includes(markDelimiter)) return text;
    let markedText = <></>;
    while (text.includes(markDelimiter)) {
      const startDelimiterIndex = text.indexOf(markDelimiter);
      const endDelimiterIndex = text.indexOf(markDelimiter, startDelimiterIndex + 1);
      markedText = (
        <>
          {markedText}
          {text.substring(0, startDelimiterIndex)}
          <span
            style={{
              display: 'inline',
              backgroundColor: theme.palette.secondary,
              color: theme.palette.secondaryContrast
            }}
          >
            {text.substring(startDelimiterIndex + markDelimiter.length, endDelimiterIndex)}
          </span>
          {text.substring(endDelimiterIndex + markDelimiter.length)}
        </>
      );
      text = text.substring(endDelimiterIndex + markDelimiter.length);
    }
    return markedText;
  }

  return (
    <View
      centered={!isInit || (!theme.isOnMobile && useLeadFormWelcomePageStyles)}
      subviewThemeStyleProps={{
        column: theme.isOnMobile || !useLeadFormWelcomePageStyles,
        maxW: !useLeadFormWelcomePageStyles ? MAX_VIEW_CONTENT_WIDTH_PX + 'px' : undefined,
        py: !useLeadFormWelcomePageStyles ? 6 : 0
      }}
      {...themeStyleProps}
    >
      {!isInit
        ? (<Box centered column>
            <Spinner size={4} />
            <Text mt={1.5} c='muted' fontSize='sm'>
              {authentic.isSubmittingApplication
                ? 'Loading your quote...'
                : authentic.isLoadingApplication
                  ? 'Loading your application...'
                  : 'Loading...'}
            </Text>
          </Box>)
        : (<>
            <Box
              display={!useLeadFormWelcomePageStyles ? 'none' : undefined}
              position='relative'
              w='100vw'
              h='calc(100svh - 250px)'
              mr={4}
              radius={10}
              overflow='hidden'
              onMobile={{ w: '100%', h: 'auto', mr: undefined, radius: undefined }}
            >
              <Image
                fit='cover'
                src={leadFormWelcomePageImageUrl}
                alt='Depiction of a small business'
                styles={{ filter: 'grayscale(1)' }}
                onMobile={{ display: 'none' }}
              />
              <Box
                position='absolute'
                top={0}
                left={0}
                centered
                column
                w='100%'
                h='100%'
                styles={{
                  backgroundColor: !theme.isOnMobile
                    ? theme.isDarkMode
                      ? 'rgba(0, 0, 0, 0.6)'
                      : 'rgba(255, 255, 255, 0.8)'
                    : undefined
                }}
                onMobile={{ position: 'relative' }}
              >
                <Box
                  column
                  w='100%'
                  maxW='665px'
                  px={4}
                  pt={6}
                  pb={2}
                  onMobile={{ px: undefined }}
                >
                  <Box centered onMobile={{ display: 'none' }}>
                    <Image
                      h='25px'
                      src={theme.isDarkMode && typeof theme.brand.logoUrls.light !== 'undefined'
                        ? theme.brand.logoUrls.light
                        : theme.brand.logoUrls.dark}
                      alt={`${theme.brand.name} logo`}
                    />
                  </Box>
                  <Text
                    variant='heading'
                    mt={3}
                    fontSize='xl'
                    onMobile={{ mt: undefined, fontSize: 'lg', textAlign: 'center' }}
                  >
                    {getInjectedMarkedText(leadFormWelcomePageTitle)}
                  </Text>
                  <Text mt={1.5} fontSize={1.25} onMobile={{ fontSize: 'sm', textAlign: 'center' }}>
                    {leadFormWelcomePageSubtitle}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box centered w={!useLeadFormWelcomePageStyles ? '100%' : undefined} styles={{ display: 'grid' }}>
              <Button
                variant='icon'
                position='absolute'
                top={showBackButton ? 5.5 : 7.5}
                left={3}
                opacity={showBackButton ? (isSubmitting ? 0.5 : 1) : 0}
                pointerEvents={!showBackButton ? 'none' : undefined}
                zIndex={999}
                onMobile={{ top: -7.5, left: 1 }}
                onClick={handleBack}
                isDisabled={authentic.isLoadingAvailableProducts ||
                  authentic.isSubmittingApplication ||
                  authentic.isGeneratingApplicationQuotesPaymentData ||
                  isSubmitting}
              >
                <Icon name='RiArrowLeftLine' size={1.75} />
              </Button>
              <LeadFormPages
                supportPhoneNumber={supportPhoneNumber}
                isActive={currentPage === PAGE.APPLICATION_META_FORM}
                isCompleted={currentPage !== PAGE.APPLICATION_META_FORM}
                initialPage={initialLeadFormPage}
                customerIndustry={customerIndustry}
                showWelcomePage={showLeadFormWelcomePage}
                showClassCodeSearchPage={showLeadFormClassCodeSearchPage}
                currentPage={currentLeadFormPage}
                setCurrentPage={setCurrentLeadFormPage}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
              <ExposurePages
                isActive={currentPage === PAGE.APPLICATION_QUESTIONS}
                isCompleted={currentPage === PAGE.APPLICATION_DECLINED || currentPage === PAGE.APPLICATION_QUOTE}
                customerIndustry={customerIndustry}
                setNumberOfExposurePages={setNumberOfExposurePages}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
              <QuestionSectionPages
                isActive={currentPage === PAGE.APPLICATION_QUESTIONS}
                isCompleted={currentPage === PAGE.APPLICATION_DECLINED || currentPage === PAGE.APPLICATION_QUOTE}
                customerIndustry={customerIndustry}
                numberOfExposurePages={numberOfExposurePages}
                setNumberOfQuestionSectionPages={setNumberOfQuestionSectionPages}
                totalApplicationPages={totalApplicationPages}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
              <QuotesPage
                supportPhoneNumber={supportPhoneNumber}
                isActive={currentPage === PAGE.APPLICATION_QUOTE}
                customerIndustry={customerIndustry}
              />
              <DeclinePage
                supportPhoneNumber={supportPhoneNumber}
                isActive={currentPage === PAGE.APPLICATION_META_REJECTED || currentPage === PAGE.APPLICATION_DECLINED}
                customerIndustry={customerIndustry}
              />
            </Box>
          </>)}
    </View>
  );
}

export function ApplicationPage({
  isActive = false,
  isCompleted = false,
  customerIndustry,
  title,
  continueButton,
  footer,
  children,
  ...themeStyleProps
}: ApplicationPageProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();

  // When the page becomes active, scroll to the top of it.
  useEffect(() => { if (isActive) window.scrollTo(0, 0); }, [isActive]);

  function getInjectedPageTitle(title: string): string {
    title = injectCustomerIndustry(title, customerIndustry);
    while (title.includes('{{')) {
      const startDelimiterIndex = title.indexOf('{{');
      const endDelimiterIndex = title.indexOf('}}');
      const indexer = title.substring(startDelimiterIndex + 2, endDelimiterIndex);
      const isStatsigConfig = indexer.startsWith('statsig');
      if (analytics.isInit && isStatsigConfig) {
        const statsigConfigName = indexer.replace('statsig.', '').split('.')[0] ?? '';
        const statsigConfigProperty = indexer.replace(`statsig.${statsigConfigName}.`, '');
        const template = (analytics.getConfig(statsigConfigName)?.[statsigConfigProperty] as string | undefined) ?? '';
        title = title.substring(0, startDelimiterIndex) + template + title.substring(endDelimiterIndex + 2);
      } else {
        const fieldValue = authentic.application?.answers.questions[indexer] ?? null;
        if (fieldValue === null) break;
        title = title.substring(0, startDelimiterIndex) +
          (indexer === 'NAME' ? fieldValue.split(' ')[0] : fieldValue) +
          title.substring(endDelimiterIndex + 2);
      }
    }
    return title;
  }

  return (
    <Box
      justify='center'
      position='relative'
      w='100%'
      maxH={isActive ? '100%' : '0px'}
      opacity={isActive ? 1 : 0}
      transition
      transitionDuration={0}
      transitionDelay={!isActive ? 1 : undefined}
      overflow={!isActive ? 'hidden' : undefined}
      pointerEvents={!isActive ? 'none' : undefined}
      styles={{
        gridArea: '1 / 1',
        alignSelf: 'flex-start',
        justifySelf: 'center'
      }}
    >
      <Box
        column
        align='center'
        w='100%'
        maxW={MAX_ELEMENT_WIDTH_PX + 'px'}
        ml={isCompleted ? -4 : undefined}
        mr={!isActive && !isCompleted ? -4 : undefined}
        opacity={isActive ? 1 : 0}
        transition
        transitionDelay={isActive ? 1 : undefined}
        {...themeStyleProps}
      >
        {typeof title !== 'undefined' && (
          <Text
            variant='heading'
            maxW={MAX_ELEMENT_WIDTH_PX + 'px'}
            mb={4}
            textAlign='center'
          >
            {getInjectedPageTitle(title)}
          </Text>
        )}
        {children}
        {typeof continueButton !== 'undefined' && (
          <Button
            variant='primary'
            mt={6}
            isDisabled={!isActive || continueButton.isDisabled === true}
            {...continueButton}
          >
            {continueButton.label ?? 'Next'}
          </Button>
        )}
        {typeof footer !== 'undefined' && (
          <Box centered column mt={1.5}>
            {footer}
          </Box>
        )}
      </Box>
    </Box>
  );
}

function ApplicationFieldTitle(field: Authentic.ApplicationField): ReactElement {
  return (
    <Box display='block' mb={1}>
      <Text inline>{`${field.isOptional ? 'Optional—' : ''}${field.title}`}</Text>
      {typeof field.helperText !== 'undefined' && <Tooltip label={<Markdown>{field.helperText}</Markdown>} />}
    </Box>
  );
}

export function ApplicationFieldInput({
  field,
  showFieldTitle = true,
  options,
  validValues,
  value,
  onChange,
  error = false,
  isDisabled = false,
  inputElementThemeStyleProps,
  ...themeStyleProps
}: ApplicationFieldInputProps): ReactElement {
  const [showOptionsVariant, setShowOptionsVariant] = useState<boolean>(false);
  const inputTooltip = typeof field.helperText !== 'undefined'
    ? <Tooltip label={<Markdown>{field.helperText}</Markdown>} />
    : undefined;

  if (field.type === 'date') {
    return (
      <Box column w='100%' {...themeStyleProps}>
        {showFieldTitle && <ApplicationFieldTitle {...field} />}
        <DateInput
          label={field.label}
          minDate={typeof field.minimumDateOffset !== 'undefined'
            ? getOffsetUtcDateFromTimestamp(
              Date.now(),
              parseInt(field.minimumDateOffset.amount),
              field.minimumDateOffset.unit
            )
            : undefined}
          maxDate={typeof field.maximumDateOffset !== 'undefined'
            ? getOffsetUtcDateFromTimestamp(
              Date.now(),
              parseInt(field.maximumDateOffset.amount),
              field.maximumDateOffset.unit
            )
            : undefined}
          value={value !== null ? new Date(value) : undefined}
          onChange={(v) => {
            // Field responses for dates must be submitted in UTC as ISO strings.
            onChange(v !== null ? v.toISOString() : null);
          }}
          error={error}
          isDisabled={isDisabled}
          {...inputElementThemeStyleProps}
        />
      </Box>
    );
  }

  if (field.type === 'address') {
    return (
      <AddressInput
        validStates={typeof validValues !== 'undefined' ? validValues as StateCode[] : undefined}
        label={field.label}
        rightElement={inputTooltip}
        value={value ?? undefined}
        onChange={onChange}
        error={error}
        isDisabled={isDisabled}
        {...inputElementThemeStyleProps}
      />
    );
  }

  if (field.type === 'email') {
    return (
      <EmailInput
        label={field.label}
        rightElement={inputTooltip}
        value={value ?? undefined}
        onChange={onChange}
        error={error}
        isDisabled={isDisabled}
        {...inputElementThemeStyleProps}
      />
    );
  }
  if (field.type === 'phone') {
    return (
      <PhoneInput
        label={field.label}
        rightElement={inputTooltip}
        value={value ?? undefined}
        onChange={onChange}
        error={error}
        isDisabled={isDisabled}
        {...inputElementThemeStyleProps}
      />
    );
  }
  if (field.type === 'url') {
    return (
      <UrlInput
        label={field.label}
        rightElement={inputTooltip}
        value={value ?? undefined}
        onChange={onChange}
        error={error}
        isDisabled={isDisabled}
        {...inputElementThemeStyleProps}
      />
    );
  }
  if (field.type === 'percent') {
    return (
      <Box column w='100%'>
        {showFieldTitle && <ApplicationFieldTitle {...field} />}
        <PercentInput
          value={value ?? undefined}
          onChange={onChange}
          error={error}
          isDisabled={isDisabled}
          {...inputElementThemeStyleProps}
        />
      </Box>
    );
  }

  if (field.type === 'boolean') {
    return (
      <Box column w='100%' {...themeStyleProps}>
        {showFieldTitle && <ApplicationFieldTitle {...field} />}
        <BooleanApplicationFieldButtons
          value={value}
          onChange={onChange}
          error={error}
          isDisabled={isDisabled}
          {...inputElementThemeStyleProps}
        />
      </Box>
    );
  }

  if (field.type === 'select') {
    return (
      <Select
        label={field.label}
        rightElement={inputTooltip}
        options={field.options
          .filter((option): option is string => typeof option === 'string')
          .map((option) => ({ value: option, label: option }))}
        value={value}
        onChange={onChange}
        error={error}
        isDisabled={isDisabled}
        {...inputElementThemeStyleProps}
      />
    );
  }
  if (field.type === 'select-group') {
    const nonDecliningOptions = field.options
      .filter((option): option is string => typeof option === 'string' && !option.includes('None'));
    const decliningOption = field.options
      .find((option): option is string => typeof option === 'string' && option.includes('None'));
    if (typeof decliningOption === 'undefined') {
      console.error('Error: No declining option found for "select-group" field.', field);
      return <></>;
    }

    return (
      <Box column w='100%' maxW={MAX_ELEMENT_WIDTH_PX + 'px'} {...themeStyleProps}>
        <Box
          column
          w='100%'
          maxH={!showOptionsVariant ? '1500px' : '150px'}
          px={2}
          py={1}
          border='subtle'
          radius='base'
          opacity={showOptionsVariant ? 0.5 : undefined}
          transition
          transitionDuration={2}
          transitionDelay={!showOptionsVariant ? 1 : undefined}
          overflowY='hidden'
        >
          <ul>
            {nonDecliningOptions.map((option, i) => {
              return (
                <li key={i}>
                  <Text my={1}>{option}</Text>
                </li>
              );
            })}
          </ul>
        </Box>
        <BooleanApplicationFieldButtons
          mt={2}
          value={value === null ? null : (showOptionsVariant || nonDecliningOptions.includes(value)) ? 'Yes' : 'No'}
          onChange={(v) => {
            if (v === 'No') onChange(decliningOption);
            setShowOptionsVariant(v === 'Yes');
          }}
          error={(typeof error === 'string' ? error.length > 0 : error) && !showOptionsVariant}
          isDisabled={isDisabled}
          {...inputElementThemeStyleProps}
        />
        <Box
          column
          maxH={showOptionsVariant ? '1500px' : '0px'}
          radius='base'
          opacity={showOptionsVariant ? 1 : 0}
          transition
          transitionDuration={2}
          transitionDelay={showOptionsVariant ? 1 : undefined}
          overflow='hidden'
          pointerEvents={!showOptionsVariant ? 'none' : undefined}
        >
          <Text mb={1} mt={2}>
            Which option best applies to you or your business?
          </Text>
          <RadioGroup
            itemLabels={nonDecliningOptions}
            activeItemIndex={value !== null ? nonDecliningOptions.indexOf(value) : -1}
            onChange={(activeItemIndex) => { onChange(nonDecliningOptions[activeItemIndex] ?? null); }}
            isDisabled={isDisabled}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Input
      type={(field.type === 'number' || field.type === 'currency' || field.type === 'year') ? 'number' : 'text'}
      mode={(field.type === 'number' || field.type === 'year')
        ? 'numeric'
        : field.type === 'currency'
          ? 'decimal'
          : 'text'}
      label={field.label}
      iconName={field.iconName as IconName | undefined}
      rightElement={inputTooltip}
      min={typeof field.minimumNumberValue !== 'undefined' && !isNaN(parseInt(field.minimumNumberValue))
        ? parseFloat(field.minimumNumberValue)
        : undefined}
      max={typeof field.maximumNumberValue !== 'undefined' && !isNaN(parseInt(field.maximumNumberValue))
        ? parseFloat(field.maximumNumberValue)
        : undefined}
      value={value ?? undefined}
      displayFormattedValue={field.type !== 'year'}
      onChange={(v) => {
        if ((field.type === 'number' || field.type === 'currency' || field.type === 'year') && v !== null) {
          v = getRoundedNumber(v, parseFloat(field.maximumDecimalPlaces ?? '0')).toString();
        }
        onChange(v);
      }}
      error={error}
      isDisabled={isDisabled}
      {...inputElementThemeStyleProps}
    />
  );
}

function BooleanApplicationFieldButtons({
  value,
  onChange,
  error = false,
  isDisabled = false,
  ...themeStyleProps
}: BooleanFieldButtonsProps): ReactElement {
  const theme = useTheme();

  return (
    <Box align='center' {...themeStyleProps}>
      {['Yes', 'No'].map((booleanFieldValue, i) => {
        const isActive = value === booleanFieldValue;

        return (
          <Button
            key={i}
            variant='secondary'
            w='80px'
            mr={i === 0 ? 1 : undefined}
            c={isActive
              ? theme.palette.doesPrimaryPassAccessibility
                ? 'primary'
                : 'text'
              : 'muted'}
            border={(typeof error === 'string' ? error.length > 0 : error)
              ? 'error'
              : isActive
                ? theme.palette.doesPrimaryPassAccessibility
                  ? 'primary'
                  : 'text'
                : 'subtle'}
            radius='base'
            onClick={() => { onChange(booleanFieldValue); }}
            isDisabled={isDisabled}
          >
            {booleanFieldValue}
          </Button>
        );
      })}
    </Box>
  );
}

/**
 * Injects the given text string with the given customer industry (or "Business" if the industry is `null`).
 */
export function injectCustomerIndustry(text: string, customerIndustry: string | null): string {
  if (customerIndustry === null) customerIndustry = 'Business';
  const pluralCustomerIndustry = customerIndustry.endsWith('y')
    ? customerIndustry.substring(0, customerIndustry.length - 1) + 'ies'
    : customerIndustry.endsWith('s')
      ? customerIndustry + 'es'
      : customerIndustry + 's';
  const isFirstWordInText = text.startsWith('{{INDUSTRY');
  return text
    .replaceAll('{{INDUSTRY_PLURAL}}', isFirstWordInText ? pluralCustomerIndustry : pluralCustomerIndustry.toLowerCase())
    .replaceAll('{{INDUSTRY}}', isFirstWordInText ? customerIndustry : customerIndustry.toLowerCase());
}
