import { type ReactElement, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import * as Authentic from '@authenticins/ts-client';
import {
  type FunnelFlow,
  type ThemeStyleProps,
  formatPhoneNumber,
  useTheme,
  Box,
  Text,
  Button,
  Link,
  Dropdown,
  Image,
  Icon
} from '@authenticins/react-ui';

import { ROUTE } from '../main';
import { useAnalytics, useAuthentic } from '../providers';

interface NavbarProps extends ThemeStyleProps {
  supportPhoneNumber: number;
  funnelFlow: FunnelFlow;
}

export function Navbar({
  supportPhoneNumber,
  funnelFlow,
  ...themeStyleProps
}: NavbarProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isOnApplicationView = location.pathname === ROUTE.APPLY ||
    ((funnelFlow === 'prefill' || funnelFlow === 'lead') && location.pathname === '/');
  const isOnPolicyView = location.pathname === ROUTE.POLICY;
  const [showViewPoliciesButton, setShowViewPoliciesButton] = useState<boolean>(false);

  // Once analytics is initialized, check if lead form welcome page is enabled.
  useEffect(() => {
    if (!analytics.isInit) return;
    const isLeadFormWelcomePageEnabled = analytics.featureIsEnabled('lead_form_welcome_page');
    setShowViewPoliciesButton(!isLeadFormWelcomePageEnabled);
  }, [analytics.isInit]);

  async function handleLogOut(): Promise<void> {
    const email = authentic.auth.email;
    const wasError = await authentic.auth.logOut();
    if (!wasError) {
      if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.LOGGED_OUT, { email });
      window.location.reload();
    }
  }

  return (
    <Box
      align='center'
      justify='space-between'
      position='relative'
      w='100vw'
      h='80px'
      px={4}
      py={2}
      bg='background'
      borderBottom='subtle'
      zIndex={999}
      onMobile={{ px: 2 }}
      {...themeStyleProps}
    >
      <Box align='center' w='33%'>
        {authentic.auth.isAuthenticated && (
          <Dropdown
            items={[
              ...(!isOnPolicyView
                ? [{
                    onClick: () => { navigate(ROUTE.POLICY); },
                    children: <Text>View my policies</Text>
                  }]
                : []),
              {
                c: 'error',
                onClick: () => { void handleLogOut(); },
                children: (
                  <>
                    <Text>Log out</Text>
                    <Icon name='RiLogoutBoxLine' />
                  </>
                )
              }
            ]}
          >
            {!theme.isOnMobile && <Text mr={1} bold>{authentic.auth.email}</Text>}
            <Icon name={theme.isOnMobile ? 'RiMenuLine' : 'RiArrowDownSLine'} size={1.25} />
          </Dropdown>
        )}
      </Box>
      <Box centered column position='relative' w='33%' h='100%'>
        <Box centered>
          <Image
            h={theme.isOnMobile ? '25px' : '40px'}
            src={theme.isDarkMode && typeof theme.brand.logoUrls.light !== 'undefined'
              ? theme.brand.logoUrls.light
              : theme.brand.logoUrls.dark}
            alt={`${theme.brand.name} logo`}
          />
        </Box>
        {isOnApplicationView && <ApplicationStepsIndicator position='absolute' bottom={-3.5} />}
      </Box>
      <Box align='center' justify='flex-end' w='33%'>
        {!theme.isOnMobile && !isOnPolicyView && showViewPoliciesButton && (
          <Button
            variant='secondary'
            mr={3}
            onClick={() => { navigate(ROUTE.POLICY); }}
          >
            View policies
          </Button>
        )}
        <Link
          href={`tel:${supportPhoneNumber}`}
          onClick={() => { if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.CLICKED_SPEAK_TO_AGENT_CTA); }}
        >
          <Icon name='RiCustomerService2Line' size={1.25} />
          <Text ml={1} onMobile={{ display: 'none' }}>
            {formatPhoneNumber(supportPhoneNumber)}
          </Text>
        </Link>
      </Box>
    </Box>
  );
}

function ApplicationStepsIndicator(themeStyleProps: ThemeStyleProps): ReactElement {
  const authentic = useAuthentic();
  const theme = useTheme();
  const [isInit, setIsInit] = useState<boolean>(false);

  // Animate in on mount.
  useEffect(() => {
    setTimeout(() => { setIsInit(true); }, theme.transition.baseDurationMs);
    return () => { setIsInit(false); };
  }, []);

  return (
    <Box centered px={2} py={1} bg={isInit ? 'background' : undefined} transition {...themeStyleProps}>
      {Object.keys(Authentic.APPLICATION_STEP).filter((key) => !isNaN(parseInt(key))).map((_, i) => {
        const hasBeenReached = authentic.currentApplicationStep >= i;

        return (
          <Box
            key={i}
            w='24px'
            h='6px'
            mx={0.5}
            mt={!isInit ? 1 : 0}
            mb={!isInit ? -1 : 0}
            bg={hasBeenReached ? 'secondary' : 'subtle'}
            radius='full'
            opacity={isInit ? 1 : 0}
            transition
            transitionDelay={i / 2}
          />
        );
      })}
    </Box>
  );
}
