import { type ReactElement, Fragment, useState, useEffect } from 'react';
import type * as Authentic from '@authenticins/ts-client';
import {
  type ThemeStyleProps,
  type IconName,
  MAX_ELEMENT_WIDTH_PX,
  useTheme,
  Box,
  Text,
  Button,
  Switch,
  Tag,
  Image,
  Icon
} from '@authenticins/react-ui';

import { useAnalytics } from '../providers';
import { ProductDetailsModal, ProductIcon } from '.';

interface ProductCardProps extends ThemeStyleProps {
  product: Authentic.Product;
  isMandatory?: boolean;
  isActive?: boolean;
  showHeaderDetails?: boolean;
  containingBundleNames?: string[];
  actions?: Array<{ label: string; onClick: () => void }>;
  onChange?: (isActive: boolean) => void;
  isDisabled?: boolean;
}
export interface ProductDetails {
  title: string;
  description: string;
  iconUrl?: string;
  coverageExamples: CoverageExample[];
  faqs: ProductFaq[];
}
interface CoverageExample {
  iconName: IconName;
  title: string;
}
interface ProductFaq {
  question: string;
  answer?: string;
  bulletPoints?: string[];
}

const DEFAULT_PRODUCT_DETAILS: Record<string, ProductDetails> = {
  'General Liability': {
    title: 'Protect your business',
    description: 'Essential coverage to protect against common accidents.',
    coverageExamples: [
      {
        iconName: 'RiFirstAidKitLine',
        title: 'Customer injuries or illnesses caused by your business'
      },
      {
        iconName: 'RiBuilding3Line',
        title: "Accidental damage of someone else's property"
      },
      {
        iconName: 'RiAuctionLine',
        title: 'Defense costs for copyright infringement, libel, or slander'
      }
    ],
    faqs: [
      {
        question: 'Why is General Liability important?',
        answer: 'Proof of General Liability is often required for signing leases, obtaining permits and meeting business requirements. General liability insurance also shows your clients that your business is responsible.'
      },
      {
        question: 'What is General Liability?',
        answer: 'Coverage that protects your business from financial losses due to claims of bodily injury, property damage, or advertising injury caused to others by your business operations. It helps cover legal fees, medical expenses, and damages awarded in lawsuits, providing financial security and peace of mind.'
      }
    ]
  },
  Property: {
    title: 'Protect your buildings and assets',
    description: 'Coverage for your Equipment, supplies, furniture and inventory.',
    coverageExamples: [
      {
        iconName: 'RiFireLine',
        title: 'Fire destroys your furniture, inventory, and equipment'
      },
      {
        iconName: 'RiCriminalLine',
        title: 'Someone breaks in and steals your inventory'
      },
      {
        iconName: 'RiFloodLine',
        title: 'A pipe bursts and causes water damage'
      },
      {
        iconName: 'RiCloudWindyLine',
        title: 'Loss of business income due to property damage'
      }
    ],
    faqs: [
      {
        question: 'Why is Property coverage important?',
        answer: 'As a business owner, your equipment, inventory, and other property are essential for your operations. Business Personal Property (BPP) insurance ensures that if something happens at your business, like fire, pipe burst, or theft, you can replace the items you need to keep going.'
      },
      {
        question: 'What is Property coverage?',
        answer: 'Coverage that protects your business assets including equipment, inventory, and furniture against losses caused by fire, theft, vandalism, natural disasters, and other covered perils. It typically covers repair or replacement costs, helping your business recover swiftly from unexpected property damage.'
      }
    ]
  },
  'Professional Liability': {
    title: 'Protection if you get sued',
    description: 'Coverage in case of professional mistakes or allegations of error.',
    coverageExamples: [
      {
        iconName: 'RiAuctionLine',
        title: 'Client sues you for incorrect advice'
      },
      {
        iconName: 'RiFilePaper2Line',
        title: 'Your business fails to deliver according to contract terms'
      }
    ],
    faqs: [
      {
        question: 'Why is Professional Liability important?',
        answer: 'Mistakes and errors happen in business, and when they do professional liability insurance is there to quickly resolves these issues, regardless of fault. This helps avoid customer disputes and preserve your reputation. It covers expenses for mistakes and negligence, and it defends against false accusations. Remember, any business can make mistakes, making this insurance essential for protecting your reputation and livelihood.'
      },
      {
        question: 'What is Professional Liability?',
        answer: "Professional Liability insurance, also known as Errors and Omissions insurance, protects your business from claims of negligence, errors, or omissions in professional services or advice provided to clients. It covers legal defense costs, settlements, and judgments arising from allegations of professional misconduct, helping safeguard your business's finances and reputation."
      },
      {
        question: 'What is not covered by Professional Liability?',
        bulletPoints: [
          'Intentional misconduct or illegal activity',
          'Car accidents',
          'Employee injuries'
        ]
      }
    ]
  }
};

export function ProductCard({
  product,
  isMandatory = false,
  isActive = true,
  showHeaderDetails = false,
  containingBundleNames,
  actions,
  onChange,
  isDisabled = false,
  ...themeStyleProps
}: ProductCardProps): ReactElement {
  const analytics = useAnalytics();
  const theme = useTheme();
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(DEFAULT_PRODUCT_DETAILS[product.name] ?? null);
  const [productDetailsModalCurrentProductId, setProductDetailsModalCurrentProductId] = useState<string | null>(null);
  const useActiveStyles = isActive || isMandatory;

  // Check analytics for product details config.
  useEffect(() => {
    if (!analytics.isInit) return;
    const productDetails = analytics.getConfig('tenant_product_details')?.[product.name] as ProductDetails | undefined;
    if (typeof productDetails !== 'undefined') setProductDetails(productDetails);
  }, [analytics.isInit, product]);

  return (
    <>
      <Box
        column
        w='100%'
        {...themeStyleProps}>
        {showHeaderDetails && productDetails !== null && (
          <>
            <Box align='center' wrap>
              <Text variant='heading' mb={1} mr={typeof containingBundleNames !== 'undefined' ? 2 : undefined}>
                {productDetails.title}
              </Text>
              {containingBundleNames?.map((containingBundleName) => (
                <Tag mb={1} mr={2}>
                  <Icon name='RiShieldLine' size='sm' mr={0.5} />
                  {containingBundleName}
                </Tag>
              ))}
            </Box>
            <Text mb={2}>
              {productDetails.description}
            </Text>
          </>
        )}
        <Box
          align='center'
          position='relative'
          w='100%'
          maxW={MAX_ELEMENT_WIDTH_PX + 'px'}
          p={3}
          bg='surface'
          radius='lg'
          shadow={useActiveStyles ? 'drop' : undefined}
          opacity={isDisabled ? 0.5 : undefined}
          transition
          onMobile={{ px: 2 }}
        >
          {typeof productDetails?.iconUrl !== 'undefined'
            ? (<Box
                centered
                w={(theme.font.size.base * 4) + 'px'}
                transition
                styles={{ filter: !useActiveStyles ? 'grayscale(100%)' : undefined }}
              >
                <Image fit='contain' src={productDetails.iconUrl} alt={`${productDetails.title} icon`} />
              </Box>)
            : (<ProductIcon
                isActive={useActiveStyles}
                productName={product.name}
              />)}
          <Box column w='100%' ml={1}>
            <Box justify='space-between' w='100%'>
              <Text variant='heading' pr={2} onMobile={{ fontSize: 1.25 }}>
                {product.name}
              </Text>
              {typeof onChange !== 'undefined' && (isMandatory
                ? (<Text c='muted'>
                    Included
                  </Text>)
                : (<Switch
                    mt={-1}
                    isActive={isActive}
                    onChange={onChange}
                    isDisabled={isDisabled}
                  />))}
            </Box>
            {typeof onChange !== 'undefined' && (
              <Box align='center' mt={1}>
                <Button
                  variant='link'
                  onClick={() => { setProductDetailsModalCurrentProductId(product.id); }}
                  isDisabled={isDisabled}
                >
                  View details
                </Button>
                {typeof actions !== 'undefined' && actions.length > 0 && actions.map((action, i) => (
                  <Fragment key={i}>
                    <Box w={theme.border.baseWidthPx + 'px'} h='20px' mx={1} bg='subtle' />
                    <Button variant='link' onClick={action.onClick} isDisabled={isDisabled}>
                      {action.label}
                    </Button>
                  </Fragment>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {productDetails !== null && (
        <ProductDetailsModal
          product={product}
          productDetails={productDetails}
          isOpen={productDetailsModalCurrentProductId === product.id}
          onClose={() => { setProductDetailsModalCurrentProductId(null); }}
        />
      )}
    </>
  );
}
