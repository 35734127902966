import { type ReactElement, useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import {
  MS_PER_SECOND,
  DEFAULT_AUTH_CODE_LENGTH,
  emailIsValid,
  getUrlParam,
  useTheme,
  Text,
  Button,
  EmailInput,
  AuthCodeInput,
  Image,
  Modal
} from '@authenticins/react-ui';

import { useAnalytics, useAuthentic } from '../../providers';

interface LoginModalProps {
  authCodeLength?: number;
  isOpen: boolean;
  onClose: (wasAuthenticated: boolean) => void;
}

const RESEND_AUTH_CODE_TIMEOUT_MS = 30 * MS_PER_SECOND;

export function LoginModal({
  authCodeLength = DEFAULT_AUTH_CODE_LENGTH,
  isOpen,
  onClose
}: LoginModalProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const [email, setEmail] = useState<string | null>(null);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [showAuthCodeInput, setShowAuthCodeInput] = useState<boolean>(false);
  const [isAuthCodeInvalid, setIsAuthCodeInvalid] = useState<boolean>(false);
  const [resendAuthCodeTimeoutMs, setResendAuthCodeTimeoutMs] = useState<number>(0);
  const [resendAuthCodeTimoutInterval, setResendAuthCodeTimoutInterval] = useState<NodeJS.Timeout | null>(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // When the auth code changes and is the correct length, reset error state.
  useEffect(() => {
    if (authCode === null || authCode.length < authCodeLength) return;
    setIsAuthCodeInvalid(false);
  }, [authCodeLength, authCode]);

  // Check URL params for email, and automatically send login code if not authenticated.
  useEffect(() => {
    const urlParamsEmail = getUrlParam(window.location.href, 'email') as string | null;
    if (!authentic.isInit || authentic.auth.isAuthenticated || urlParamsEmail === null || !emailIsValid(urlParamsEmail)) return;
    setEmail(urlParamsEmail);
    setShowAuthCodeInput(true);
    void handleSubmitEmail(urlParamsEmail);
    return () => {
      setEmail(null);
      setShowAuthCodeInput(false);
    };
  }, [authentic.isInit, isOpen]);

  async function handleSubmitEmail(email: string): Promise<boolean> {
    if (resendAuthCodeTimoutInterval !== null) clearInterval(resendAuthCodeTimoutInterval);
    setIsSubmitting(true);
    const wasError = await authentic.auth.sendLoginCode(email, true);
    if (!wasError) {
      if (!showAuthCodeInput) setShowAuthCodeInput(true);
      setResendAuthCodeTimeoutMs(RESEND_AUTH_CODE_TIMEOUT_MS);
      const authCodeTimoutInterval = setInterval(() => {
        setResendAuthCodeTimeoutMs((prev) => Math.max(0, prev - MS_PER_SECOND));
      }, MS_PER_SECOND);
      setResendAuthCodeTimoutInterval(authCodeTimoutInterval);
    }
    setHasAttemptedSubmit(false);
    setIsSubmitting(false);
    return wasError;
  }

  async function handleSubmitAuthCode(): Promise<boolean> {
    if (authCode === null) return true;
    setIsSubmitting(true);
    setIsAuthCodeInvalid(false);
    const wasError = await authentic.auth.verifyLoginCode(authCode);
    setIsAuthCodeInvalid(wasError);
    if (!wasError) {
      onClose(true);
      if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.LOGGED_IN, { email: authentic.auth.email });
    }
    setHasAttemptedSubmit(false);
    setIsSubmitting(false);
    return wasError;
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      onClose={() => { if (!isSubmitting) onClose(false); }}
      footer={
        <>
          <Button
            variant='primary'
            onClick={() => {
              if (showAuthCodeInput) void handleSubmitAuthCode();
              else if (email !== null) void handleSubmitEmail(email);
            }}
            isDisabled={isSubmitting || (showAuthCodeInput
              ? authCode === null || authCode.length !== authCodeLength || isAuthCodeInvalid
              : email === null)}
            onDisabledClick={() => { setHasAttemptedSubmit(true); }}
            isLoading={isSubmitting}
          >
            {showAuthCodeInput ? 'Submit' : 'Send code'}
          </Button>
          {showAuthCodeInput && (
            <Text mt={1.5}>
              Didn't receive a code?&nbsp;
              <Button
                variant='link'
                inline
                onClick={() => { if (email !== null) void handleSubmitEmail(email); }}
                isDisabled={isSubmitting || resendAuthCodeTimeoutMs > 0}
              >
                Resend
                {resendAuthCodeTimeoutMs > 0 ? ` in ${resendAuthCodeTimeoutMs / MS_PER_SECOND}s` : ''}
              </Button>
            </Text>
          )}
        </>
      }
    >
      <Image
        h='50px'
        src={theme.isDarkMode && typeof theme.brand.logoUrls.light !== 'undefined'
          ? theme.brand.logoUrls.light
          : theme.brand.logoUrls.dark}
        alt={`${theme.brand.name} logo`}
      />
      <Text variant='heading' mt={2} textAlign='center'>
        Log in to continue
      </Text>
      <Text mt={1.5} textAlign='center'>
        {showAuthCodeInput
          ? `Enter the code sent to to ${email} below.`
          : 'For policies, quotes, or to apply, please enter your email.'}
      </Text>
      {showAuthCodeInput
        ? (<AuthCodeInput
            codeLength={authCodeLength}
            mt={4}
            value={authCode ?? undefined}
            onChange={setAuthCode}
            error={isAuthCodeInvalid ? 'Invalid code' : hasAttemptedSubmit && authCode === null}
            isDisabled={isSubmitting}
          />)
        : (<EmailInput
            mt={4}
            value={email ?? undefined}
            onChange={setEmail}
            error={hasAttemptedSubmit && email === null}
            isDisabled={isSubmitting}
          />)}
    </Modal>
  );
}
