import { type ReactElement } from 'react';
import type * as Authentic from '@authenticins/ts-client';
import {
  Box,
  Text,
  Accordion,
  Modal,
  Icon,
  Markdown
} from '@authenticins/react-ui';

import type { ProductDetails } from '..';

interface ProductDetailsModalProps {
  product: Authentic.Product;
  productDetails: ProductDetails;
  isOpen: boolean;
  onClose: () => void;
}

export function ProductDetailsModal({
  product,
  productDetails,
  isOpen,
  onClose
}: ProductDetailsModalProps): ReactElement {
  return (
    <Modal
      title={`${product.name} coverage`}
      subtitle={productDetails.description}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text variant='label'>Carrier:</Text>
      <Box mt={1}>
        <Icon name='RiAwardLine' mt={0.25} mr={1} />
        <Box column>
          <Text>{product.carrier.name}</Text>
          <Text c='muted' fontSize='sm'>{product.carrier.description}</Text>
        </Box>
      </Box>
      <Text variant='label' mt={2}>Covers you in events like:</Text>
      <Box column w='100%'>
        {productDetails.coverageExamples.map((coverageExample, i) => (
          <Box key={i} mt={1}>
            <Icon name={coverageExample.iconName} mt={0.25} mr={1} />
            <Text>{coverageExample.title}</Text>
          </Box>
        ))}
      </Box>
      {productDetails !== null && (
        <Accordion
          mt={4}
          items={productDetails.faqs.map((faq) => ({
            title: faq.question,
            content: typeof faq.answer !== 'undefined' && <Markdown>{faq.answer}</Markdown>
          }))}
        />
      )}
    </Modal>
  );
};
