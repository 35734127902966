import type { ReactElement } from 'react';
import { useTheme, useThemeStyles } from '@authenticins/react-ui';

import { type ProductIconProps } from '.';

export function GeneralLiabilityIcon({ isActive = true, size = 4, ...themeStyleProps }: Omit<ProductIconProps, 'productName'>): ReactElement {
  const theme = useTheme();
  const themeStyles = useThemeStyles(themeStyleProps);
  const sizePx = (typeof size === 'number' ? size * theme.font.size.base : theme.font.size[size]) + 'px';
  const accentFill = isActive ? theme.palette.primary : theme.palette.muted;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1250 1250'
      fill='none'
      style={{
        ...themeStyles,
        minWidth: sizePx,
        width: sizePx,
        minHeight: sizePx,
        height: sizePx
      }}
    >
      <path d='M952.674 636.647C901.094 983.015 676.873 1142.75 676.873 1142.75C676.873 1142.75 426.528 1171.17 210.921 886.512C64.8944 693.677 38.17 356.715 38.17 356.715C132.605 353.799 223.037 318.407 293.824 256.662C365.408 193.588 404.328 53.7165 404.328 53.7165L522.457 61.0505L523.576 62.532C523.718 62.6701 523.844 62.8226 523.954 62.9872C524.107 63.0969 524.22 63.2548 524.271 63.4352C533.363 75.1233 575.445 126.907 630.447 158.498C630.642 158.55 630.845 158.57 631.046 158.557C646.072 167.386 662.237 174.172 679.105 178.731C749.774 197.017 824.332 134.413 824.332 134.413L939.918 140.604C939.918 140.604 991.942 373.144 952.674 636.647Z' fill={accentFill} />
      <path d='M521.231 60.8172C521.231 60.8172 506.104 187.111 403.87 265.236C301.635 343.361 154.212 365.163 154.212 365.163L36.8482 356.234C131.283 353.317 221.716 317.926 292.502 256.181C364.181 193.354 403.101 53.483 403.101 53.483L521.231 60.8172Z' fill='black' />
      <path d='M35.8325 371.041L38.1589 341.456L153.722 350.267C169.823 347.619 302.676 324.085 394.777 253.548C490.472 180.324 506.174 60.2898 506.299 59.0493L536.165 62.5267C535.615 67.958 519.107 195.963 413.253 277.011C309.17 356.685 162.782 379.005 156.589 379.887L154.872 380.135L35.8325 371.041Z' fill='black' />
      <path d='M938.662 140.665C938.662 140.665 921.822 182.297 795.339 201.39C733.609 210.638 676.114 188.163 630.087 158.649C645.113 167.478 661.28 174.264 678.146 178.823C748.817 197.109 823.375 134.505 823.375 134.505L938.662 140.665Z' fill='black' />
      <path d='M745.747 217.317C705.177 213.324 663.654 197.848 621.643 171.055L638.007 146.071C691.772 180.343 743.994 194.01 793.147 186.624C905.885 169.578 924.809 134.829 924.964 134.487L924.905 135.077L952.862 146.058C950.776 151.159 929.006 196.122 797.857 215.948C780.614 218.556 763.106 219.016 745.747 217.317Z' fill='black' />
      <path d='M675.647 1142.52C675.647 1142.52 425.303 1170.94 209.695 886.279C63.669 693.443 36.9445 356.481 36.9445 356.481L154.308 365.41C154.308 365.41 184.238 667.09 286.308 829.067C452.605 1092.77 675.647 1142.52 675.647 1142.52Z' fill='black' />
      <path d='M672.351 1157.16C662.956 1155.04 441.235 1102.98 273.556 836.816C170.803 673.817 140.636 379.269 139.358 366.8L169.336 363.789C169.635 366.8 200.085 663.939 299.137 821.088C460.215 1076.65 676.773 1127.78 678.987 1127.99L672.351 1157.16Z' fill='black' />
      <path d='M617.136 1156.37C527.8 1147.58 354.373 1102.01 197.674 895.176C50.7445 701.115 23.1302 371.162 21.9838 357.394L20.6757 341.047L37.3163 341.432C127.882 338.237 214.558 304.311 282.715 245.38C350.129 185.889 388.255 50.8886 388.631 49.5543L391.86 37.9468L529.388 46.4751L535.128 54.2556L535.601 54.9571C544.651 66.4622 585.058 115.696 636.805 145.416L637.942 146.125C651.635 154.139 666.358 160.302 681.716 164.446C743.787 180.514 812.746 123.679 813.344 123.142L817.957 119.363L951.033 126.499L953.405 137.704C953.889 140.077 1001.93 375.027 966.347 638.477C918.925 987.938 693.856 1148.3 684.505 1154.71L681.383 1156.91L677.595 1157.31C657.461 1159.01 637.205 1158.7 617.136 1156.37ZM53.5263 370.576C60.9143 438.563 97.4128 713.52 221.772 877.399C414.874 1132.41 638.431 1129.84 670.162 1128.02C697.581 1107.28 893.796 948.379 936.541 634.41C967.356 408.534 934.979 203.985 926.106 154.872L828.016 149.57C809.476 163.784 741.532 210.582 674.039 193.147C656.035 188.306 638.774 181.092 622.725 171.698C622.092 171.402 621.48 171.063 620.894 170.682C568.081 140.142 527.64 93.0507 513.431 75.1947L414.102 68.9948C402.903 105.041 365.154 212.411 302.658 267.496C233.316 327.881 145.779 364.1 53.5263 370.576Z' fill='black' />
      <path d='M1040.71 913.081C1041.67 916.489 1039.49 919.983 1036 920.613L464.01 1024.12C460.526 1024.75 457.256 1022.24 456.962 1018.71L404.971 395.355C404.714 392.274 406.84 389.503 409.882 388.952L864.12 306.758C867.162 306.207 870.124 308.058 870.963 311.034L1040.71 913.081Z' fill='white' />
      <path d='M1052.41 920.395C1053.37 923.803 1051.19 927.297 1047.7 927.927L455.583 1035.07C452.099 1035.7 448.829 1033.19 448.535 1029.67L395.014 387.65C394.758 384.569 396.883 381.798 399.925 381.248L870.745 296.053C873.787 295.503 876.749 297.354 877.588 300.329L1052.41 920.395ZM465.355 1007.76C465.65 1011.29 468.919 1013.8 472.403 1013.17L1024.31 913.299C1027.79 912.669 1029.97 909.173 1029.01 905.765L864.134 321.622C863.295 318.648 860.333 316.797 857.292 317.348L419.813 396.509C416.771 397.06 414.645 399.832 414.902 402.912L465.355 1007.76Z' fill='black' />
      <path d='M721.379 298.826C725.004 298.17 728.359 300.905 728.446 304.588L730.024 371.133C730.094 374.088 728.002 376.653 725.094 377.18L564.733 406.197C561.825 406.723 558.967 405.053 557.997 402.262L536.156 339.383C534.947 335.903 537.131 332.166 540.756 331.51L577.772 324.812C581.032 324.222 583.197 321.1 582.607 317.839L578.386 294.51C577.796 291.249 579.961 288.127 583.222 287.537L666.197 272.523C669.458 271.933 672.579 274.098 673.169 277.359L677.391 300.688C677.981 303.949 681.103 306.114 684.363 305.524L721.379 298.826Z' fill='black' />
      <path d='M484.803 585.017C481.379 585.715 478.085 583.351 477.651 579.884L462.836 461.656C462.446 458.546 464.516 455.66 467.588 455.032L600.66 427.829C603.998 427.147 607.233 429.377 607.783 432.738L627.005 550.135C627.525 553.316 625.44 556.34 622.282 556.984L484.803 585.017ZM488.133 469.792C485.064 470.422 482.998 473.308 483.388 476.416L493.587 557.682C494.022 561.151 497.319 563.515 500.744 562.813L600.782 542.32C603.934 541.674 606.016 538.657 605.501 535.481L592.403 454.737C591.857 451.369 588.615 449.134 585.272 449.821L488.133 469.792Z' fill='black' />
      <path d='M509.645 760.516C506.213 761.222 502.909 758.85 502.482 755.372L487.939 637.133C487.557 634.032 489.62 631.157 492.681 630.525L628.971 602.386C632.319 601.695 635.567 603.936 636.109 607.311L654.964 724.771C655.473 727.942 653.395 730.952 650.249 731.599L509.645 760.516ZM513.264 645.251C510.203 645.88 508.137 648.754 508.516 651.856L518.461 733.169C518.886 736.646 522.188 739.021 525.62 738.318L629.179 717.111C632.327 716.467 634.408 713.457 633.9 710.284L620.961 629.512C620.421 626.138 617.176 623.896 613.829 624.584L513.264 645.251Z' fill='black' />
      <path d='M532.536 938.312C529.104 939.015 525.802 936.642 525.375 933.165L510.403 811.115C510.022 808.012 512.087 805.137 515.149 804.507L655.86 775.56C659.206 774.872 662.451 777.112 662.993 780.486L682.457 901.72C682.966 904.893 680.885 907.904 677.737 908.549L532.536 938.312ZM535.658 819.243C532.596 819.873 530.531 822.748 530.911 825.851L541.349 910.957C541.776 914.434 545.077 916.808 548.508 916.105L656.543 893.979C659.691 893.334 661.772 890.324 661.264 887.151L647.717 802.602C647.177 799.228 643.932 796.986 640.585 797.674L535.658 819.243Z' fill='black' />
      <path d='M832.628 450.615C832.038 447.355 828.916 445.19 825.656 445.78L653.154 476.994C649.893 477.584 647.728 480.705 648.318 483.966L649.486 490.421C650.076 493.682 653.198 495.847 656.459 495.257L828.96 464.043C832.221 463.453 834.386 460.331 833.796 457.07L832.628 450.615Z' fill='black' />
      {isActive && (
        <>
          <path d='M552.501 530.011C550.382 532.8 546.335 533.189 543.724 530.853L505.644 496.795C503.177 494.588 502.963 490.8 505.166 488.329L509.535 483.431C511.743 480.955 515.54 480.741 518.013 482.952L541.135 503.632C543.747 505.968 547.796 505.578 549.914 502.788L609.698 424.028C611.703 421.387 615.47 420.872 618.109 422.88L623.34 426.858C625.977 428.863 626.49 432.626 624.486 435.263L552.501 530.011Z' fill={accentFill} />
          <path d='M578.683 707.013C576.564 709.802 572.517 710.192 569.906 707.857L529.234 671.489C526.761 669.278 526.551 665.48 528.766 663.01L533.152 658.121C535.362 655.656 539.151 655.448 541.619 657.655L567.276 680.606C569.887 682.941 573.936 682.553 576.054 679.763L640.597 594.781C642.602 592.141 646.369 591.627 649.007 593.634L654.241 597.614C656.877 599.619 657.39 603.381 655.387 606.019L578.683 707.013Z' fill={accentFill} />
        </>
      )}
      <path d='M1092.52 854.593C1095.87 853.135 1097.15 849.039 1095.23 845.929L912.807 551.657C911.294 549.217 908.265 548.19 905.58 549.208L739.091 612.339C737.545 612.925 736.31 614.123 735.676 615.651L689.738 726.409C688.413 729.603 690.055 733.253 693.324 734.38L882.136 799.479C883.794 800.051 885.12 801.32 885.763 802.953L931.052 917.981C932.296 921.141 935.912 922.639 939.026 921.286L1092.52 854.593Z' fill='white' />
      <path d='M933.908 933.645C930.794 934.999 927.178 933.5 925.934 930.341L878.685 810.344C878.043 808.711 876.717 807.442 875.059 806.87L680.974 739.934C677.706 738.807 676.064 735.158 677.388 731.964L728.631 608.401C729.265 606.873 730.5 605.675 732.046 605.089L909.432 537.799C912.118 536.781 915.149 537.808 916.661 540.25L1108.65 850.217C1110.57 853.327 1109.29 857.421 1105.94 858.879L933.908 933.645ZM702.087 720.855C700.761 724.049 702.404 727.699 705.673 728.826L889.205 792.089C890.864 792.661 892.19 793.93 892.833 795.563L936.172 905.63C937.416 908.79 941.031 910.288 944.145 908.935L1078.96 850.368C1082.32 848.91 1083.6 844.814 1081.67 841.704L908.939 563.033C907.426 560.592 904.396 559.565 901.712 560.584L746.136 619.59C744.591 620.176 743.355 621.374 742.722 622.901L702.087 720.855Z' fill='black' />
      <path d='M1156.85 900.987C1158.36 903.935 1157.2 907.554 1154.25 909.068L950.483 1013.68C947.536 1015.2 943.921 1014.04 942.407 1011.09L907.084 942.359C895.699 920.125 934.455 877.465 993.679 847.056C1052.91 816.652 1110.11 810.07 1121.53 832.299L1156.85 900.987Z' fill='white' />
      <path d='M946.556 1026.16C943.61 1027.67 939.994 1026.51 938.479 1023.56L898.905 946.592C882.755 915.075 933.632 867.506 989.512 838.798C1019.09 823.596 1049.3 813.608 1074.5 810.705C1102.78 807.474 1122.45 813.61 1129.85 828.04L1169.35 904.986C1170.86 907.933 1169.7 911.549 1166.75 913.063L946.556 1026.16ZM1067.2 830.55C1043.05 835.449 1019.74 843.783 997.968 855.297C936.674 886.796 908.677 924.718 915.447 938.129L946.489 998.608C948.002 1001.56 951.621 1002.72 954.569 1001.21L1141.72 905.036C1144.66 903.521 1145.83 899.903 1144.31 896.956L1113.35 836.724C1109.84 829.813 1095.82 827.145 1076.63 829.329C1073.53 829.514 1070.41 829.968 1067.2 830.55Z' fill='black' />
      <path d='M1077.25 1009.94C1136.47 979.55 1175.23 936.89 1163.82 914.661C1152.41 892.43 1095.15 899.044 1035.93 929.431C976.715 959.825 937.951 1002.49 949.365 1024.71C960.773 1046.95 1018.03 1040.34 1077.25 1009.94Z' fill='black' />
      <path d='M1006.85 1044.67C1003.27 1045.32 999.782 1045.84 996.377 1046.23C968.074 1049.5 948.447 1043.36 941.043 1028.93C924.858 997.419 975.77 949.844 1031.61 921.144C1087.45 892.443 1155.99 878.871 1171.99 910.378C1188.14 941.894 1137.25 989.428 1081.42 1018.16C1057.96 1030.52 1032.84 1039.45 1006.85 1044.67ZM1109.58 912.844C1109.55 912.897 1109.49 912.934 1109.43 912.947C1085.24 917.841 1061.88 926.226 1040.1 937.828C978.796 969.291 950.807 1007.21 957.576 1020.66C961.094 1027.57 975.115 1030.24 994.288 1028.01C1017.35 1025.35 1045.28 1016.07 1072.96 1001.86C1134.26 970.395 1162.25 932.474 1155.48 919.026C1151.7 911.27 1134.82 908.218 1109.75 912.74C1109.68 912.751 1109.62 912.789 1109.58 912.844V912.844Z' fill='black' />
      <path d='M925.607 535.682C923.478 532.977 919.516 532.614 916.931 534.886L676.867 745.909C676.428 746.295 676.047 746.744 675.738 747.24L624.745 828.981C621.623 833.986 626.841 840.02 632.244 837.652L716.028 800.935C716.548 800.707 717.034 800.406 717.469 800.042L963.997 593.874C966.486 591.793 966.87 588.11 964.863 585.56L925.607 535.682Z' fill={accentFill} />
      <path d='M608.465 858.189C603.064 860.554 597.849 854.526 600.965 849.521L668.421 741.209C668.731 740.711 669.112 740.262 669.552 739.875L917.973 521.413C920.554 519.143 924.509 519.501 926.641 522.196L977.796 586.873C979.812 589.422 979.432 593.113 976.939 595.198L722.493 807.997C722.056 808.361 721.571 808.662 721.05 808.89L608.465 858.189ZM684.043 752.003C683.603 752.389 683.223 752.837 682.913 753.333L648.512 808.448C645.389 813.451 650.6 819.484 656.004 817.124L711.16 793.026C711.683 792.797 712.17 792.496 712.607 792.13L951.207 592.604C953.696 590.523 954.08 586.84 952.073 584.29L924.426 549.171C922.297 546.467 918.335 546.104 915.751 548.375L684.043 752.003Z' fill='black' />
      <path d='M687.221 745.692C685.231 743.043 681.469 742.509 678.82 744.499L673.576 748.439C670.926 750.429 670.392 754.191 672.382 756.84L705.819 801.346C707.81 803.996 711.571 804.53 714.22 802.539L719.464 798.6C722.113 796.609 722.648 792.848 720.657 790.199L687.221 745.692Z' fill='black' />
      <path d='M837.831 723.151C841.809 719.496 839.308 712.856 833.907 712.734L760.251 711.068C758.584 711.03 756.977 711.688 755.814 712.883L730.557 738.85C727.621 741.868 728.627 746.894 732.498 748.55L781.943 769.699C784.112 770.627 786.624 770.197 788.362 768.601L837.831 723.151Z' fill='white' />
      <path d='M807.74 777.316C806.658 780.447 803.244 782.108 800.113 781.027L716.545 752.19C712.389 750.755 711.136 745.485 714.203 742.333L751.84 703.658C752.854 702.616 754.213 701.978 755.662 701.862L892.83 690.921C896.13 690.658 899.02 693.117 899.287 696.417L899.819 702.969C900.087 706.275 897.622 709.172 894.315 709.435L764.077 719.804C762.627 719.919 761.268 720.557 760.254 721.6L747.874 734.317C744.806 737.468 746.059 742.74 750.216 744.174L806.172 763.48C809.306 764.562 810.969 767.98 809.886 771.113L807.74 777.316Z' fill='black' />
      <path d='M281.939 988.673C277.921 989.456 274.315 986.074 274.84 982.014L313.148 685.756C313.286 684.687 313.71 683.674 314.375 682.825L408.487 562.716C411.852 558.422 418.736 560.482 419.189 565.917L423.773 620.83C424.214 626.124 430.81 628.275 434.288 624.259L490.201 559.685C493.33 556.072 499.222 557.389 500.514 561.991L510.611 597.963C510.988 599.306 510.885 600.739 510.32 602.014L436.797 767.965C436.39 768.885 436.22 769.892 436.303 770.894L451.244 950.224C451.498 953.273 449.417 956.026 446.413 956.611L281.939 988.673Z' fill='white' />
      <path d='M271.094 1000.25C267.075 1001.03 263.47 997.651 263.995 993.591L304.288 682.041C304.426 680.971 304.85 679.958 305.515 679.11L415.777 538.386C419.141 534.093 426.025 536.152 426.479 541.588L431.199 598.098C431.641 603.392 438.238 605.542 441.715 601.526L494.473 540.583C497.602 536.969 503.495 538.287 504.786 542.89L520.432 598.675C520.809 600.018 520.706 601.451 520.141 602.726L446.209 769.545C445.802 770.464 445.632 771.47 445.715 772.471L461.058 957.764C461.311 960.813 459.229 963.563 456.227 964.148L271.094 1000.25ZM323.266 686.535C322.602 687.384 322.178 688.396 322.039 689.465L285.686 970.471C285.161 974.53 288.764 977.911 292.781 977.131L436.502 949.199C439.508 948.615 441.592 945.861 441.337 942.81L426.864 769.397C426.78 768.394 426.95 767.386 427.358 766.466L500.513 601.414C501.079 600.138 501.181 598.703 500.803 597.359L496.256 581.189C494.963 576.591 489.077 575.273 485.948 578.882L426.855 647.011C423.376 651.022 416.785 648.87 416.343 643.578L411.896 590.252C411.442 584.816 404.557 582.757 401.193 587.051L323.266 686.535Z' fill='black' />
      <path d='M253.426 1031.69C253.896 1034.97 256.935 1037.25 260.214 1036.78L486.899 1004.35C490.179 1003.89 492.458 1000.85 491.989 997.566L481.053 921.072C477.524 896.36 421.224 883.953 355.335 893.386C289.445 902.819 238.938 930.555 242.473 955.302L253.426 1031.69Z' fill='white' />
      <path d='M502.519 1005.45C502.981 1008.73 500.703 1011.76 497.427 1012.23L252.35 1047.29C249.07 1047.76 246.03 1045.48 245.561 1042.2L233.313 956.581C230.973 940.521 243.476 924.15 268.418 910.433C290.688 898.218 321.104 888.905 354.195 884.161C387.286 879.418 418.961 879.986 443.763 885.306C471.57 891.591 488.13 903.683 490.427 919.714L502.519 1005.45ZM261.29 1021.22C261.766 1024.49 264.802 1026.77 268.078 1026.3L476.408 996.483C479.694 996.013 481.974 992.965 481.496 989.681L471.717 922.422C470.687 915.062 458.293 907.648 439.439 903.515C416.728 898.497 387.29 898.203 356.513 902.603C325.737 907.002 297.563 915.57 277.182 926.765C260.295 936.007 250.566 946.443 251.525 954.041L261.29 1021.22Z' fill='black' />
      <path d='M496.173 1015.53C492.706 990.756 436.415 978.164 370.44 987.393C304.467 996.629 253.794 1024.19 257.261 1048.96C260.726 1073.72 317.019 1086.32 382.992 1077.09C448.966 1067.86 499.638 1040.29 496.173 1015.53Z' fill='black' />
      <path d='M394.011 1081.9C390.358 1082.56 386.413 1083.27 382.611 1083.73C349.52 1088.47 317.846 1087.91 293.079 1082.58C265.236 1076.3 248.714 1064.21 246.417 1048.18C241.396 1013.14 304.917 984.776 367.158 975.818C400.249 971.074 431.925 971.644 456.691 976.969C484.533 983.246 501.057 995.348 503.354 1011.38C508.063 1044.28 452.378 1071.33 394.011 1081.9ZM357.852 996.098C297.037 1007.1 262.761 1031.56 264.761 1045.53C265.86 1053.07 278.221 1060.3 297.081 1064.47C319.75 1069.46 349.194 1069.79 380 1065.34C448.175 1055.61 487.124 1028.83 485.001 1013.98C483.935 1006.63 471.542 999.216 452.688 995.082C430.02 990.092 400.576 989.766 369.799 994.164C365.718 994.748 361.724 995.397 357.852 996.098Z' fill='black' />
      <path d='M422.404 632.958C419.823 630.88 416.046 631.287 413.967 633.868L390.414 663.117C388.336 665.698 388.744 669.475 391.324 671.553L396.433 675.667C399.014 677.746 402.791 677.338 404.869 674.757L428.422 645.508C430.501 642.927 430.093 639.15 427.512 637.072L422.404 632.958Z' fill='black' />
    </svg>
  );
}
