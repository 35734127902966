import type { ReactElement } from 'react';
import {
  type ThemeStyleProps,
  AUTHENTIC_BRAND_NAME,
  AUTHENTIC_WEBSITE_URL,
  useTheme,
  Box,
  Text,
  Link,
  Image
} from '@authenticins/react-ui';

export function Footer(themeStyleProps: ThemeStyleProps): ReactElement {
  const theme = useTheme();

  return (
    <Box
      align='center'
      column
      w='100%'
      h='56px'
      px={2}
      opacity={0.5}
      {...themeStyleProps}
    >
      <Link c='text' underline={false} href={AUTHENTIC_WEBSITE_URL} openInNewTab>
        Powered by
        <Image
          w='125px'
          mt={-0.5}
          ml={-0.5}
          src={`${AUTHENTIC_WEBSITE_URL}/img/authentic/logo/horizontal/horizontal-onecolor-${theme.isDarkMode ? 'white' : 'black'}.png`}
          alt={`${AUTHENTIC_BRAND_NAME} logo`}
        />
      </Link>
      <Text fontSize='sm' textAlign='center'>
        All insurance services are provided by {AUTHENTIC_BRAND_NAME} Services, Inc. (NPN: 20619648)
      </Text>
    </Box>
  );
}
