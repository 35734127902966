import { type ReactElement } from 'react';
import {
  AUTHENTIC_SUPPORT_EMAIL,
  formatPhoneNumber,
  Text,
  Link,
  Icon
} from '@authenticins/react-ui';

import { type ApplicationPageProps, ApplicationPage } from '.';

type DeclinePageProps = Pick<ApplicationPageProps, 'isActive' | 'customerIndustry'> & {
  supportPhoneNumber: number;
};

export function DeclinePage({
  supportPhoneNumber,
  isActive = false,
  customerIndustry
}: DeclinePageProps): ReactElement {
  return (
    <ApplicationPage isActive={isActive} customerIndustry={customerIndustry}>
      <Icon name='RiEmotionSadLine' size={4} c='subtle' />
      <Text variant='heading' mt={2} textAlign='center'>
        Sorry! Looks like we can't cover your business yet
      </Text>
      <Text mt={1.5} textAlign='center'>
        We're constantly working to expand our program to offer coverage for a business like yours,
        and we want to thank you for your interest in buying from us.
      </Text>
      <Link buttonVariant='primary' w='100%' mt={4} href={`tel:${supportPhoneNumber}`}>
        <Icon name='RiPhoneLine' mr={0.5} />
        {formatPhoneNumber(supportPhoneNumber)}
      </Link>
      <Link mt={1.5} href={`mailto:${AUTHENTIC_SUPPORT_EMAIL}`}>
        <Icon name='RiMailLine' mr={1} />
        {AUTHENTIC_SUPPORT_EMAIL}
      </Link>
    </ApplicationPage>
  );
}
