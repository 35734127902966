import type { ReactElement } from 'react';
import { useTheme, useThemeStyles } from '@authenticins/react-ui';

import type { ProductIconProps } from '.';

export function ProfessionalLiabilityIcon({ isActive = true, size = 4, ...themeStyleProps }: Omit<ProductIconProps, 'productName'>): ReactElement {
  const theme = useTheme();
  const themeStyles = useThemeStyles(themeStyleProps);
  const sizePx = (typeof size === 'number' ? size * theme.font.size.base : theme.font.size[size]) + 'px';
  const accentFill = isActive ? theme.palette.primary : theme.palette.muted;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1250 1250'
      fill='none'
      style={{
        ...themeStyles,
        minWidth: sizePx,
        width: sizePx,
        minHeight: sizePx,
        height: sizePx
      }}
    >
      <path d='M833.862 978.192C832.581 979.168 830.961 979.589 829.366 979.358L53.0081 867.153C47.7451 866.392 45.9996 859.668 50.2283 856.443L316.544 653.377C317.825 652.4 319.446 651.98 321.04 652.21L1097.4 764.463C1102.66 765.224 1104.41 771.949 1100.18 775.173L833.862 978.192Z' fill='white' />
      <path d='M56.1449 1010.38C54.8702 1010.2 53.6886 1009.61 52.7749 1008.7L27.8636 983.958C25.5084 981.619 25.5003 977.812 27.8455 975.463L47.7608 955.512C50.8021 952.465 49.7403 947.302 45.7437 945.703L32.8761 940.553C28.6718 938.871 27.7755 933.315 31.2374 930.396L65.8698 901.192C67.4762 899.838 68.2569 897.741 67.9279 895.666L64.9299 876.754C64.2955 872.752 67.7284 869.285 71.7364 869.879L880.756 989.899C883.096 990.246 885.013 991.936 885.652 994.213L895.668 1029.92C896.299 1032.16 895.569 1034.57 893.797 1036.09L868.887 1057.45C865.267 1060.56 866.541 1066.44 871.122 1067.77L886.952 1072.36C891.336 1073.63 892.751 1079.14 889.523 1082.37L849.303 1122.58C847.965 1123.92 846.074 1124.55 844.202 1124.28L56.1449 1010.38Z' fill='white' />
      <path d='M1094.56 779.479C1095.88 778.478 1097.55 778.062 1099.18 778.331L1151.69 786.986C1153.22 787.238 1154.59 788.069 1155.52 789.308L1178.03 819.343C1179.9 821.839 1179.55 825.353 1177.22 827.426L1142.41 858.369C1140.37 860.178 1139.81 863.132 1141.04 865.563L1153.09 889.366C1154.4 891.965 1153.66 895.132 1151.33 896.878L897.193 1087.24C892.763 1090.56 887.453 1084.44 891.368 1080.52V1080.52C893.766 1078.12 892.715 1074.03 889.457 1073.09L871.122 1067.77C866.541 1066.44 865.267 1060.56 868.887 1057.45L893.797 1036.09C895.569 1034.57 896.299 1032.16 895.668 1029.91L885.591 993.996C884.984 991.831 883.216 990.187 881.013 989.738L844.81 982.359C839.76 981.329 838.272 974.832 842.371 971.708L1094.56 779.479Z' fill='white' />
      <path d='M837.247 991.212C835.957 992.195 834.325 992.615 832.721 992.376L830.273 992.01L21.8316 875.185C16.568 874.424 14.8228 867.699 19.0523 864.475L313.009 640.402C314.299 639.418 315.932 638.999 317.537 639.24L319.984 639.607L1128.04 756.533C1133.3 757.294 1135.04 764.017 1130.82 767.241L837.247 991.212ZM81.2114 848.415C76.9827 851.64 78.7278 858.364 83.9908 859.125L825.797 966.37C827.391 966.6 829.012 966.18 830.293 965.203L1069 783.205C1073.23 779.981 1071.48 773.256 1066.22 772.495L324.411 665.249C322.817 665.018 321.196 665.439 319.915 666.416L81.2114 848.415Z' fill='black' />
      <path d='M853.667 1135.73C852.329 1137.06 850.441 1137.69 848.57 1137.42L50.4449 1022.03C49.1696 1021.85 47.9875 1021.26 47.0736 1020.35L10.5058 984.004C8.15388 981.667 8.1443 977.864 10.4844 975.515L25.916 960.021C28.9515 956.973 27.8882 951.816 23.8949 950.217L9.27999 944.366C5.0758 942.683 4.18045 937.127 7.64304 934.208L52.6808 896.245C54.2889 894.889 55.0698 892.791 54.7391 890.714L50.1644 861.98C49.527 857.977 52.9608 854.506 56.9707 855.102L890.677 978.85C893.016 979.197 894.933 980.886 895.572 983.163L909.889 1034.18C910.519 1036.42 909.79 1038.83 908.02 1040.35L894.574 1051.89C890.953 1055 892.234 1060.89 896.819 1062.21L910.712 1066.22C915.104 1067.49 916.522 1073 913.287 1076.23L853.667 1135.73ZM58.5765 997.16C59.4894 998.063 60.6679 998.649 61.9387 998.833L839.837 1111.15C841.711 1111.42 843.603 1110.79 844.941 1109.45L865.939 1088.42C869.161 1085.19 867.745 1079.69 863.364 1078.42L845.643 1073.27C841.062 1071.94 839.788 1066.06 843.409 1062.96L879.717 1031.83C881.489 1030.31 882.219 1027.9 881.589 1025.66L875.892 1005.33C875.253 1003.05 873.335 1001.36 870.995 1001.01L86.6324 884.604C82.6171 884.008 79.1812 887.488 79.8284 891.496L81.2992 900.603C81.6364 902.691 80.8493 904.802 79.2274 906.16L54.8451 926.572C51.364 929.487 52.2554 935.062 56.4717 936.745L67.5014 941.149C71.4997 942.746 72.5651 947.909 69.5253 950.958L45.1501 975.405C42.8039 977.758 42.817 981.57 45.1793 983.907L58.5765 997.16Z' fill='black' />
      <path d='M859.082 1131.28C856.563 1133.17 853.015 1132.79 850.953 1130.41L842.065 1120.16C840.002 1117.78 840.128 1114.21 842.355 1111.99L865.925 1088.42C869.153 1085.19 867.738 1079.68 863.355 1078.41L845.643 1073.27C841.063 1071.94 839.789 1066.06 843.41 1062.96L879.72 1031.83C881.49 1030.31 882.221 1027.9 881.594 1025.66L875.726 1004.65C875.122 1002.49 873.36 1000.85 871.16 1000.39L815.566 988.923C810.525 987.883 809.048 981.394 813.142 978.274L1091.24 766.346C1092.56 765.344 1094.23 764.929 1095.86 765.199L1158.58 775.584C1160.11 775.837 1161.48 776.67 1162.41 777.91L1194.74 821.135C1196.61 823.632 1196.25 827.142 1193.92 829.213L1157.73 861.39C1155.69 863.2 1155.13 866.153 1156.36 868.583L1168.81 893.187C1170.12 895.786 1169.38 898.953 1167.05 900.699L859.082 1131.28ZM894.371 1051.91C890.748 1055.01 892.017 1060.9 896.596 1062.23L903.335 1064.19C905.147 1064.72 907.1 1064.37 908.61 1063.23L1135.58 893.202C1137.91 891.459 1138.65 888.3 1137.35 885.703L1125.71 862.579C1124.49 860.15 1125.06 857.204 1127.09 855.398L1160.45 825.749C1162.79 823.676 1163.14 820.159 1161.27 817.663L1148.58 800.754C1147.65 799.52 1146.28 798.689 1144.76 798.437L1102.45 791.422C1100.82 791.152 1099.15 791.567 1097.83 792.57L871.597 965.021C867.5 968.143 868.984 974.636 874.029 975.67L890.937 979.136C893.139 979.587 894.903 981.23 895.51 983.394L909.748 1034.17C910.379 1036.42 909.647 1038.83 907.874 1040.35L894.371 1051.91Z' fill='black' />
      <path d='M76.368 887.783C73.0973 887.251 70.015 889.472 69.4834 892.742L67.4327 905.358C66.901 908.629 69.1217 911.711 72.3926 912.243L888.781 1044.91C892.051 1045.44 895.133 1043.22 895.665 1039.95L897.718 1027.33C898.25 1024.06 896.03 1020.98 892.758 1020.45L76.368 887.783Z' fill='black' />
      <path d='M1150.2 782.195C1148.21 779.549 1144.45 779.021 1141.8 781.016L881.972 976.878C879.327 978.873 878.798 982.634 880.792 985.28L888.49 995.497C890.485 998.143 894.248 998.672 896.894 996.677L1156.72 800.808C1159.37 798.813 1159.89 795.051 1157.9 792.405L1150.2 782.195Z' fill='black' />
      <path d='M865.654 1076.23C864.39 1077.15 862.815 1077.54 861.265 1077.32L58.3362 962.181C55.0561 961.71 52.7783 958.67 53.2487 955.39L55.0643 942.728C55.5347 939.448 58.5751 937.17 61.8552 937.641L854.825 1051.34C856.375 1051.56 857.95 1051.17 859.214 1050.25L1126.83 854.915C1129.5 852.962 1133.26 853.548 1135.21 856.223L1142.75 866.552C1144.71 869.229 1144.12 872.983 1141.44 874.937L865.654 1076.23Z' fill='black' />
      {isActive && (
        <>
          <path d='M533.835 843.989C700.756 843.989 836.073 819.289 836.073 788.816C836.073 758.345 700.756 733.643 533.835 733.643C366.914 733.643 231.598 758.345 231.598 788.816C231.598 819.289 366.914 843.989 533.835 843.989Z' fill={accentFill} />
          <path d='M533.835 856.329C520.996 856.329 219.205 855.589 219.205 788.816C219.205 722.043 520.996 721.25 533.835 721.25C546.674 721.25 848.465 722.043 848.465 788.816C848.465 855.589 546.674 856.329 533.835 856.329ZM246.02 786.422C244.618 787.773 244.618 789.859 246.021 791.21C262.462 807.044 362.828 831.549 533.835 831.549C704.843 831.549 805.21 807.044 821.65 791.21C823.052 789.859 823.052 787.773 821.65 786.422C805.211 770.583 704.843 746.036 533.835 746.036C362.827 746.036 262.461 770.583 246.02 786.422Z' fill='black' />
        </>
      )}
      <path d='M797.935 589.304C801.23 589.652 803.619 592.606 803.271 595.901L786.585 753.894C786.566 754.076 786.412 754.215 786.229 754.215V754.215C786.046 754.215 785.894 754.349 785.862 754.53C782.648 772.694 671.662 775.944 536.859 761.651C401.993 747.35 294.155 720.983 294.929 702.457C294.935 702.299 294.812 702.165 294.653 702.165V702.165C294.494 702.165 294.371 702.027 294.387 701.869L311.123 543.851C311.472 540.556 314.426 538.167 317.721 538.516L797.935 589.304Z' fill='black' />
      <path d='M687.904 782.867C637.007 782.457 586.164 779.463 535.571 773.895C476.957 768.146 418.773 758.642 361.376 745.441C304.192 731.389 286.292 719.738 283.096 707.51C282.994 707.121 282.866 706.741 282.721 706.365C282.035 704.591 281.79 702.672 282.012 700.777L299.412 536.497C299.579 534.877 300.065 533.306 300.841 531.875C301.618 530.443 302.67 529.18 303.937 528.157C305.204 527.134 306.661 526.372 308.224 525.914C309.787 525.457 311.426 525.314 313.044 525.492L805.24 577.592C808.506 577.941 811.5 579.574 813.564 582.13C815.627 584.686 816.592 587.957 816.244 591.224L798.845 755.653C798.644 757.578 797.998 759.426 796.962 761.055C796.77 761.357 796.592 761.665 796.433 761.985C790.806 773.27 771.037 780.909 711.847 782.669C704.262 782.768 696.232 782.867 687.904 782.867ZM307.107 699.613C307.138 699.862 307.267 700.055 307.454 700.222C320.956 712.284 405.629 735.223 538.148 749.258C670.524 763.278 758.186 758.601 774.052 749.47C774.292 749.332 774.406 749.09 774.406 748.812V748.812L789.487 606.709C789.836 603.414 787.448 600.459 784.153 600.109L328.539 551.804C325.24 551.454 322.283 553.848 321.939 557.148L307.109 699.363C307.1 699.446 307.097 699.53 307.107 699.613V699.613Z' fill='black' />
      <path d='M803.179 589.779C805.132 571.32 696.825 544.727 561.269 530.384C425.713 516.041 314.239 519.377 312.286 537.836C310.333 556.295 418.64 582.887 554.196 597.23C689.752 611.573 801.226 608.238 803.179 589.779Z' fill='black' />
      <path d='M705.353 618.638C654.438 618.233 603.577 615.221 552.97 609.616C494.357 603.867 436.172 594.363 378.776 581.162C311.26 564.605 298.47 551.419 300.057 536.548C301.643 521.676 316.861 511.514 386.36 509.432C445.328 508.528 504.293 511.442 562.884 518.157C621.499 523.898 679.684 533.402 737.079 546.611C804.595 563.168 817.384 576.354 815.848 591.225C814.261 606.096 798.993 616.259 729.494 618.341C721.513 618.489 713.681 618.638 705.353 618.638ZM346.149 537.019C340.706 537.739 340.061 543.965 345.236 545.798C379.974 558.104 453.554 574.182 555.597 584.979C657.371 595.748 732.595 595.364 769.194 590.693C774.67 589.994 775.343 583.729 770.138 581.891C735.313 569.596 661.871 553.566 560.059 542.794C458.037 531.999 382.659 532.194 346.149 537.019Z' fill='black' />
      <path d='M740.26 578.398C741.763 579.705 742.648 581.805 741.32 583.288C729.904 596.034 649.233 598.44 552.623 588.201C455.985 577.958 377.592 558.743 369.146 543.85C368.166 542.121 369.469 540.257 371.205 539.29C375.332 536.99 379.362 534.52 383.287 531.888C424.53 505.02 464.882 457.531 493.435 397.103C513.977 355.041 525.973 309.345 528.754 262.659C528.96 259.2 531.993 256.578 535.439 256.943L638.329 267.842C641.769 268.207 644.185 271.397 643.669 274.819C636.705 321.043 638.927 368.208 650.229 413.61C665.547 478.698 694.844 533.623 729.544 568.472C733.179 572.026 736.715 575.317 740.26 578.398Z' fill={accentFill} />
      <path d='M662.027 606.938C625.011 606.674 588.032 604.523 551.235 600.494C506.368 596.112 461.86 588.609 418.036 578.038C371.737 566.141 354.337 555.334 356.022 539.471L356.379 536.083C356.589 534.082 357.789 532.319 359.574 531.39L362.615 529.805C367.573 527.326 372.034 524.55 376.694 521.477C417.541 494.857 456.008 447.615 482.43 391.748C503.088 349.308 514.814 303.078 516.882 255.922L517.036 248.952C517.113 245.445 520.169 242.752 523.657 243.116L653.099 256.637C656.577 257 659.009 260.249 658.376 263.689L657.07 270.794C649.257 317.344 651.045 365 662.325 410.833C676.502 471.063 704.262 525.343 738.516 559.796C742.482 563.811 746.547 567.529 750.611 570.9L753.185 573.113C754.678 574.397 755.446 576.332 755.24 578.291L754.875 581.756C753.189 597.619 733.906 604.559 686.168 606.542C678.733 606.938 670.603 606.938 662.027 606.938ZM390.326 542.148C389.901 542.484 389.967 543.142 390.468 543.35C412.658 552.583 467.173 566.641 554.011 575.807C640.774 584.967 697.041 582.731 720.694 578.322C721.224 578.223 721.398 577.576 721.017 577.195V577.195C683.095 539.075 653.699 481.968 638.282 416.435C627.784 373.598 624.939 329.259 629.863 285.463C630.24 282.117 627.845 279.078 624.497 278.722L547.188 270.503C543.835 270.146 540.852 272.622 540.523 275.977C536.215 319.848 524.154 362.624 504.886 402.307C476.58 462.189 434.841 513.149 390.326 542.148V542.148Z' fill='black' />
      <path d='M602.492 272.132C674.331 272.132 732.568 213.895 732.568 142.057C732.568 70.2185 674.331 11.9814 602.492 11.9814C530.654 11.9814 472.417 70.2185 472.417 142.057C472.417 213.895 530.654 272.132 602.492 272.132Z' fill={accentFill} />
      <path d='M602.542 284.724C597.571 284.651 592.607 284.32 587.67 283.733C550.819 279.329 517.145 260.71 493.821 231.841C470.497 202.972 459.369 166.137 462.806 129.183C466.243 92.2297 483.975 58.0786 512.223 34.0076C540.471 9.93564 577.003 -2.15336 614.034 0.31564C651.065 2.78364 685.668 19.6156 710.471 47.2236C735.273 74.8316 748.315 111.034 746.817 148.117C745.318 185.2 729.398 220.232 702.449 245.75C675.5 271.267 639.651 285.251 602.542 284.724ZM602.144 24.4752C602.081 24.5376 601.996 24.5727 601.908 24.573C579.218 24.6405 557.029 31.2647 538.017 43.6516C518.965 56.0636 503.917 73.7286 494.692 94.5116C485.467 115.294 482.46 138.305 486.035 160.76C489.611 183.215 499.615 204.154 514.838 221.044C530.061 237.934 549.851 250.053 571.815 255.934C593.78 261.815 616.978 261.207 638.604 254.184C660.23 247.161 679.359 234.023 693.676 216.359C707.994 198.694 716.888 177.261 719.283 154.649C722.559 123.624 713.381 92.5666 693.768 68.3056C674.154 44.0446 645.71 28.5636 614.687 25.2666C610.604 24.7615 606.499 24.4645 602.386 24.3776C602.295 24.3756 602.208 24.4109 602.144 24.4752V24.4752Z' fill='black' />
      <path d='M459.136 227.494C460.545 225.001 463.519 223.852 466.239 224.749L579.027 261.955C582.576 263.126 584.202 267.221 582.424 270.508L568.176 296.847C566.99 299.039 564.573 300.269 562.103 299.939L437.092 283.223C432.882 282.661 430.574 278.021 432.664 274.324L459.136 227.494Z' fill='white' />
      <path d='M581.389 321.851C584.012 322.554 585.836 324.931 585.836 327.646V355.494C585.836 358.89 583.02 361.61 579.626 361.491L408.73 355.501C404.302 355.346 401.564 350.61 403.639 346.695L435.032 287.459C436.342 284.988 439.185 283.749 441.886 284.473L581.389 321.851Z' fill='white' />
      <path d='M555.439 361.486C558.514 361.612 560.995 364.044 561.182 367.116L563.429 403.928C563.643 407.432 560.816 410.371 557.307 410.292L410.622 407.031C406.839 406.947 404.078 403.426 404.897 399.733L413.578 360.611C414.208 357.775 416.779 355.797 419.682 355.916L555.439 361.486Z' fill='white' />
      <path d='M535.611 410.305C538.514 410.382 540.946 412.527 541.384 415.398L545.053 439.449C545.562 442.784 543.221 445.883 539.874 446.306L443.067 458.552C440.067 458.931 437.253 457.018 436.502 454.088L426.502 415.061C425.514 411.202 428.491 407.469 432.473 407.574L535.611 410.305Z' fill='white' />
      <path d='M575.017 310.281C573.831 312.472 571.414 313.703 568.944 313.373L417.266 293.088C413.055 292.525 410.748 287.884 412.84 284.186L453.384 212.523C454.794 210.031 457.769 208.882 460.488 209.781L596.975 254.864C600.523 256.036 602.148 260.13 600.37 263.417L575.017 310.281ZM452.447 264.457C450.355 268.155 452.663 272.795 456.874 273.358L555.257 286.504C557.73 286.835 560.148 285.601 561.333 283.406L564.442 277.642C566.215 274.356 564.587 270.266 561.041 269.096L471.988 239.719C469.269 238.822 466.296 239.971 464.886 242.463L452.447 264.457Z' fill='black' />
      <path d='M598.229 368.333C598.229 371.73 595.413 374.449 592.019 374.33L388.507 367.199C384.079 367.044 381.34 362.307 383.417 358.392L428.738 272.929C430.047 270.461 432.886 269.222 435.585 269.942L593.775 312.138C596.402 312.839 598.229 315.218 598.229 317.936V368.333ZM423.88 334.99C421.799 338.906 424.537 343.647 428.968 343.803L567.233 348.652C570.627 348.771 573.443 346.051 573.443 342.655V337.167C573.443 334.45 571.617 332.072 568.992 331.371L448.224 299.092C445.526 298.371 442.687 299.607 441.376 302.073L423.88 334.99Z' fill='black' />
      <path d='M576.64 416.642C576.84 420.137 574.018 423.061 570.517 422.984L395.254 419.124C391.472 419.041 388.71 415.521 389.529 411.827L403.715 347.821C404.343 344.985 406.914 343.006 409.817 343.125L567.365 349.54C570.448 349.666 572.934 352.11 573.111 355.191L576.64 416.642ZM420.278 387.678C419.452 391.373 422.214 394.901 426 394.985L544.117 397.603C547.628 397.681 550.455 394.74 550.239 391.235L549.486 379.016C549.297 375.943 546.813 373.512 543.736 373.39L429.532 368.847C426.635 368.732 424.071 370.705 423.438 373.534L420.278 387.678Z' fill='black' />
      <path d='M433.76 472.433C430.754 472.812 427.937 470.89 427.194 467.953L410.612 402.455C409.636 398.599 412.613 394.878 416.589 394.985L546.566 398.456C549.47 398.534 551.9 400.679 552.338 403.55L559.477 450.402C559.986 453.738 557.644 456.838 554.295 457.259L433.76 472.433ZM448.537 420.663C444.554 420.556 441.575 424.291 442.565 428.15L445.771 440.656C446.522 443.585 449.335 445.498 452.335 445.118L525.712 435.845C529.053 435.422 531.392 432.334 530.894 429.003L530.714 427.806C530.283 424.928 527.849 422.774 524.94 422.697L448.537 420.663Z' fill='black' />
      <path d='M924.013 383.569C920.237 383.723 917.264 380.39 917.753 376.643C921.346 349.145 921.202 321.276 917.322 293.795C914.424 266.544 907.913 239.81 897.97 214.292C896.697 211.026 898.71 207.379 902.166 206.793V206.793C905.197 206.28 908.054 208.371 908.479 211.416L931.576 376.667C932.067 380.179 929.421 383.348 925.878 383.493L924.013 383.569Z' fill='white' />
      <path d='M865.272 176.857C865.252 176.707 865.282 176.556 865.372 176.429C865.452 176.302 865.572 176.208 865.712 176.163L971.752 147.511C992.122 144.635 1016.66 198.619 1026.52 268.118C1036.39 337.617 1027.81 396.26 1007.44 399.135L896.002 419.906C895.922 419.92 895.842 419.918 895.772 419.901C895.692 419.884 895.622 419.851 895.552 419.805C895.492 419.758 895.432 419.7 895.392 419.632C895.352 419.564 895.322 419.489 895.312 419.41L865.272 176.857Z' fill='white' />
      <path d='M896.052 432.299C892.882 432.297 889.812 431.13 887.432 429.02C885.062 426.91 883.542 424.003 883.162 420.848L853.122 178.394C852.722 175.258 853.472 172.083 855.242 169.464C857.022 166.845 859.682 164.961 862.742 164.167L970.162 135.266C986.372 132.937 1001.34 146.519 1014.78 175.568C1026.88 204.604 1034.95 235.159 1038.77 266.383C1043.7 297.546 1044.31 329.242 1040.55 360.569C1035.99 391.948 1025.68 409.099 1009.47 411.578L898.268 432.285C897.687 432.393 897.094 432.414 896.507 432.349L896.052 432.299ZM883.981 184.222C881.096 185.002 879.225 187.786 879.593 190.752L905.428 399.202C905.853 402.633 909.082 404.995 912.48 404.363L1005.06 387.128C1005.55 387.036 1006.04 386.901 1006.44 386.604C1014.24 380.896 1023.88 336.76 1014.38 269.952C1005.23 205.471 984.886 166.223 975.153 160.56C974.264 160.043 973.21 160.094 972.217 160.362L883.981 184.222Z' fill='black' />
      <path d='M917.447 378.997C917.048 381.831 914.662 383.95 911.802 384.066L864.182 385.999L817.68 387.872C815.949 387.942 814.333 388.756 813.247 390.106L775.826 436.618C775.002 437.642 773.863 438.368 772.586 438.682L671.228 463.64C668.189 464.389 665.099 462.691 664.165 459.703C660.75 448.775 652.665 421.734 644.925 387.189C635.934 347.06 642.852 285.202 644.567 271.473C644.788 269.699 645.806 268.169 647.327 267.229L654.636 262.716C658.826 260.129 658.287 253.875 653.717 252.042L570.717 218.758C568.914 218.034 567.572 216.482 567.117 214.593L559.827 184.305C558.883 180.381 562.003 176.663 566.031 176.912L747.778 188.162C748.791 188.225 749.772 188.544 750.629 189.089L801.906 221.72C803.159 222.517 804.662 222.821 806.126 222.574L843.509 216.266L890.855 208.285C893.61 207.821 896.327 209.317 897.359 211.914C907.73 238.013 914.491 265.409 917.472 293.35C921.454 321.768 921.446 350.597 917.447 378.997Z' fill='white' />
      <path d='M663.087 478.222C660.052 478.967 656.954 477.257 655.966 474.293L654.096 468.684C653.65 467.296 643.042 435.124 632.83 389.667C622.271 342.674 632.186 269.853 632.83 266.78L633.504 261.891C633.579 261.345 633.895 260.861 634.365 260.572V260.572C635.656 259.778 635.49 257.851 634.082 257.289L560.389 227.867C558.595 227.15 557.255 225.614 556.79 223.739L543.687 170.915C542.711 166.981 545.836 163.231 549.881 163.482L751.583 175.967C752.604 176.03 753.592 176.354 754.453 176.906L804.626 209.113C805.882 209.92 807.394 210.229 808.865 209.981L897.993 194.942C900.585 194.505 903.16 195.805 904.346 198.151L906.462 202.337C921.332 231.435 925.552 259.096 929.662 292.259C933.582 324.035 936.502 360.123 928.672 387.288L927.393 391.711C926.676 394.191 924.452 395.934 921.872 396.039L823.817 400.016C822.092 400.086 820.48 400.897 819.394 402.24L782.717 447.628C781.892 448.65 780.754 449.372 779.479 449.685L663.087 478.222ZM658.948 274.426C657.386 275.388 656.352 276.987 656.15 278.81C653.903 299.028 649.43 350.504 657.021 384.264C662.694 409.51 668.556 430.823 672.591 444.5C673.493 447.558 676.647 449.348 679.743 448.585L765.597 427.429C766.874 427.115 768.011 426.39 768.836 425.366L807.149 377.761C808.235 376.412 809.851 375.598 811.581 375.528L901.985 371.868C904.803 371.754 907.175 369.697 907.542 366.9C910.403 345.076 907.843 317.739 905.032 295.085C901.783 268.534 898.569 247.113 890.037 225.854C889.009 223.293 886.311 221.847 883.591 222.313L803.566 236C802.091 236.252 800.576 235.944 799.316 235.137L746.521 201.294C745.661 200.742 744.674 200.419 743.654 200.356L582.048 190.35C578.004 190.099 574.88 193.846 575.852 197.779L577.761 205.496C578.225 207.372 579.564 208.909 581.358 209.626L681.104 249.516C685.68 251.346 686.22 257.608 682.025 260.194L658.948 274.426Z' fill='black' />
      <path d='M674.936 247.012C671.858 245.786 668.368 247.288 667.142 250.367L662.413 262.245C661.187 265.324 662.689 268.813 665.767 270.039L722.458 292.611C725.536 293.837 729.026 292.335 730.252 289.256L734.981 277.377C736.207 274.298 734.705 270.809 731.626 269.583L674.936 247.012Z' fill='black' />
      <path d='M892.021 200.722C893.829 204.219 891.611 208.38 887.729 209.035L851.653 215.116C847.591 215.8 844.058 212.276 844.958 208.256C849.181 189.388 856.182 177.442 865.322 176.312C874.486 175.177 883.611 184.452 892.021 200.722Z' fill='black' />
      <path d='M909.16 384.174C913.023 384.017 916.036 387.509 915.098 391.261C910.809 408.397 904.096 419.113 895.512 420.203C886.963 421.288 876.745 411.327 867.886 393.908C865.977 390.155 868.703 385.815 872.91 385.645L909.16 384.174Z' fill='black' />
      <path d='M836.58 230.256C832.517 230.931 828.998 227.406 829.679 223.345L831.165 214.482C836.122 183.004 847.375 166.05 863.832 164.018C880.292 161.986 894.222 175.023 906.712 202.634L910.185 210.413C911.787 214.001 909.566 218.135 905.69 218.778L836.58 230.256ZM868.247 189.353C867.313 188.727 866.176 188.897 865.452 189.758C864.837 190.49 864.063 191.594 863.202 193.163C861.304 196.621 864.502 200.389 868.39 199.725V199.725C872.112 199.09 873.963 194.781 871.349 192.056C870.099 190.754 869.05 189.891 868.247 189.353Z' fill='black' />
      <path d='M893.922 432.497C878.602 432.497 864.822 418.468 852.832 390.708L849.097 381.946C847.451 378.084 850.177 373.769 854.372 373.598L924.292 370.742C928.152 370.584 931.153 374.062 930.431 377.857L928.922 385.801C923.426 414.272 912.87 429.814 897.545 432.039C897.245 432.082 896.946 432.146 896.651 432.215C895.757 432.424 894.858 432.497 893.922 432.497ZM893.157 397.008C889.086 397.183 886.735 401.742 889.648 404.591C890.015 404.951 890.364 405.271 890.694 405.557C892.486 407.109 895.077 406.685 896.472 404.77C896.841 404.263 897.189 403.742 897.515 403.208C899.356 400.191 896.689 396.857 893.157 397.008V397.008Z' fill='black' />
    </svg>
  );
}
