import { type ReactElement, Fragment, useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from '@authenticins/analytics';
import * as Authentic from '@authenticins/ts-client';
import {
  type StateCode,
  type TableColumn,
  STATES,
  AUTHENTIC_BRAND_NAME,
  AUTHENTIC_WEBSITE_URL,
  MOBILE_BREAKPOINT_PX,
  MAX_VIEW_CONTENT_WIDTH_PX,
  INPUT_HEIGHT_PX,
  pause,
  getFormattedUtcDate,
  parseAddress,
  formatPhoneNumber,
  formatCurrency,
  setUrlParam,
  getBrandNameWithoutInsurance,
  useTheme,
  Box,
  Text,
  Button,
  CopyButton,
  Link,
  Input,
  NameInput,
  DateInput,
  AddressInput,
  Radio,
  Table,
  Divider,
  Modal,
  Icon,
  Spinner,
  Tooltip
} from '@authenticins/react-ui';

import { ROUTE } from '../../main';
import { useAnalytics, useAuthentic, useFunctionalFinance } from '../../providers';
import { type ApplicationPageProps, ApplicationPage, ApplicationFieldInput } from '.';
import { ProductCard } from '../../components';

type QuotesPageProps = Pick<ApplicationPageProps, 'isActive' | 'customerIndustry'> & {
  supportPhoneNumber: number;
};
interface PaymentModalProps {
  acceptedQuotes: Authentic.ApplicationQuote[];
  isPayingInFull: boolean;
  policyNumbers: string[];
  premiumFinanceAgreementLink: string | null;
  paymentConfig: Authentic.ApplicationQuotesPaymentConfig | null;
  isOpen: boolean;
  onClose: () => void;
}

const MINIMUM_GROSS_PREMIUM_FOR_MONTHLY_PAYMENTS = 250;

const STATES_WITH_UNIQUE_NOTICES_AND_WARNINGS: StateCode[] = ['NH', 'LA'];

const FUNCTIONAL_FINANCE_PAYER_TERMS_URL = 'https://www.functionalfi.com/terms-of-service#payor-tos';
const NUM_FUNCTIONAL_FINANCE_CARD_PAYMENT_FORM_IFRAMES = 3;

const INDEMN_SCRIPT_LOADING_GRACE_PERIOD_MS = 2500;

export function QuotesPage({
  supportPhoneNumber,
  isActive = false,
  customerIndustry
}: QuotesPageProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const functionalFinance = useFunctionalFinance();
  const [declinedQuotes, setDeclinedQuotes] = useState<Authentic.ApplicationQuote[]>([]);
  const [acceptedQuotes, setAcceptedQuotes] = useState<Authentic.ApplicationQuote[]>([]);
  const [grossPremium, setGrossPremium] = useState<number>(0);
  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState<number>(0);
  const [adjustments, setAdjustments] = useState<Authentic.ApplicationQuoteAdjustment[]>([]);
  const totalAdjustmentToGrossPremium = adjustments.reduce((acc, adjustment) => acc + parseFloat(adjustment.amount), 0);
  // Only show adjustments if there was a discount.
  const showAdjustments = totalAdjustmentToGrossPremium < 0;
  const [isAdjustmentsDetailModalOpen, setIsAdjustmentsDetailModalOpen] = useState<boolean>(false);
  const [canOfferMonthlyPayments, setCanOfferMonthlyPayments] = useState<boolean>(false);
  const [isPayingInFull, setIsPayingInFull] = useState<boolean>(true);
  const addOnProducts = authentic.application !== null
    ? authentic.availableProducts
      // Ensure we only show add-ons available in the user's state(s) that weren't already declined.
      .filter((product) =>
        authentic.application !== null &&
        authentic.application.meta.stateCodes.every((stateCode) => product.availableStates.includes(stateCode)) &&
        !declinedQuotes.some((quote) => quote.product.name === product.name))
      // Filter based on name because products may have different quoted IDs.
      .filter((product) => !acceptedQuotes.some((quote) => quote.product.name === product.name))
    : [];
  const businessName = authentic.application?.answers.questions['BUSINESS_LEGAL_NAME'] ?? null;
  const operatingStates = authentic.application?.meta.stateCodes.map((stateCode) => STATES.find((state) => state.code === stateCode)?.name ?? stateCode) ?? [];
  const applicantFirstName = authentic.application?.answers.questions['NAME']?.split(' ')[0] ?? null;
  const policyStartDateField = authentic.application?.questions
    .find((section) => section.fields.some((field) => field.name === 'POLICY_START_DATE'))
    ?.fields.find((field) => field.name === 'POLICY_START_DATE');
  const policyStartDate = authentic.application?.answers.questions['POLICY_START_DATE'] ?? null;
  const [limitsDetailModalCurrentQuoteId, setLimitsDetailModalCurrentQuoteId] = useState<string | null>(null);
  const [documentsToAcknowledge, setDocumentsToAcknowledge] = useState<Array<{ name: string; url: string }>>([]);
  const [quotePacketFileUrls, setQuotePacketFileUrls] = useState<string[]>([]);
  const areQuotePacketsGenerating = quotePacketFileUrls.length !== acceptedQuotes.length;
  const [showQuotePacketDownloadLinks, setShowQuotePacketDownloadLinks] = useState(false);
  const [policyNumbers, setPolicyNumbers] = useState<string[]>([]);
  const [premiumFinanceAgreementLink, setPremiumFinanceAgreementLink] = useState<string | null>(null);
  const [paymentConfig, setPaymentConfig] = useState<Authentic.ApplicationQuotesPaymentConfig | null>(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isIndemnChatbotInit, setIsIndemnChatbotInit] = useState<boolean>(false);
  const [hasIndemnReceivedInstructions, setHasIndemnReceivedInstructions] = useState<boolean>(false);
  const email = authentic.application?.answers.questions['EMAIL'];
  const quoteUrl = typeof email !== 'undefined' ? setUrlParam(window.location.href, 'email', email) : window.location.href;

  useEffect(() => {
    if (!analytics.isInit || !analytics.featureIsEnabled('indemn_chatbot') || isIndemnChatbotInit) return;
    const indemnScript = document.createElement('script');
    indemnScript.src = 'https://proxy.indemn.ai/chat-script/668c992bbb88aa0013351299.js';
    indemnScript.addEventListener('load', () => {
      setIsIndemnChatbotInit(true);
      setTimeout(() => {
        // @ts-expect-error - Indemn's script provides the function to the global scope.
        sendPayload(JSON.stringify({
          action: 'context',
          payload: {
            instructions: 'I have just initialised the chatbot but there is no quote yet. Answer accordingly',
            quotes: { identityId: authentic.auth.awsCognitoIdentityId }
          }
        }));
      }, INDEMN_SCRIPT_LOADING_GRACE_PERIOD_MS);
    });
    document.head.appendChild(indemnScript);

    return () => {
      indemnScript.parentNode?.removeChild(indemnScript);
      document.getElementById('indemn-webchat')?.remove();
      setIsIndemnChatbotInit(false);
    };
  }, [analytics.isInit, analytics.featureIsEnabled('indemn_chatbot')]);

  useEffect(() => {
    if (!isIndemnChatbotInit) return;
    const webchatDiv = document.getElementById('indemn-webchat');
    if (authentic.application === null || webchatDiv === null) return;
    webchatDiv.setAttribute('data-params', JSON.stringify({
      tenantName: authentic.tenantName,
      applicantName: authentic.application.answers.questions['NAME'],
      email: authentic.application.meta.email
    }));
    const indemnBotMessage = !hasIndemnReceivedInstructions ? 'How can I help you with your quote?' : null;
    setHasIndemnReceivedInstructions(true);
    // @ts-expect-error - Indemn's script provides the function to the global scope.
    sendPayload(JSON.stringify({
      action: 'context',
      bot_message: indemnBotMessage,
      payload: {
        instructions: 'Here, I am sending a quote payload, you need to answer the user\'s question if any. Respond to the user with a proper answer based on the quotes payload. When answering a question about amounts, specify all amounts rounded up to two decimal places.',
        quotes: { identityId: authentic.auth.awsCognitoIdentityId, ...acceptedQuotes }
      }
    }));
  }, [acceptedQuotes, isIndemnChatbotInit]);

  // Parse declined and accepted quotes to set relevant state.
  useEffect(() => {
    if (authentic.application === null) return;
    const declinedQuotes = authentic.application.quotes.filter((quote) => quote.status === Authentic.APPLICATION_QUOTE_STATUS.DECLINED);
    setDeclinedQuotes(declinedQuotes);
    const acceptedQuotes = authentic.application.quotes.filter((quote) => quote.status !== Authentic.APPLICATION_QUOTE_STATUS.DECLINED);
    setAcceptedQuotes(acceptedQuotes);
    const acceptedQuotesWithPacketFileUrls = acceptedQuotes.filter((acceptedQuote) => acceptedQuote.packetFileUrl !== null);
    // @ts-expect-error - We know these packet file URLs are set.
    setQuotePacketFileUrls(acceptedQuotesWithPacketFileUrls.map((acceptedQuote) => acceptedQuote.packetFileUrl));
    const grossPremium = acceptedQuotes.reduce((acc, quote) => acc + parseFloat(quote.grossPremium), 0);
    setGrossPremium(grossPremium);
    const canOfferMonthlyPayments = analytics.featureIsEnabled('premium_finance_option') && grossPremium >= MINIMUM_GROSS_PREMIUM_FOR_MONTHLY_PAYMENTS;
    if (canOfferMonthlyPayments) {
      setEstimatedMonthlyPayment(parseFloat(acceptedQuotes[0]?.premiumFinanceEstimate?.monthlyPayment ?? '0'));
      setCanOfferMonthlyPayments(true);
    }
    setAdjustments(acceptedQuotes[0]?.adjustments ?? []);
  }, [authentic.application?.quotes]);

  // Determine which documents need to be acknowledged in order to purchase quotes.
  useEffect(() => {
    if (authentic.application === null) return;
    const documentsToAcknowledge = [];
    const uniqueNoticesAndWarningsStates = authentic.application.meta.stateCodes.filter((state) => STATES_WITH_UNIQUE_NOTICES_AND_WARNINGS.includes(state));
    if (uniqueNoticesAndWarningsStates.length > 0) {
      for (const state of uniqueNoticesAndWarningsStates) {
        documentsToAcknowledge.push({
          name: `Notices and Warnings (${state})`,
          url: `${AUTHENTIC_WEBSITE_URL}/docs/authentic-notices-and-warnings-${state.toLowerCase()}.pdf`
        });
      }
    } else {
      documentsToAcknowledge.push({
        name: 'Notices and Warnings',
        url: `${AUTHENTIC_WEBSITE_URL}/docs/authentic-notices-and-warnings.pdf`
      });
    }
    documentsToAcknowledge.push({
      name: 'Terrorism Coverage Disclosure',
      url: `${AUTHENTIC_WEBSITE_URL}/docs/authentic-terrorism-coverage-disclosure.pdf`
    });
    setDocumentsToAcknowledge(documentsToAcknowledge);
  }, [authentic.application?.meta.stateCodes]);

  // Product types are not correct in API, so this is how we determine a bundle.
  function productIsBundle(product: Authentic.Product): boolean {
    return product.backendIds.some((id) => acceptedQuotes.some((quote) => quote.product.id === id));
  }

  async function handlePaymentCtaClick(): Promise<void> {
    const paymentData = await authentic.getApplicationQuotesPaymentData(isPayingInFull);
    if (paymentData === null) return;
    if (!analytics.featureIsEnabled('embedded_ff_payment') || !functionalFinance.isInit || paymentData.paymentConfig === null) {
      if (analytics.isInit) analytics.trackEvent(ANALYTICS_EVENT.CLICKED_APPLICATION_QUOTES_PAYMENT_LINK);
      window.location.assign(paymentData.paymentLink);
    } else {
      setPolicyNumbers(paymentData.policyNumbers);
      setPremiumFinanceAgreementLink(paymentData.premiumFinanceAgreementLink);
      setPaymentConfig(paymentData.paymentConfig);
      setIsPaymentModalOpen(true);
      functionalFinance.configurePayment(Authentic.convertApplicationQuotesPaymentConfigToFFPaymentConfig(paymentData.paymentConfig));
    }
  }

  return (
    <ApplicationPage maxW={undefined} isActive={isActive} customerIndustry={customerIndustry}>
      <Text
        variant='heading'
        maxW={MOBILE_BREAKPOINT_PX + 'px'}
        mb={1}
        fontSize='xl'
        textAlign='center'
        onMobile={{ px: 4, fontSize: 'lg' }}
      >
        {applicantFirstName}'s {customerIndustry ?? 'Business'} Insurance
      </Text>
      <Box centered w='100%' onMobile={{ column: true }}>
        {businessName !== null && operatingStates.length > 0 && (
          <Box centered mb={2}>
            <Text fontSize={1.25} textAlign='center'>
              {businessName} in {operatingStates.length > 1
                ? `${operatingStates.slice(0, operatingStates.length - 1).join(', ')}${operatingStates.length > 2 ? ',' : ''} and ${operatingStates[operatingStates.length - 1]}`
                : operatingStates[0]}
            </Text>
            <Box
              w={theme.border.baseWidthPx + 'px'}
              h='30px'
              mx={1.5}
              bg='subtle'
              onMobile={{ display: 'none' }}
            />
          </Box>
        )}
        <CopyButton mb={2} iconName='RiLink' textToCopy={quoteUrl}>
          Copy quote link
        </CopyButton>
      </Box>
      <Box
        align='flex-end'
        justify='space-between'
        wrap='reverse'
        w={`calc(100vw - ${theme.baseSpacingPx * 4}px)`}
        maxW={MOBILE_BREAKPOINT_PX + 'px'}
        mt={4}
        onMobile={{ justify: 'center' }}
      >
        <Box column w='100%' maxW='525px' pr={8} onMobile={{ pr: undefined }}>
          {declinedQuotes.length > 0 && (
            <Box mb={1} c='error'>
              <Icon name='RiErrorWarningLine' mr={1} />
              <Text>
                We're sorry, but we couldn't offer you a quote for&nbsp;
                {declinedQuotes.map((quote) => quote.product.name).join(', ')}&nbsp;coverage.
              </Text>
            </Box>
          )}
          {[...acceptedQuotes.map((quote) => quote.product), ...addOnProducts]
            .map((product, i) => {
              const isPropertyProduct = product.name === 'Property';
              const isMandatory = product.isOffered && authentic.availableProducts.filter((product) => product.isOffered).length === 1;
              const containingBundleNames = authentic.availableProducts
                .filter((availableProduct) => productIsBundle(availableProduct) &&
                  availableProduct.backendIds.some((id) => product.id === id) &&
                  availableProduct.name !== product.name)
                .map((product) => product.name);
              const correspondingQuote = acceptedQuotes.find((quote) => quote.product.id === product.id) ?? null;

              if (productIsBundle(product)) return <Fragment key={i} />;
              return (
                <Fragment key={i}>
                  <ProductCard
                    mt={i > 0 ? 6 : undefined}
                    product={product}
                    actions={correspondingQuote !== null
                      ? [{
                          label: 'View limits',
                          onClick: () => { setLimitsDetailModalCurrentQuoteId(correspondingQuote.id); }
                        }]
                      : []}
                    isMandatory={isMandatory}
                    isActive={correspondingQuote !== null}
                    showHeaderDetails
                    containingBundleNames={containingBundleNames}
                    onChange={(isActive) => {
                      if (authentic.application === null) return;
                      const acceptedQuoteProductIds = acceptedQuotes.map((quote) => quote.product.id);
                      void authentic.resubmitApplication({
                        productIds: isActive
                          ? [...acceptedQuoteProductIds, product.id]
                          : acceptedQuoteProductIds.filter((id) => id !== product.id)
                      });
                    }}
                    isDisabled={authentic.isSubmittingApplication || authentic.isGeneratingApplicationQuotesPaymentData}
                  />
                  {correspondingQuote !== null && (
                    <Modal
                      maxW={MAX_VIEW_CONTENT_WIDTH_PX + 'px'}
                      title={`${product.name} limits`}
                      isOpen={limitsDetailModalCurrentQuoteId === correspondingQuote.id}
                      onClose={() => { setLimitsDetailModalCurrentQuoteId(null); }}
                    >
                      <Table
                        columns={[
                          ...(isPropertyProduct ? [{ title: 'Location' }] : []),
                          { title: 'Type' },
                          { title: isPropertyProduct ? 'Amount' : 'Occurrence', align: 'right' },
                          ...(!isPropertyProduct ? [{ title: 'Aggregate', align: 'right' }] : [])
                        ] as TableColumn[]}
                        rows={correspondingQuote.limits.map((limit) => ({
                          cells: [
                            ...(isPropertyProduct ? [limit.address ?? ''] : []),
                            limit.title,
                            parseInt(limit.occurrence) > 0 ? formatCurrency(limit.occurrence, true) : 'N/A',
                            ...(!isPropertyProduct ? [parseInt(limit.aggregate) > 0 ? formatCurrency(limit.aggregate, true) : 'N/A'] : [])
                          ]
                        }))}
                      />
                      <Text mt={1.5} textAlign='center'>
                        To adjust your limits and further tailor this policy to fit your needs, call us at&nbsp;
                        <Link inline href={`tel:${supportPhoneNumber}`}>
                          {formatPhoneNumber(supportPhoneNumber)}
                        </Link>. We're here to help!
                      </Text>
                    </Modal>
                  )}
                </Fragment>
              );
            })}
        </Box>
        <Box
          centered
          column
          w='100%'
          maxW='375px'
          mb={6}
          onMobile={{ maxW: undefined }}
        >
          <Box
            centered
            column
            w='100%'
            p={3}
            bg='surface'
            radius='lg'
            shadow='drop'
          >
            {canOfferMonthlyPayments && (
              <Button
                w='180px'
                p={0.5}
                bg='subtle'
                fontSize='sm'
                bold
                radius='full'
                onClick={() => { setIsPayingInFull(!isPayingInFull); }}
                isDisabled={authentic.isSubmittingApplication || authentic.isGeneratingApplicationQuotesPaymentData}
              >
                {['MONTHLY', 'ANNUAL'].map((paymentType, i) => {
                  const isActive = paymentType === 'ANNUAL' ? isPayingInFull : !isPayingInFull;

                  return (
                    <Box
                      key={i}
                      centered
                      w='50%'
                      p={0.75}
                      bg={isActive ? 'secondary' : undefined}
                      c={isActive ? 'secondaryContrast' : 'muted'}
                      radius='full'
                    >
                      {paymentType}
                    </Box>
                  );
                })}
              </Button>
            )}
            <Box align='flex-end' mt={2}>
              {authentic.isSubmittingApplication
                ? <Spinner size='xl' />
                : (<>
                    <Text
                      variant='heading'
                      c={theme.palette.doesPrimaryPassAccessibility ? 'primary' : 'text'}
                      fontSize='xl'
                    >
                      {formatCurrency(isPayingInFull ? grossPremium : estimatedMonthlyPayment)}
                    </Text>
                    <Text mb={0.5} ml={1} fontSize='lg'>
                      /{isPayingInFull ? 'year' : 'month'}
                    </Text>
                  </>)}
            </Box>
            {typeof policyStartDateField !== 'undefined' && (
              <ApplicationFieldInput
                centered
                mt={2}
                field={{
                  ...policyStartDateField,
                  label: 'Start date'
                }}
                showFieldTitle={false}
                value={policyStartDate}
                onChange={(value) => {
                  if (value === null || value === policyStartDate) return;
                  void authentic.resubmitApplication([{ fieldName: 'POLICY_START_DATE', fieldValue: value }]);
                }}
                isDisabled={authentic.isSubmittingApplication || authentic.isGeneratingApplicationQuotesPaymentData}
                inputElementThemeStyleProps={{ border: 'subtle' }}
              />
            )}
            {showAdjustments && (
              <>
                <Button
                  variant='link'
                  mt={2}
                  underline
                  onClick={() => { setIsAdjustmentsDetailModalOpen(true); }}
                >
                  <Icon name='RiSparkling2Line' mr={1} />
                  {formatCurrency(Math.abs(totalAdjustmentToGrossPremium))} discount applied!
                </Button>
                <Modal
                  title='Discounts applied'
                  isOpen={isAdjustmentsDetailModalOpen}
                  onClose={() => { setIsAdjustmentsDetailModalOpen(false); }}
                >
                  {adjustments.map((adjustment, i) => (
                    <Box key={i} justify='space-between' w='100%' mt={1}>
                      <Text>
                        {adjustment.title.replaceAll('{{TENANT_NAME}}', getBrandNameWithoutInsurance(theme))}
                      </Text>
                      <Text>{formatCurrency(Math.abs((parseFloat(adjustment.amount))))}</Text>
                    </Box>
                  ))}
                  <Divider />
                  <Box justify='space-between' w='100%' bold>
                    <Text>Total saved</Text>
                    <Text>{formatCurrency(Math.abs(totalAdjustmentToGrossPremium))}</Text>
                  </Box>
                </Modal>
              </>
            )}
            <Box centered column w='100%' mt={4}>
              <Box centered>
                <Icon name='RiCalendarCloseLine' size='sm' mr={0.5} />
                <Text fontSize='sm'>Cancel anytime for free and get a prorated refund.</Text>
              </Box>
              <Button
                variant='primary'
                w='100%'
                mt={1}
                onClick={() => { void handlePaymentCtaClick(); }}
                isDisabled={authentic.isSubmittingApplication || authentic.isGeneratingApplicationQuotesPaymentData}
                isLoading={authentic.isGeneratingApplicationQuotesPaymentData}
              >
                Pay now
              </Button>
              <Text mt={1.5} fontSize='sm' textAlign='center'>
                Please see our&nbsp;
                {documentsToAcknowledge.map((document, i) => (
                  <Fragment key={i}>
                    <Link inline c='text' href={document.url} openInNewTab>
                      {document.name}
                    </Link>
                    {i === documentsToAcknowledge.length - 1
                      ? '.'
                      : documentsToAcknowledge.length > 2
                        ? i === documentsToAcknowledge.length - 2
                          ? (<>,&nbsp;and&nbsp;</>)
                          : (<>,&nbsp;</>)
                        : <>&nbsp;and&nbsp;</>}
                  </Fragment>
                ))}
              </Text>
              <Divider my={3}/>
              <Box column w='100%'>
                <Button
                  variant='link'
                  w='100%'
                  textAlign='center'
                  isLoading={areQuotePacketsGenerating}
                  onClick={() => { setShowQuotePacketDownloadLinks(!showQuotePacketDownloadLinks); }}
                >
                  <Text mr={1}>Download quote packet{acceptedQuotes.length > 1 ? 's' : ''}</Text>
                  {areQuotePacketsGenerating ? <Spinner size='base' c='inherit' /> : <Icon name='RiArrowDownSLine' />}
                </Button>
                <Box
                  display='block'
                  w='100%'
                  maxH={showQuotePacketDownloadLinks ? '200px' : '0px'}
                  mt={1}
                  opacity={showQuotePacketDownloadLinks ? 1 : 0}
                  transition
                  overflow='hidden'
                >
                  {quotePacketFileUrls.map((quotePacketFileUrl, i) => (
                    <Box align='center' justify='space-between' my={1}>
                      <Text pr={1}>
                        {authentic.application?.quotes.find((quote) =>
                          quote.packetFileUrl === quotePacketFileUrl)?.product.name}
                      </Text>
                      <Link
                        key={i}
                        href={quotePacketFileUrl}
                        openInNewTab
                      >
                        Download
                        <Icon name='RiDownloadLine' ml={0.5} />
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            align='flex-start'
            column
            w='100%'
            mt={3}
            p={3}
            border='neutral'
            radius='lg'
          >
            <Text variant='heading' mb={1} fontSize={1.25}>
              Already insured? Switch hassle-free.
            </Text>
            <Text fontSize='sm'>
              Input your old policy information after purchase; we'll cancel it and request your refund.
            </Text>
          </Box>
        </Box>
      </Box>
      <PaymentModal
        acceptedQuotes={acceptedQuotes}
        isPayingInFull={isPayingInFull}
        policyNumbers={policyNumbers}
        premiumFinanceAgreementLink={premiumFinanceAgreementLink}
        paymentConfig={paymentConfig}
        isOpen={isPaymentModalOpen}
        onClose={() => { setIsPaymentModalOpen(false); }}
      />
    </ApplicationPage>
  );
}

function PaymentModal({
  acceptedQuotes,
  isPayingInFull,
  policyNumbers,
  premiumFinanceAgreementLink,
  paymentConfig,
  isOpen,
  onClose
}: PaymentModalProps): ReactElement {
  const analytics = useAnalytics();
  const authentic = useAuthentic();
  const theme = useTheme();
  const functionalFinance = useFunctionalFinance();
  const [isPayingWithCard, setIsPayingWithCard] = useState<boolean>(true);
  const totalDueToday = paymentConfig !== null
    ? parseFloat(isPayingInFull ? paymentConfig.payInFull.invoiceAmount : (paymentConfig.premiumFinance?.downPaymentAmount ?? '0'))
    : 0;
  const totalDueTodayPlatformFee = paymentConfig !== null
    ? parseFloat(isPayingInFull
      ? paymentConfig.payInFull[isPayingWithCard ? 'cardFeeAmount' : 'achFeeAmount']
      : (paymentConfig.premiumFinance?.[isPayingWithCard ? 'cardDownPaymentFeeAmount' : 'achDownPaymentFeeAmount'] ?? '0'))
    : 0;
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  const premiumFinanceTotalPlatformFees = paymentConfig !== null && paymentConfig.premiumFinance !== null
    ? (parseFloat(paymentConfig.premiumFinance[isPayingWithCard ? 'cardInstallmentFeeAmount' : 'achInstallmentFeeAmount']) *
        parseInt(paymentConfig.premiumFinance.numberOfInstallments)) +
      parseFloat(paymentConfig.premiumFinance[isPayingWithCard ? 'cardDownPaymentFeeAmount' : 'achDownPaymentFeeAmount'])
    : 0;
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  const premiumFinanceTotalCost = paymentConfig !== null && paymentConfig.premiumFinance !== null
    ? totalDueToday +
      (parseInt(paymentConfig.premiumFinance.numberOfInstallments) * parseFloat(paymentConfig.premiumFinance.installmentAmount)) +
      premiumFinanceTotalPlatformFees +
      parseFloat(paymentConfig.premiumFinance.interestAmount)
    : 0;
  const [payerName, setPayerName] = useState<string | null>(null);
  const [billingZipCode, setBillingZipCode] = useState<string | null>(null);
  const [isBankAccountSavingsAccount, setIsBankAccountSavingsAccount] = useState<boolean>(false);
  const [bankRoutingNumber, setBankRoutingNumber] = useState<string | null>(null);
  const [bankAccountNumber, setBankAccountNumber] = useState<string | null>(null);
  const [showPremiumFinanceDetails, setShowPremiumFinanceDetails] = useState<boolean>(false);
  const isSubmittingDisabled = !functionalFinance.isInit ||
    functionalFinance.isSubmittingPaymentDisabled ||
    payerName === null ||
    billingZipCode === null ||
    (!isPayingWithCard && (bankRoutingNumber === null || bankAccountNumber === null));
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const [isCardPaymentFormInit, setIsCardPaymentFormInit] = useState<boolean>(false);
  const [showExistingPolicyCancellationForm, setShowExistingPolicyCancellationForm] = useState<boolean>(false);
  const [existingPolicyBusinessName, setExistingPolicyBusinessName] = useState<string | null>(authentic.application?.answers.questions['BUSINESS_LEGAL_NAME'] ?? null);
  const applicantName = authentic.application?.answers.questions['NAME'] ?? null;
  const email = authentic.application?.meta.email ?? null;
  const [existingPolicyInsuredName, setExistingPolicyInsuredName] = useState<string | null>(applicantName);
  const [existingPolicyMailingAddress, setExistingPolicyMailingAddress] = useState<string | null>(authentic.application?.answers.questions['MAILING_ADDRESS'] ?? null);
  const [existingPolicyCarrier, setExistingPolicyCarrier] = useState<string | null>(null);
  const [existingPolicyNumbers, setExistingPolicyNumbers] = useState<string | null>(null);
  const policyStartDateFieldResponse = authentic.application?.answers.questions['POLICY_START_DATE'] ?? null;
  const [existingPolicyStartDate, setExistingPolicyStartDate] = useState<Date | null>(null);
  const [existingPolicyEndDate, setExistingPolicyEndDate] = useState<Date | null>(null);
  const existingPolicyCancellationDate = policyStartDateFieldResponse !== null ? new Date(policyStartDateFieldResponse) : null;
  const isSubmittingExistingPolicyCancellationFormDisabled = existingPolicyBusinessName === null ||
    existingPolicyInsuredName === null ||
    existingPolicyMailingAddress === null ||
    existingPolicyCarrier === null ||
    existingPolicyNumbers === null ||
    existingPolicyStartDate === null;
  const [hasAttemptedSubmitExistingPolicyCancellationForm, setHasAttemptedSubmitExistingPolicyCancellationForm] = useState<boolean>(false);
  const [showExistingPolicyCancellationFormSuccessMessage, setShowExistingPolicyCancellationFormSuccessMessage] = useState<boolean>(false);

  // When the modal opens, try to pre-fill payer name and billing Zip code.
  // If paying with card, configure Functional Finance's injected card payment form and initialize.
  useEffect(() => {
    if (!isOpen) return;
    setPayerName(applicantName);
    setBillingZipCode(parseAddress(authentic.application?.answers.questions['MAILING_ADDRESS'] ?? '')?.zipCode ?? null);
    if (!isPayingWithCard) return;
    functionalFinance.configureCardPaymentForm({
      cardNumberWrapper: 'card-number-wrapper',
      expirationDateWrapper: 'expiration-date-wrapper',
      cvcWrapper: 'cvc-wrapper'
    });
    // Style the injected iframes once they render.
    void (async () => {
      let ffCardIframes: HTMLIFrameElement[] = [];
      while (ffCardIframes.length !== NUM_FUNCTIONAL_FINANCE_CARD_PAYMENT_FORM_IFRAMES) {
        ffCardIframes = [...document.querySelectorAll<HTMLIFrameElement>('#functional-finance-card-payment-form iframe')];
        await pause();
      }
      ffCardIframes.forEach((iframe) => {
        iframe.style.width = '100%';
        iframe.style.height = INPUT_HEIGHT_PX + 'px';
        iframe.style.border = 'none';
        iframe.style.borderTop = `${theme.baseSpacingPx * 2.5}px solid white`;
        iframe.style.borderLeft = `${theme.baseSpacingPx * 4}px solid white`;
        iframe.style.borderRadius = theme.border.radius.base + 'px';
      });
      setIsCardPaymentFormInit(true);
    })();
    return () => {
      document.querySelectorAll('#functional-finance-card-payment-form iframe').forEach((iframe) => { iframe.remove(); });
      setIsCardPaymentFormInit(false);
    };
  }, [isOpen, isPayingWithCard]);

  async function handleSubmitPayment(): Promise<void> {
    if (isSubmittingDisabled) return;
    // @ts-expect-error - If submitting isn't disabled, we know bank account details are defined for ACH payment.
    await functionalFinance.submitPayment(isPayingInFull, {
      payerName,
      billingZipCode,
      ...(!isPayingWithCard
        ? {
            bankAccount: {
              isSavingsAccount: isBankAccountSavingsAccount,
              routingNumber: bankRoutingNumber,
              accountNumber: bankAccountNumber
            }
          }
        : {})
    });
  }

  return (
    <Modal
      centered={functionalFinance.wasPaymentSuccessful}
      title={!functionalFinance.wasPaymentSuccessful ? 'Activate coverage' : undefined}
      subtitle={!functionalFinance.wasPaymentSuccessful ? 'Add your payment information to activate coverage.' : undefined}
      isOpen={isOpen}
      onClose={(!functionalFinance.isSubmittingPayment && !functionalFinance.wasPaymentSuccessful) ? onClose : undefined}
      footer={showExistingPolicyCancellationForm || showExistingPolicyCancellationFormSuccessMessage
        ? (<>
            <Button
              variant='primary'
              onClick={() => {
                if (showExistingPolicyCancellationFormSuccessMessage) window.location.assign(ROUTE.POLICY);
                else if (analytics.isInit) {
                  analytics.trackEvent(ANALYTICS_EVENT.COMPLETED_EXISTING_POLICY_CANCELLATION_FORM, {
                    email,
                    businessName: existingPolicyBusinessName,
                    insuredName: existingPolicyInsuredName,
                    mailingAddress: existingPolicyMailingAddress,
                    newPolicyNumbers: policyNumbers,
                    existingPolicyCarrier,
                    existingPolicyNumbers,
                    existingPolicyStartDate: existingPolicyStartDate !== null ? getFormattedUtcDate(existingPolicyStartDate) : null,
                    existingPolicyEndDate: existingPolicyEndDate !== null ? getFormattedUtcDate(existingPolicyEndDate) : null,
                    existingPolicyCancellationDate: existingPolicyCancellationDate !== null ? getFormattedUtcDate(existingPolicyCancellationDate) : null
                  });
                  setShowExistingPolicyCancellationFormSuccessMessage(true);
                }
              }}
              isDisabled={isSubmittingExistingPolicyCancellationFormDisabled}
              onDisabledClick={() => { setHasAttemptedSubmitExistingPolicyCancellationForm(true); }}
            >
              {showExistingPolicyCancellationFormSuccessMessage ? 'View policy' : 'Submit'}
            </Button>
            {!showExistingPolicyCancellationFormSuccessMessage && (
              <Button variant='link' mt={1.5} c='error' onClick={() => { window.location.assign(ROUTE.POLICY); }}>
                Cancel
              </Button>
            )}
          </>)
        : functionalFinance.wasPaymentSuccessful
          ? (<Box centered w='100%'>
              <Button variant='secondary' mr={2} onClick={() => { window.location.assign(ROUTE.POLICY); }}>
                No
              </Button>
              <Button variant='primary' onClick={() => { setShowExistingPolicyCancellationForm(true); }}>
                Yes
              </Button>
            </Box>)
          : undefined}
    >
      {showExistingPolicyCancellationFormSuccessMessage
        ? <>
          <Icon name='RiCheckboxCircleLine' size={4} c='success' />
          <Text variant='heading' mt={2} textAlign='center'>
            Thank you, {applicantName?.split(' ')[0]}! Check your email.
          </Text>
          <Text mt={1.5} textAlign='center'>
            Please review and sign the Docusign envelope we just emailed to {authentic.application?.meta.email ?? 'you'}.
            After signing, we'll go ahead and initiate the cancellation.
          </Text>
          </>
        : showExistingPolicyCancellationForm
          ? <>
              <Text variant='heading' mb={4} textAlign='center'>
                We need some information about your old policy.
              </Text>
              <AddressInput
                label='Mailing address'
                value={existingPolicyMailingAddress ?? undefined}
                onChange={setExistingPolicyMailingAddress}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyMailingAddress === null}
              />
              <Input
                mt={3}
                label='Business name'
                iconName='RiAtLine'
                value={existingPolicyBusinessName ?? undefined}
                onChange={setExistingPolicyBusinessName}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyBusinessName === null}
              />
              <Input
                mt={3}
                label='Insured name'
                iconName='RiUserLine'
                value={existingPolicyInsuredName ?? undefined}
                onChange={setExistingPolicyInsuredName}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyInsuredName === null}
              />
              <Input
                mt={3}
                label='Carrier name'
                iconName='RiHonourLine'
                value={existingPolicyCarrier ?? undefined}
                onChange={setExistingPolicyCarrier}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyCarrier === null}
              />
              <Input
                mt={3}
                label='Policy number(s)'
                iconName='RiHashtag'
                rightElement={<Tooltip label='Please separate multiple policy numbers with commas.' />}
                value={existingPolicyNumbers ?? undefined}
                onChange={setExistingPolicyNumbers}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyNumbers === null}
              />
              <DateInput
                maxW='100%'
                mt={3}
                label='Old policy start date'
                value={existingPolicyStartDate ?? undefined}
                onChange={(v) => {
                  if (v === null) {
                    setExistingPolicyStartDate(null);
                    setExistingPolicyEndDate(null);
                  } else {
                    setExistingPolicyStartDate(v);
                    const endDate = new Date(v);
                    endDate.setFullYear(endDate.getFullYear() + 1);
                    setExistingPolicyEndDate(endDate);
                  }
                }}
                error={hasAttemptedSubmitExistingPolicyCancellationForm && existingPolicyStartDate === null}
              />
            </>
          : functionalFinance.wasPaymentSuccessful
            ? (<>
                <Icon name='RiCheckboxCircleLine' size={4} c='success' />
                <Text variant='heading' mt={2} textAlign='center'>
                  You're covered! Policy documents will be emailed to you shortly. 🎉
                </Text>
                <Text mt={1.5} textAlign='center'>
                  Do you need to cancel your old policy?
                </Text>
              </>)
            : (<>
                <Box align='center' justify='space-between' w='100%'>
                  {[`Credit${!theme.isOnMobile ? ' or debit' : ''} card`, 'Bank account'].map((paymentOption, i) => {
                    const isCardPaymentOption = paymentOption.toLowerCase().includes('card');
                    const isActive = isCardPaymentOption ? isPayingWithCard : !isPayingWithCard;

                    return (
                      <Radio
                        key={i}
                        variant='card'
                        w={`calc(50% - ${theme.baseSpacingPx / 2}px)`}
                        label={paymentOption}
                        description={isCardPaymentOption ? 'All major cards accepted' : 'ACH payments accepted'}
                        isActive={isActive}
                        onActivate={() => { setIsPayingWithCard(isCardPaymentOption); }}
                        isDisabled={functionalFinance.isSubmittingPayment}
                      />
                    );
                  })}
                </Box>
                <Divider />
                <Box centered>
                  <NameInput
                    mr={1}
                    bg={theme.isDarkMode ? 'text' : 'surface'}
                    c={theme.isDarkMode ? 'surface' : 'text'}
                    label={`Name on ${isPayingWithCard ? 'card' : 'account'}`}
                    value={payerName ?? undefined}
                    onChange={setPayerName}
                    error={hasAttemptedSubmit && payerName === null}
                    isDisabled={functionalFinance.isSubmittingPayment}
                  />
                  <Input
                    w='200px'
                    bg={theme.isDarkMode ? 'text' : 'surface'}
                    c={theme.isDarkMode ? 'surface' : 'text'}
                    label='Billing ZIP'
                    iconName='RiMapPinLine'
                    value={billingZipCode ?? undefined}
                    onChange={setBillingZipCode}
                    error={hasAttemptedSubmit && billingZipCode === null}
                    isDisabled={functionalFinance.isSubmittingPayment}
                  />
                </Box>
                <Box
                  position='relative'
                  column
                  w='100%'
                  mb={functionalFinance.errorMessage !== null ? 2 : undefined}
                  transition
                >
                  {isPayingWithCard
                    ? (<Box
                        id='functional-finance-card-payment-form'
                        position='relative'
                        centered
                        w='100%'
                        h={INPUT_HEIGHT_PX + (theme.border.baseWidthPx * 2) + 'px'}
                        mt={1}
                        opacity={functionalFinance.isSubmittingPayment ? 0.5 : undefined}
                        transition
                        overflow={!isCardPaymentFormInit ? 'hidden' : undefined}
                        pointerEvents={functionalFinance.isSubmittingPayment ? 'none' : undefined}
                        onMobile={{
                          justify: 'space-between',
                          wrap: true,
                          h: 'auto'
                        }}
                      >
                        {['card-number-wrapper', 'expiration-date-wrapper', 'cvc-wrapper'].map((wrapperElementId, i) => {
                          const isCardNumberWrapper = wrapperElementId === 'card-number-wrapper';
                          const isExpirationDateWrapper = wrapperElementId === 'expiration-date-wrapper';

                          return (
                            <Box
                              key={i}
                              position='relative'
                              column
                              w='100%'
                              maxW={isCardNumberWrapper
                                ? '235px'
                                : isExpirationDateWrapper
                                  ? '100px'
                                  : '85px'}
                              ml={i > 0 ? 1 : undefined}
                              border={functionalFinance.errorMessage !== null ? 'error' : 'transparent'}
                              radius='base'
                              opacity={isCardPaymentFormInit ? 1 : 0}
                              transition
                              onMobile={{
                                w: i === 0 ? '100%' : `calc(50% - ${theme.baseSpacingPx / 2}px)`,
                                maxW: undefined,
                                mt: i > 0 ? 1 : undefined,
                                ml: undefined
                              }}
                              styles={{ background: 'white' }}
                            >
                              <Text
                                position='absolute'
                                top={1.5}
                                left={4.25}
                                c='muted'
                                fontSize='sm'
                              >
                                {isCardNumberWrapper
                                  ? 'Card number'
                                  : isExpirationDateWrapper
                                    ? 'Expiry'
                                    : 'CVC'}
                              </Text>
                              <Icon
                                name={isCardNumberWrapper
                                  ? 'RiBankCardLine'
                                  : isExpirationDateWrapper
                                    ? 'RiCalendarLine'
                                    : 'RiLockLine'}
                                position='absolute'
                                top='50%'
                                left={1.25}
                                c='muted'
                              />
                              <Box id={wrapperElementId} w='100%' />
                            </Box>
                          );
                        })}
                        <Spinner
                          size='xl'
                          position='absolute'
                          top='50%'
                          left='50%'
                          opacity={!isCardPaymentFormInit ? 1 : 0}
                          transition
                        />
                      </Box>)
                    : (<>
                        <Box align='center' justify='space-between' w='100%' mt={2}>
                          {['Checking', 'Savings'].map((accountType, i) => {
                            const isSavingsAccountOption = accountType === 'Savings';
                            const isActive = isSavingsAccountOption ? isBankAccountSavingsAccount : !isBankAccountSavingsAccount;

                            return (
                              <Radio
                                key={i}
                                variant='card'
                                w={`calc(50% - ${theme.baseSpacingPx / 2}px)`}
                                label={accountType}
                                isActive={isActive}
                                onActivate={() => { setIsBankAccountSavingsAccount(isSavingsAccountOption); }}
                                isDisabled={functionalFinance.isSubmittingPayment}
                              />
                            );
                          })}
                        </Box>
                        <Input
                          mt={1}
                          type='number'
                          label='Routing number'
                          iconName='RiBankLine'
                          value={bankRoutingNumber ?? undefined}
                          displayFormattedValue={false}
                          allowValueCapture={false}
                          onChange={setBankRoutingNumber}
                          error={functionalFinance.errorMessage !== null || (hasAttemptedSubmit && bankRoutingNumber === null)}
                          isDisabled={functionalFinance.isSubmittingPayment}
                        />
                        <Input
                          mt={1}
                          type='number'
                          label='Account number'
                          iconName='RiHashtag'
                          value={bankAccountNumber ?? undefined}
                          displayFormattedValue={false}
                          allowValueCapture={false}
                          onChange={setBankAccountNumber}
                          error={functionalFinance.errorMessage !== null || (hasAttemptedSubmit && bankAccountNumber === null)}
                          isDisabled={functionalFinance.isSubmittingPayment}
                        />
                      </>)}
                  <Text
                    position='absolute'
                    left={0}
                    bottom={-3}
                    c='error'
                    fontSize='sm'
                    opacity={functionalFinance.errorMessage !== null ? 1 : 0}
                    transition
                  >
                    {functionalFinance.errorMessage}
                  </Text>
                </Box>
                <Box
                  column
                  w='100%'
                  mt={3}
                  p={2}
                  border='subtle'
                  radius='lg'
                >
                  {// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                  !isPayingInFull && paymentConfig !== null && paymentConfig.premiumFinance !== null && (
                    <>
                      <Box justify='space-between' w='100%'>
                        <Text>First installment date</Text>
                        <Text>{getFormattedUtcDate(new Date(paymentConfig.premiumFinance.firstPaymentDueDate))}</Text>
                      </Box>
                      <Box justify='space-between' w='100%' mt={1}>
                        <Box column>
                          <Text>
                            Monthly payment&nbsp;
                            <Text inline c='muted' fontSize='sm'>(x{paymentConfig.premiumFinance.numberOfInstallments})</Text>
                          </Text>
                          <Button
                            variant='link'
                            mt={1}
                            onClick={() => setShowPremiumFinanceDetails(!showPremiumFinanceDetails)}
                            isDisabled={functionalFinance.isSubmittingPayment}
                          >
                            {showPremiumFinanceDetails ? 'Hide' : 'Show'} details
                          </Button>
                        </Box>
                        <Box align='flex-end' column>
                          <Text>{formatCurrency(paymentConfig.premiumFinance.installmentAmount)}</Text>
                          <Text c='muted' fontSize='sm'>
                            + {formatCurrency(paymentConfig.premiumFinance[isPayingWithCard
                            ? 'cardInstallmentFeeAmount'
                            : 'achInstallmentFeeAmount'])} platform fee
                            <Tooltip
                              label={`There is a payment processing fee of ${isPayingWithCard
                                  ? '3.50% + $1.25'
                                  : '$3.25'} per installment for ${isPayingWithCard
                                  ? 'card'
                                  : 'ACH'} payments.`}
                            />
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        w='100%'
                        maxH={showPremiumFinanceDetails ? '1000px' : '0px'}
                        opacity={showPremiumFinanceDetails ? 1 : 0}
                        transition
                        overflow='hidden'
                        pointerEvents={!showPremiumFinanceDetails ? 'none' : undefined}
                      >
                        <Box
                          position='relative'
                          column
                          w='100%'
                          mt={2}
                          p={2}
                          border='subtle'
                          radius='base'
                        >
                          <Text
                            position='absolute'
                            top={-1.5}
                            left={1}
                            px={1}
                            bg='background'
                          >
                            <b>Your financing plan&nbsp;</b>
                            <Text inline c='muted' fontSize='sm'>({paymentConfig.premiumFinance.interestRatePercentage}% APR)</Text>
                          </Text>
                          <Box justify='space-between' w='100%'>
                            <Text>Premium down payment</Text>
                            <Text>{formatCurrency(paymentConfig.premiumFinance.downPaymentAmount)}</Text>
                          </Box>
                          <Box justify='space-between' w='100%' mt={1}>
                            <Text>Financed premium</Text>
                            <Text>{formatCurrency(paymentConfig.premiumFinance.financedAmount)}</Text>
                          </Box>
                          <Box justify='space-between' w='100%' mt={1}>
                            <Text>Interest</Text>
                            <Text>{formatCurrency(paymentConfig.premiumFinance.interestAmount)}</Text>
                          </Box>
                          {parseInt(paymentConfig.premiumFinance.documentStampsFeeAmount) > 1 && (
                            <Box justify='space-between' w='100%' mt={0.25}>
                              <Text>Document stamps fee</Text>
                              <Text>{formatCurrency(paymentConfig.premiumFinance.documentStampsFeeAmount)}</Text>
                            </Box>
                          )}
                          <Box justify='space-between' w='100%' mt={1}>
                            <Text mt={-0.5}>
                              Total
                              <Tooltip
                                label='The total amount you will pay over the course of your financing plan, including interest and fees.'
                                toRightOfElement
                              />
                            </Text>
                            <Text>{formatCurrency(premiumFinanceTotalCost)}</Text>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {acceptedQuotes.length > 1 && acceptedQuotes.map((quote, i) => (
                    <Box
                      key={i}
                      justify='space-between'
                      w='100%'
                      mb={1}
                    >
                      <Text>{quote.product.name} premium</Text>
                      <Text>{authentic.isSubmittingApplication ? '—' : formatCurrency(quote.grossPremium)}</Text>
                    </Box>
                  ))}
                  <Box justify='space-between' w='100%'>
                    <Text bold>{isPayingInFull ? 'Total due' : 'Due today'}</Text>
                    <Box align='flex-end' column>
                      <Text variant='heading'>
                        {formatCurrency(totalDueToday)}
                      </Text>
                      <Text c='muted' fontSize='sm'>
                        + {formatCurrency(totalDueTodayPlatformFee)} platform fee
                        <Tooltip
                          label={`There is a payment processing fee of ${isPayingWithCard
                            ? '2.80% + $0.30'
                            : '$1.00'}${!isPayingInFull ? ' on down payments' : ''} for ${isPayingWithCard
                              ? 'card'
                              : 'ACH'} payments.`}
                        />
                      </Text>
                    </Box>
                  </Box>
                  <Box centered column w='100%' mt={2}>
                    <Button
                      variant='primary'
                      w='100%'
                      onClick={() => { void handleSubmitPayment(); }}
                      isDisabled={isSubmittingDisabled}
                      onDisabledClick={() => { setHasAttemptedSubmit(true); }}
                      isLoading={functionalFinance.isSubmittingPayment}
                    >
                      Confirm payment
                    </Button>
                    <Text mt={1.5} c='muted' fontSize='sm' textAlign='center'>
                      By clicking "Confirm payment," you agree to the&nbsp;
                      {!isPayingInFull && (
                        <>
                          <Link
                            inline
                            c='muted'
                            href={premiumFinanceAgreementLink ?? ''}
                            openInNewTab
                          >
                            Finance Agreement
                          </Link>,&nbsp;
                        </>
                      )}
                      <Link
                        inline
                        c='muted'
                        href={FUNCTIONAL_FINANCE_PAYER_TERMS_URL}
                        openInNewTab
                      >
                        Payer Terms of Service
                      </Link>{!isPayingInFull ? ',' : ''}&nbsp;and authorize&nbsp;
                      {AUTHENTIC_BRAND_NAME}{!isPayingInFull
                        ? ' and Honor Capital / ETI Financial'
                        : ''}&nbsp;
                      {isPayingWithCard
                        ? 'to store and debit the payment method specified above for this amount and future payments.'
                        : (<>
                            to store and debit the bank account specified above for any amount owed for charges arising
                            from your use of {AUTHENTIC_BRAND_NAME}'s services and/or purchase of products from {AUTHENTIC_BRAND_NAME}, pursuant to {AUTHENTIC_BRAND_NAME}'s
                            website and terms, until this authorization is revoked. You may amend or cancel this authorization with 30 days notice.
                          </>)}
                      {!isPayingInFull && ` Installments are debited by Honor Capital; down payment is debited by ${AUTHENTIC_BRAND_NAME}.`}
                    </Text>
                  </Box>
                </Box>
              </>)}
    </Modal>
  );
}
